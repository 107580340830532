import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Tag.module.scss';

export type TagProps = {
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  color?:
    | 'primary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'mu'
    | 'nu'
    | 'dark'
    | 'light'; // color names from $all-colors
  rounded?: boolean;
  className?: string;
  nowrap?: boolean;
};

export const Tag = ({
  children,
  size = 'md',
  color = 'primary',
  className,
  rounded = true,
  nowrap,
  ...rest
}: TagProps) => {
  return (
    <span
      className={classNames(styles.root, className, {
        [styles[`${color}Color`]]: color,
        [styles[`${size}Size`]]: size,
        [styles.rounded]: rounded,
        [styles.nowrap]: nowrap,
      })}
      {...rest}
    >
      {children}
    </span>
  );
};
