import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  active: {
    id: 'BroadcastsTable.active',
    description: 'Active',
    defaultMessage: 'Aktiv',
  },
  closed: {
    id: 'BroadcastsTable.closed',
    description: 'Closed',
    defaultMessage: 'Stängd',
  },
});
