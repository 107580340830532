/* eslint-disable max-lines */

import {
  useCallback,
  useEffect,
  useMemo,
  ClassAttributes,
  HTMLAttributes,
  ReactNode,
  useRef,
  useState,
  isValidElement,
} from 'react';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import {
  useTable as useReactTable,
  useSortBy,
  usePagination,
} from 'react-table';

import { Grid, Icon, Spinner } from 'components/ui/general';
import {
  Pagination,
  Search,
  FilterSelect,
  FilterSelectProps,
  FilterDate,
  FilterDateProps,
} from 'components/ui/table';
import { TableSelectors } from 'consts/cypress';
import { useTable } from 'hooks';

import styles from './Table.module.scss';

export type TableFetchData = {
  pageIndex: number;
  pageSize: number;
  sortBy: {
    id: string;
    desc: boolean;
  }[];
  searchTerm?: string;
  activeFilter?: string[];
};

export type TableProps = {
  columns: any[];
  data: any[];
  onFetchData: ({
    pageIndex,
    pageSize,
    sortBy,
    searchTerm,
    activeFilter,
  }: TableFetchData) => void;
  maxWidth?: boolean;
  pageSize?: number;
  totalPages?: number;
  className?: string;
  classNameTable?: string;
  classNameSearch?: string;
  classNamePagination?: string;
  classNameBodyTr?: string;
  classNameTop?: string;
  topBeside?: ReactNode;
  topStripPaddingSides?: boolean;
  headBackground?: boolean;
  truncate?: boolean;
  pagination?: boolean;
  sort?: boolean;
  empty?: ReactNode;
  loading?: boolean;
  search?: boolean;
  handleQueryParameters?: boolean;
  filter?: {
    select?: FilterSelectProps['select'];
    date?: FilterDateProps['date'];
  }[];
  initialFilters?: {
    type: string;
    key: string;
    filters: { label: string; value: string }[];
  }[];
  outerBorder?: boolean;
  lastTdRight?: boolean;
  paginationBeside?: ReactNode;
  hideHeader?: boolean;
  isInModalMiddle?: boolean;
};

export const Table = ({
  columns,
  data,
  onFetchData,
  maxWidth,
  totalPages,
  pageSize,
  className,
  classNameTable,
  classNameSearch,
  classNamePagination,
  classNameBodyTr,
  classNameTop,
  topBeside,
  topStripPaddingSides,
  headBackground,
  truncate,
  pagination,
  sort,
  empty,
  loading,
  search,
  handleQueryParameters = true,
  filter,
  initialFilters,
  outerBorder,
  lastTdRight,
  paginationBeside,
  hideHeader = false,
  isInModalMiddle = false,
}: TableProps) => {
  const initiated = useRef(false);

  const {
    setAllQueryParameters,
    setFilterQueryParameterForSelect,
    getPageIndexQueryParameter,
    getFilterQueryParameter,
    getFilterValuesForSelect,
    getSearchTermQueryParameter,
    getSortingQueryParameter,
    setFilterQueryParameterForDate,
    getFilterValuesForDate,
  } = useTable();

  const [variables, setVariables] = useState<TableFetchData>({
    pageIndex: handleQueryParameters ? getPageIndexQueryParameter() || 0 : 0,
    pageSize: typeof pageSize === 'number' ? pageSize : 10,
    sortBy: handleQueryParameters ? getSortingQueryParameter() || [] : [],
    searchTerm: handleQueryParameters
      ? getSearchTermQueryParameter()
      : undefined,
    activeFilter: handleQueryParameters ? getFilterQueryParameter() : undefined,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useReactTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: variables.pageIndex,
        pageSize: variables.pageSize,
        sortBy: variables.sortBy,
      },
      manualPagination: !!pagination,
      manualSortBy: !!sort,
      pageCount: totalPages,
    },
    !!sort && useSortBy,
    !!pagination && usePagination
  );

  const handleFetchData = useCallback(() => {
    if (handleQueryParameters) {
      setAllQueryParameters((previousQueryParameters) => ({
        ...previousQueryParameters,
        pageIndex: variables.pageIndex,
        sortById: variables.sortBy[0]?.id,
        sortByDesc:
          typeof variables.sortBy[0]?.desc === 'boolean'
            ? variables.sortBy[0].desc
            : undefined,
        searchTerm: variables.searchTerm?.length
          ? variables.searchTerm
          : undefined,
      }));
    }

    onFetchData(variables);
  }, [handleQueryParameters, onFetchData, setAllQueryParameters, variables]);

  useEffect(() => {
    handleFetchData();
    initiated.current = true;
  }, [handleFetchData]);

  useEffect(() => {
    setVariables((previousVariables) => ({
      ...previousVariables,
      pageIndex,
    }));
  }, [pageIndex]);

  useEffect(() => {
    setVariables((previousVariables) => {
      const getPageIndex = isEqual(previousVariables.sortBy, sortBy)
        ? previousVariables.pageIndex
        : 0;

      gotoPage?.(getPageIndex);

      return {
        ...previousVariables,
        sortBy,
        pageIndex: getPageIndex,
      };
    });
  }, [sortBy, gotoPage]);

  const onFilterSelectChange = useCallback(
    (filterChange, selectOptions) => {
      if (handleQueryParameters) {
        setFilterQueryParameterForSelect(filterChange, selectOptions);
      }
      setVariables((previousVariables) => ({
        ...previousVariables,
        activeFilter: getFilterValuesForSelect(
          filterChange,
          selectOptions,
          previousVariables.activeFilter
        ),
      }));
    },
    [
      handleQueryParameters,
      setFilterQueryParameterForSelect,
      getFilterValuesForSelect,
    ]
  );

  useEffect(() => {
    if (initialFilters) {
      initialFilters.forEach((column) => {
        if (column.type === 'select' && column.filters) {
          onFilterSelectChange(column.filters, column.filters);
        }
      });
    }
  }, [filter, getFilterValuesForSelect, initialFilters, onFilterSelectChange]);

  const onFilterDateChange = useCallback(
    (dateChange, dateName) => {
      if (handleQueryParameters) {
        setFilterQueryParameterForDate(dateChange, dateName);
      }

      setVariables((previousVariables) => ({
        ...previousVariables,
        activeFilter: getFilterValuesForDate(
          dateChange,
          dateName,
          previousVariables.activeFilter
        ),
      }));
    },
    [
      getFilterValuesForDate,
      handleQueryParameters,
      setFilterQueryParameterForDate,
    ]
  );

  const onSearchChange = useCallback(
    (searchTerm: string) => {
      if (!search) return;

      setVariables((previousVariables) => {
        gotoPage?.(0);

        return {
          ...previousVariables,
          searchTerm: searchTerm.length ? searchTerm : undefined,
          pageIndex: 0,
        };
      });
    },
    [search, gotoPage]
  );

  const getRows = useMemo(
    () => (pagination ? page : rows),
    [page, pagination, rows]
  );

  const renderHead = useCallback(
    () =>
      headerGroups.map(
        (headerGroup: {
          getHeaderGroupProps: () => JSX.IntrinsicAttributes &
            ClassAttributes<HTMLTableRowElement> &
            HTMLAttributes<HTMLTableRowElement>;
          headers: any[];
        }) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className={styles.tr}
            data-cy={TableSelectors.HeadTr}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps?.())}
                className={classNames(styles.th, column.classNameTh)}
                data-cy={TableSelectors.HeadTh}
              >
                <div className={styles.thText}>
                  {column.render('Header')}
                  {sort &&
                    !column.disableSortBy &&
                    (typeof column.Header === 'string' ||
                      isValidElement(column.Header)) && (
                      <Icon
                        name={
                          column.isSortedDesc
                            ? 'long-arrow-alt-up'
                            : 'long-arrow-alt-down'
                        }
                        font="kyoto"
                        className={classNames(styles.thIcon, {
                          [styles.isSorted]: column.isSorted,
                        })}
                      />
                    )}
                </div>
              </th>
            ))}
          </tr>
        )
      ),
    [headerGroups, sort]
  );

  const renderBody = useCallback(() => {
    if (!loading) {
      return getRows.map(
        (row: {
          getRowProps: () => JSX.IntrinsicAttributes &
            ClassAttributes<HTMLTableRowElement> &
            HTMLAttributes<HTMLTableRowElement>;
          cells: any[];
        }) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={classNames(styles.tr, classNameBodyTr)}
              data-cy={TableSelectors.BodyTr}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={classNames(styles.td, cell.column.classNameTd, {
                      [styles.tdWrap]: !!cell.column.wrapTd,
                    })}
                    data-cy={TableSelectors.BodyTd}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        }
      );
    }

    return null;
  }, [getRows, loading, prepareRow, classNameBodyTr]);

  const renderFilter = useCallback(() => {
    if (!filter) {
      return null;
    }

    return filter.map(({ select, date }) => {
      if (select) {
        return (
          <Grid.Item width={3} key={select.name}>
            <FilterSelect
              select={select}
              onFilterChange={onFilterSelectChange}
              handleQueryParameters={handleQueryParameters}
            />
          </Grid.Item>
        );
      }

      if (date) {
        return (
          <Grid.Item width={3} key={date.name}>
            <FilterDate
              date={date}
              onFilterChange={onFilterDateChange}
              handleQueryParameters={handleQueryParameters}
            />
          </Grid.Item>
        );
      }

      return null;
    });
  }, [filter, handleQueryParameters, onFilterSelectChange, onFilterDateChange]);

  const renderTop = useCallback(() => {
    if (search || filter || topBeside) {
      return (
        <div className={classNames(styles.top, classNameTop)}>
          <div className={styles.topInner}>
            <div className={styles.topSearchAndFilter}>
              <Grid gutter={{ left: 1.5, bottom: 1.5 }}>
                {!!search && (
                  <Grid.Item width={3}>
                    <Search
                      classNameSearch={classNameSearch}
                      onSearchChange={onSearchChange}
                      handleQueryParameters={handleQueryParameters}
                    />
                  </Grid.Item>
                )}
                {renderFilter()}
              </Grid>
            </div>
            {!!topBeside && <div className={styles.topBeside}>{topBeside}</div>}
          </div>
        </div>
      );
    }

    return null;
  }, [
    classNameSearch,
    classNameTop,
    filter,
    onSearchChange,
    renderFilter,
    search,
    topBeside,
    handleQueryParameters,
  ]);

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.maxWidth]: maxWidth,
        [styles.truncate]: truncate,
        [styles.outerBorder]: outerBorder,
        [styles.lastTdRight]: lastTdRight,
        [styles.topStripPaddingSides]: topStripPaddingSides,
        [styles.headBackground]: headBackground,
      })}
      data-cy={TableSelectors.Root}
    >
      {renderTop()}
      <div>
        <div className={classNames(styles.tableHolder, classNameTable)}>
          <table {...getTableProps()} className={styles.table}>
            {!hideHeader && (
              <thead data-cy={TableSelectors.Head}>{renderHead()}</thead>
            )}
            <tbody {...getTableBodyProps()} data-cy={TableSelectors.Body}>
              {renderBody()}
            </tbody>
          </table>
        </div>
        {!!loading && (
          <div className={styles.spinnerHolder}>
            <Spinner visible color="primary" />
          </div>
        )}
        {!loading && !getRows.length && initiated.current && (
          <div className={styles.emptyHolder}>{empty}</div>
        )}
        {!loading && !!getRows.length && pagination && (
          <Pagination
            classNamePagination={classNamePagination}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={totalPages || pageCount}
            pageIndex={handleQueryParameters ? variables.pageIndex : pageIndex}
            pageOptions={pageOptions}
            paginationBeside={paginationBeside}
            isInModalMiddle={isInModalMiddle}
          />
        )}
      </div>
    </div>
  );
};
