import { ReactNode, useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { TabsSelectors } from 'consts/cypress';
import { QueryParameters } from 'consts/tabs';
import { useTable, useUrlQuery } from 'hooks';

import styles from './Tabs.module.scss';

export type TabsProps = {
  panes: {
    id: string;
    label: ReactNode;
    content: ReactNode;
    beside?: ReactNode;
    disabled?: boolean;
  }[];
  activePaneId?: string;
  className?: string;
  borderBottom?: boolean;
};

export const Tabs = ({
  panes,
  activePaneId,
  className,
  borderBottom = true,
}: TabsProps) => {
  const { get, set } = useUrlQuery();
  const { clearAllQueryParameters } = useTable();
  const getActivePaneId = useMemo(
    () => get(QueryParameters.ActivePaneId) || activePaneId || panes[0].id,
    [activePaneId, panes, get]
  );

  const renderNavigation = useCallback(() => {
    return panes.map(({ id, label, disabled }) => (
      <button
        key={id}
        type="button"
        onClick={() => {
          set(QueryParameters.ActivePaneId, id);
          clearAllQueryParameters();
        }}
        className={classNames(styles.button, {
          [styles.active]: getActivePaneId === id,
        })}
        disabled={disabled}
        data-cy={TabsSelectors.Label}
      >
        {label}
      </button>
    ));
  }, [getActivePaneId, panes, set, clearAllQueryParameters]);

  const renderContent = useCallback(() => {
    return panes.map(({ id, content }) => {
      if (getActivePaneId === id) {
        return (
          <div
            key={id}
            data-cy={TabsSelectors.Content}
            className={styles.content}
          >
            {content}
          </div>
        );
      }

      return null;
    });
  }, [getActivePaneId, panes]);

  const renderBeside = useCallback(() => {
    return panes.map(({ id, beside }) => {
      if (!!beside && getActivePaneId === id) {
        return (
          <div
            key={id}
            data-cy={TabsSelectors.Beside}
            className={styles.beside}
          >
            {beside}
          </div>
        );
      }

      return null;
    });
  }, [getActivePaneId, panes]);

  return (
    <div
      className={classNames(className, {
        [styles.borderBottom]: borderBottom,
      })}
      data-cy={TabsSelectors.Root}
    >
      <div className={styles.top}>
        <nav className={styles.navigation}>
          <div className={styles.navigationInner}>{renderNavigation()}</div>
        </nav>
        {renderBeside()}
      </div>
      {renderContent()}
    </div>
  );
};
