import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  heading: {
    id: 'BroadcastReceiversModal.heading',
    description: 'Broadcast recipients modal heading',
    defaultMessage: 'Mottagare',
  },
  sendMessageToSameReceivers: {
    id: 'BroadcastReceiversModal.sendMessageToSameReceivers',
    description: 'Send message to same receivers',
    defaultMessage: 'Nytt meddelande till samma urval',
  },
});
