import { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useInitChatGroupChannelMutation } from 'api/graphql';
import { EmptyInboxAndErrorIcon } from 'assets/icons';
import { SendMessage } from 'components/ui/chat';
import { Empty, Spinner } from 'components/ui/general';
import { QueryParameters } from 'consts/chat';
import { Paths } from 'consts/router';

import { texts } from './GroupFeed.text';

import styles from './GroupFeed.module.scss';

type GroupFeedProps = {
  groupId?: string | number;
  workShiftId?: string | number;
};

export const GroupFeed = ({ groupId, workShiftId }: GroupFeedProps) => {
  const navigate = useNavigate();
  const [initChatGroupChannel, { data, loading, error }] =
    useInitChatGroupChannelMutation();

  useEffect(() => {
    if (groupId) {
      initChatGroupChannel({
        variables: { id: groupId },
      });
    }
  }, [initChatGroupChannel, groupId]);

  useEffect(() => {
    if (data?.initChatGroupChannel) {
      navigate(
        {
          pathname: Paths.Chat,
          search: `${QueryParameters.ChannelId}=${data.initChatGroupChannel.id}`,
        },
        {
          replace: true,
        }
      );
    }
  }, [data, navigate]);

  if (workShiftId) {
    return (
      <>
        <div className={styles.root}>
          <Empty
            title={<FormattedMessage {...texts.workShiftInstructions} />}
            icon={<EmptyInboxAndErrorIcon />}
          />
        </div>
        <SendMessage workShiftId={workShiftId} disabled={loading} />
      </>
    );
  }

  if (loading) {
    return (
      <div className={styles.root}>
        <Spinner
          visible
          text={<FormattedMessage {...texts.initChatGroupChannelLoading} />}
          color="primary"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.root}>
        <Empty
          title={
            <FormattedMessage {...texts.initChatGroupChannelLoadingError} />
          }
          message={error.message}
        />
      </div>
    );
  }

  return null;
};
