import { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Scalars, useCustomBroadcastChannelsLazyQuery } from 'api/graphql';
import { Button } from 'components/ui/general';
import { QueryParameters } from 'consts/chat';
import { Paths } from 'consts/router';
import { useToast } from 'hooks';

import { texts } from '../BroadcastRespondentsModal.text';

type NavigateToChatProps = {
  userId: Scalars['ID'];
  chatGroupId: Scalars['ID'];
};

export const NavigateToChat = ({
  userId,
  chatGroupId,
}: NavigateToChatProps) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  const [fetchBroadcastChannels, { loading }] =
    useCustomBroadcastChannelsLazyQuery();

  const handleNavigateToChat = useCallback(async () => {
    const { data, error } = await fetchBroadcastChannels({
      variables: {
        filter: {
          chatGroupId,
          userId,
          includeClosed: true,
        },
      },
    });

    const { id } = data?.channels.edges[0] || {};

    if (error || !id) {
      addToast({
        title: formatMessage(texts.fetchChannelIdError),
        message: error?.message,
        type: 'error',
      });
      return;
    }

    navigate({
      pathname: Paths.Chat,
      search: `${QueryParameters.ChannelId}=${id}`,
    });
  }, [
    addToast,
    chatGroupId,
    fetchBroadcastChannels,
    formatMessage,
    navigate,
    userId,
  ]);

  return (
    <Button
      iconRight={{ name: 'angle-right' }}
      size="sm"
      color="secondary"
      loading={loading}
      disabled={loading}
      onClick={handleNavigateToChat}
    />
  );
};
