import { useCallback, useEffect, useMemo, useRef } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  CustomChannelsQuery,
  useCustomChannelsQuery,
  useCustomChatSubscribeSubscription,
  Scalars,
} from 'api/graphql';
import { EmptyInboxAndErrorIcon, NoSearchResultsIcon } from 'assets/icons';
import { Channel } from 'components/ui/chat';
import { Empty, Spinner } from 'components/ui/general';
import { DefaultFilterChannels } from 'consts/chat';
import { format } from 'utils';

import { texts } from '../Channels.text';

import styles from '../Channels.module.scss';

type PeerToPeerProps = {
  userId?: Scalars['ID'];
  watchSearchTerm: any;
  watchChannelOrigin: any;
};

export const PeerToPeer = ({
  userId,
  watchSearchTerm,
  watchChannelOrigin,
}: PeerToPeerProps) => {
  const hasLoadedEverythingForTheFirstTime = useRef<boolean>(false);

  const { data, fetchMore, loading, error } = useCustomChannelsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      filter: { excludeUserId: userId, limit: DefaultFilterChannels.Limit },
    },
  });

  const channels = useMemo(() => data?.channels, [data]);

  // This hook creates a cache really early, so upcoming channels/messages will
  // be asserted within the cache
  useCustomChatSubscribeSubscription();

  const onFetchMore = useCallback(async () => {
    if (channels) {
      await fetchMore({
        variables: {
          filter: {
            excludeUserId: userId,
            limit: DefaultFilterChannels.Limit,
            offset: channels.edges.length,
          },
        },
      });

      const { meta, edges } = channels;
      if (edges.length >= meta.total) {
        hasLoadedEverythingForTheFirstTime.current = true;
      }
    }
  }, [channels, fetchMore, userId]);

  useEffect(() => {
    if (
      channels &&
      !hasLoadedEverythingForTheFirstTime.current &&
      !loading &&
      !error
    ) {
      const { total, offset } = channels.meta;
      if (total >= offset) {
        onFetchMore();
      }
    }
  }, [channels, onFetchMore, loading, error]);

  const getSearchTermResults = useCallback(
    (channelsEdges: CustomChannelsQuery['channels']['edges']) => {
      const lowerCaseValue = watchSearchTerm.toLowerCase();
      const filterChannels = channelsEdges.filter(({ participants }) => {
        const otherParticipants = participants?.filter(
          (participant) => participant.user.id !== userId
        );

        const { firstName, lastName } = otherParticipants?.[0]?.user || {};

        return `${firstName} ${lastName}`
          .toLowerCase()
          .includes(lowerCaseValue);
      });

      return filterChannels;
    },
    [userId, watchSearchTerm]
  );

  // Not passing variables to backend because it will interfere with the cache
  // logic from src/components/ui/general/Header/subcomponents/Chat.tsx
  const getFilteredChannelsEdges = useMemo<
    CustomChannelsQuery['channels']['edges'] | null
  >(() => {
    let channelsEdges = data?.channels.edges || null;

    if (
      !channelsEdges?.length ||
      (!watchSearchTerm?.length && !watchChannelOrigin)
    ) {
      channelsEdges = null;
    }

    if (watchSearchTerm?.length && channelsEdges) {
      channelsEdges = getSearchTermResults(channelsEdges);
    }

    return channelsEdges;
  }, [
    data?.channels.edges,
    getSearchTermResults,
    watchChannelOrigin,
    watchSearchTerm?.length,
  ]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner
          visible
          text={<FormattedMessage {...texts.fetchChannelsLoading} />}
          color="primary"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <Empty
          title={<FormattedMessage {...texts.fetchChannelsError} />}
          message={error.message}
        />
      </div>
    );
  }

  if (data?.channels.edges.length && getFilteredChannelsEdges?.length !== 0) {
    const { edges, meta } = data.channels;

    // Little risky if the error occurs in
    // src/components/ui/general/Header/subcomponents/Chat.tsx
    // but I'll take it for now
    if (edges.length < meta.total) {
      return (
        <div className={styles.loading}>
          <Spinner
            visible
            text={<FormattedMessage {...texts.fetchChannelsLoading} />}
            color="primary"
          />
        </div>
      );
    }

    const collectChannels = getFilteredChannelsEdges?.length
      ? getFilteredChannelsEdges
      : edges;

    return (
      <div className={styles.channels}>
        {[...collectChannels]
          .sort((a, b) =>
            format
              .date({ date: b.lastSentAt })
              .diff(format.date({ date: a.lastSentAt }))
          )
          .map((channel) => (
            <Channel key={channel.id} channel={channel} isOneToOneFilter />
          ))}
      </div>
    );
  }

  if (getFilteredChannelsEdges?.length === 0) {
    return (
      <div className={styles.empty}>
        <Empty
          title={<FormattedMessage {...texts.filterResultsEmpty} />}
          icon={<NoSearchResultsIcon />}
        />
      </div>
    );
  }

  return (
    <div className={styles.empty}>
      <Empty
        title={<FormattedMessage {...texts.fetchChannelsEmpty} />}
        icon={<EmptyInboxAndErrorIcon />}
      />
    </div>
  );
};
