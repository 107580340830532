import {
  ChangeEvent,
  useCallback,
  useMemo,
  MutableRefObject,
  ReactNode,
  MouseEventHandler,
} from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

import { Error } from 'components/ui/forms';
import { Icon, IconProps, Information } from 'components/ui/general';
import { TextareaSelectors } from 'consts/cypress';
import { Fonts } from 'types/icon';

import styles from './Textarea.module.scss';

type IconPropsExtended = IconProps & {
  onClick?: MouseEventHandler;
};

export type TextareaProps = {
  name: string;
  placeholder?: string;
  ariaLabel?: string;
  label?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  color?: 'light'; // color names from $all-colors
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  fullWidth?: boolean;
  error?: FieldErrors;
  iconRight?: IconPropsExtended;
  iconLeft?: IconPropsExtended;
  rounded?: boolean;
  defaultValue?: string;
  information?: ReactNode;
  success?: boolean;
  readOnly?: boolean;
  textareaRef?: MutableRefObject<HTMLTextAreaElement | null>;
} & TextareaAutosizeProps;

export const Textarea = ({
  name,
  placeholder,
  ariaLabel,
  label,
  size = 'md',
  color = 'light',
  className,
  disabled,
  register,
  validation = {},
  onChange,
  onBlur,
  fullWidth,
  error,
  iconRight,
  iconLeft,
  maxRows,
  minRows = 4,
  onHeightChange,
  cacheMeasurements,
  rounded,
  defaultValue,
  information,
  success,
  readOnly,
  textareaRef,
  onKeyDown,
}: TextareaProps) => {
  const Root = useMemo(() => (label ? 'label' : 'div'), [label]);

  const getIconFont = useCallback(
    ({ font }: { font?: Fonts }) => {
      if (font) {
        return font;
      }

      switch (size) {
        case 'sm':
        case 'md':
        case 'lg':
          return 'madrid';

        default:
          return 'madrid';
      }
    },
    [size]
  );

  const renderIcon = useCallback(
    (icon: IconPropsExtended) => {
      const { name: iconName, onClick } = icon;
      const IconButton = onClick ? 'button' : 'div';
      const disabledOrReadOnly = disabled || readOnly;

      return (
        <IconButton
          type={onClick ? 'button' : undefined}
          onClick={onClick && !disabledOrReadOnly ? onClick : undefined}
          className={onClick ? styles.iconButton : undefined}
        >
          <Icon
            name={iconName}
            font={getIconFont(icon)}
            className={styles.icon}
          />
        </IconButton>
      );
    },
    [disabled, getIconFont, readOnly]
  );

  const getValidation = useMemo(() => {
    return !disabled && !readOnly ? validation : {};
  }, [disabled, readOnly, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  return (
    <>
      <Root
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles[`${color}Color`]]: color,
          [styles[`${size}Size`]]: size,
          [styles.fullWidth]: fullWidth,
          [styles.hasIconRight]: iconRight,
          [styles.hasIconLeft]: iconLeft,
          [styles.rounded]: rounded,
          [styles.error]: !!error,
          [styles.success]: success,
          [styles.readOnly]: readOnly,
        })}
        data-cy={TextareaSelectors.Root}
      >
        {!!label && (
          <div className={styles.label}>
            {label}
            {getValidation.required ? ' *' : ''}
          </div>
        )}
        <div className={styles.textareaHolder}>
          <TextareaAutosize
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            aria-label={ariaLabel || placeholder}
            className={styles.textarea}
            maxRows={maxRows}
            minRows={minRows}
            onHeightChange={onHeightChange}
            cacheMeasurements={cacheMeasurements}
            defaultValue={defaultValue}
            readOnly={readOnly}
            onKeyDown={onKeyDown}
            data-cy={TextareaSelectors.Textarea}
            {...registerHolder}
            ref={(event) => {
              if (textareaRef) textareaRef.current = event;
              registerHolder.ref(event);
            }}
            onChange={(event) => {
              registerHolder.onChange(event);
              onChange?.(event);
            }}
            onBlur={(event) => {
              registerHolder.onBlur(event);
              onBlur?.(event);
            }}
          />
          {!!iconLeft && (
            <div className={styles.iconLeft}>{renderIcon(iconLeft)}</div>
          )}
          {!!iconRight && (
            <div className={styles.iconRight}>{renderIcon(iconRight)}</div>
          )}
        </div>
      </Root>
      {!!information && (
        <Information
          className={styles.informationText}
          size={size === 'sm' ? 'sm' : 'md'}
        >
          {information}
        </Information>
      )}
      <Error error={error} size={size === 'sm' ? 'sm' : 'md'} />
    </>
  );
};
