import { CustomMessagesQuery } from 'api/graphql';
import { Message } from 'components/ui/chat';
import { useAuth } from 'hooks';

import styles from './Messages.module.scss';

type MessagesProps = {
  messages: CustomMessagesQuery['messages']['edges'];
  firstParticipantId?: string;
};

export const Messages = ({ messages, firstParticipantId }: MessagesProps) => {
  const { user } = useAuth();
  const myId = firstParticipantId || user?.id;

  const myLastMessageId = messages.filter(
    ({ createdBy }) => createdBy?.id === myId
  )[0]?.id;

  return (
    <div className={styles.root}>
      {[...messages].reverse().map((message) => (
        <Message
          key={message.id}
          message={message}
          isMyLastMessage={myLastMessageId === message.id}
          myId={myId}
        />
      ))}
    </div>
  );
};
