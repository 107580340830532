const getTotalPages = ({
  total,
  limit,
}: {
  total?: number;
  limit?: number;
} = {}) => {
  if (typeof total === 'number' && typeof limit === 'number') {
    return Math.ceil(total / limit);
  }
};

const getFilters = (
  filters?: string[],
  options?: {
    transformValue?: (key: string, value: string) => void;
  }
) => {
  let convertFiltersArrayToObject: {
    [key: string]: any[] | undefined;
  } = {};

  if (!filters) {
    return convertFiltersArrayToObject;
  }

  const transformValue = (key: string, value: string) => {
    if (typeof options?.transformValue === 'function') {
      return options.transformValue(key, value);
    }

    return value;
  };

  filters.forEach((filter) => {
    const [key, value] = filter.split('__');
    const filterArrayAlreadyExists = convertFiltersArrayToObject[key];

    if (filterArrayAlreadyExists) {
      convertFiltersArrayToObject = {
        ...convertFiltersArrayToObject,
        [key]: [...filterArrayAlreadyExists, transformValue(key, value)],
      };
      return;
    }

    convertFiltersArrayToObject = {
      ...convertFiltersArrayToObject,
      [key]: [transformValue(key, value)],
    };
  });

  return convertFiltersArrayToObject;
};

export const table = {
  getTotalPages,
  getFilters,
};
