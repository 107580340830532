import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  ...shared.region,
  heading: {
    id: 'BroadcastSelectionCompaniesModal.heading',
    description: 'Broadcast select resources modal heading',
    defaultMessage: 'Broadcast - Urval',
  },
  filterCompanyLabel: {
    id: 'BroadcastSelectionCompaniesModal.filterCompanyLabel',
    description: 'Filter company label',
    defaultMessage: 'Kund',
  },
  buttonWriteMessage: {
    id: 'BroadcastSelectionCompaniesModal.buttonWriteMessage',
    description: 'Button write message',
    defaultMessage: 'Skriv till {amount} st',
  },
  buttonSelectAll: {
    id: 'BroadcastSelectionCompaniesModal.buttonSelectAll',
    description: 'Button select all',
    defaultMessage: 'Välj alla',
  },
});
