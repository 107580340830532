import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Location, User, useUpsertCompanyProfileMutation } from 'api/graphql';
import { Switch } from 'components/ui/forms';
import { SwitchLocationCompanyProfileSelectors } from 'consts/cypress';
import { useToast } from 'hooks';
import { typeGuards } from 'utils';

import { texts } from './SwitchLocationCompanyProfile.text';

type SwitchLocationCompanyProfileProps = {
  user: User;
  location: Location;
  companyId: string | number;
  disabled?: boolean;
};

export const SwitchLocationCompanyProfile = ({
  user,
  location,
  companyId,
  disabled,
}: SwitchLocationCompanyProfileProps) => {
  const { register, setValue } = useForm();
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const [updateCompanyProfile, { error, loading }] =
    useUpsertCompanyProfileMutation();

  const companyProfileLocationIds = useMemo(() => {
    const companyProfile = user.userProfile.map((profile) =>
      typeGuards.companyProfile(profile)
    )[0];
    return companyProfile?.locations.map(({ id }) => id) || [];
  }, [user.userProfile]);

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.updateError),
        message: error.message,
        type: 'error',
      });
    }
  }, [addToast, error, formatMessage]);

  const onChange = useCallback(
    async ({ target }) => {
      let locations: (string | number)[] = companyProfileLocationIds;

      if (!target.checked) {
        locations = locations.filter(
          (locationId) => locationId !== location.id
        );
      } else {
        locations = [...locations, location.id];
      }

      const { data } = await updateCompanyProfile({
        variables: {
          id: user.id,
          input: {
            company: companyId,
            locations,
          },
        },
      });

      if (data?.upsertCompanyProfile) {
        addToast({
          title: formatMessage(texts.updateSuccess),
          type: 'success',
        });
      }
    },
    [
      addToast,
      companyId,
      companyProfileLocationIds,
      formatMessage,
      location.id,
      updateCompanyProfile,
      user.id,
    ]
  );

  // defaultChecked needs some help
  useEffect(() => {
    setValue(
      user.id.toString(),
      companyProfileLocationIds.includes(location.id)
    );
  }, [companyProfileLocationIds, location.id, setValue, user.id]);

  return (
    <div data-cy={SwitchLocationCompanyProfileSelectors.Root}>
      <Switch
        name={location.id.toString()}
        register={register}
        onChange={onChange}
        defaultChecked={companyProfileLocationIds.includes(location.id)}
        disabled={loading || disabled}
      />
    </div>
  );
};
