/* eslint-disable max-lines */
/**
 * Collect shared messages within this file. Enums from backend is great to
 * translate here.
 */

import { defineMessages } from 'react-intl';

import {
  BookingStatus,
  BookingType,
  ChannelOrigin,
  ExtraBookingStatus,
  Language,
  NotificationType,
  Region,
  RestaurantVerticalCategory,
  UserRole,
  VerticalCategory,
  WorkShiftStatus,
  BackOfficeMainBookingStatus,
  BackOfficeSubBookingStatus,
} from 'api/graphql';
import { RestaurantWorkExperienceYearsKey } from 'consts/restaurantWorkExperience';

// enum UserRole
const userRole = defineMessages<`userRole_${UserRole}`>({
  userRole_admin: {
    id: 'shared.userRole_admin',
    description: 'Admin',
    defaultMessage: 'Superadmin',
  },
  userRole_company_admin: {
    id: 'shared.userRole_company_admin',
    description: 'Company admin',
    defaultMessage: 'Admin',
  },
  userRole_company_user: {
    id: 'shared.userRole_company_user',
    description: 'Company user',
    defaultMessage: 'Bokare',
  },
  userRole_user: {
    id: 'shared.userRole_user',
    description: 'User',
    defaultMessage: 'Resurs',
  },
});

// enum VerticalCategory
const verticalCategory = defineMessages<`verticalCategory_${VerticalCategory}`>(
  {
    verticalCategory_restaurant: {
      id: 'shared.verticalCategory_restaurant',
      description: 'Restaurant',
      defaultMessage: 'Restaurang',
    },
  }
);

// enum ChannelOrigin
const channelOrigin = defineMessages<`channelOrigin_${ChannelOrigin}`>({
  channelOrigin_Broadcast: {
    id: 'shared.channelOrigin_Broadcast',
    description: 'Broadcast',
    defaultMessage: 'Broadcast',
  },
  channelOrigin_Group: {
    id: 'shared.channelOrigin_Group',
    description: 'Group',
    defaultMessage: 'Grupp',
  },
  channelOrigin_OneToOne: {
    id: 'shared.channelOrigin_OneToOne',
    description: 'OneToOne',
    defaultMessage: 'P2P',
  },
  channelOrigin_Public: {
    id: 'shared.channelOrigin_Public',
    description: 'Public',
    defaultMessage: 'Publik',
  },
  channelOrigin_System: {
    id: 'shared.channelOrigin_System',
    description: 'System',
    defaultMessage: 'Support',
  },
});

// enum NotificationType
const notificationType = defineMessages<`notificationType_${NotificationType}`>(
  {
    notificationType_accepted_job_offer: {
      id: 'shared.notificationType_accepted_job_offer',
      description: 'Accepted job offer',
      defaultMessage: 'Accept från resurs',
    },
    notificationType_booking_canceled: {
      id: 'shared.notificationType_booking_canceled',
      description: 'Booking canceled',
      defaultMessage: 'Arbetspass avbokat',
    },
    notificationType_booking_start_soon: {
      id: 'shared.notificationType_booking_start_soon',
      description: 'Booking start soon',
      defaultMessage: 'Arbetspass startar snart',
    },
    notificationType_contact_time_change: {
      id: 'shared.notificationType_contact_time_change',
      description: 'Contact time change',
      defaultMessage: 'Kontakt har föreslagit ny tid',
    },
    notificationType_decline_job_offer: {
      id: 'shared.notificationType_decline_job_offer',
      description: 'Decline job offer',
      defaultMessage: 'Nekat uppdrag',
    },
    notificationType_decline_rfi: {
      id: 'shared.notificationType_decline_rfi',
      description: 'Decline RFI',
      defaultMessage: 'Nekat intresseförfrågan',
    },
    notificationType_job_offer_all_declined: {
      id: 'shared.notificationType_job_offer_all_declined',
      description: 'Job offer all declined',
      defaultMessage: 'Alla resurser har nekat',
    },
    notificationType_new_company: {
      id: 'shared.notificationType_new_company',
      description: 'New company',
      defaultMessage: 'Ny kund',
    },
    notificationType_new_job_offer: {
      id: 'shared.notificationType_new_job_offer',
      description: 'New job offer',
      defaultMessage: 'Nytt uppdrag',
    },
    notificationType_new_resource: {
      id: 'shared.notificationType_new_resource',
      description: 'New resource',
      defaultMessage: 'Ny resurs',
    },
    notificationType_no_show: {
      id: 'shared.notificationType_no_show',
      description: 'No show',
      defaultMessage: 'Frånvaro',
    },
    notificationType_request_for_interest: {
      id: 'shared.notificationType_request_for_interest',
      description: 'Request for interest',
      defaultMessage: 'Intresseförfrågan',
    },
    notificationType_resource_time_change: {
      id: 'shared.notificationType_resource_time_change',
      description: 'Resource time change',
      defaultMessage: 'Resurs har föreslagit ny tid',
    },
    notificationType_rfi_all_declined: {
      id: 'shared.notificationType_rfi_all_declined',
      description: 'RFI all declined',
      defaultMessage: 'Alla resurser har nekat intresseförfrågan',
    },
    notificationType_rfi_accepted: {
      id: 'shared.notificationType_rfi_accepted',
      description: 'RFI accepted',
      defaultMessage: 'Resurs har accepterat intresseförfrågan',
    },
    notificationType_time_report_submitted: {
      id: 'shared.notificationType_time_report_submitted',
      description: 'Time report submitted',
      defaultMessage: 'Inskickad tidrapport',
    },
    notificationType_user_reported_sick: {
      id: 'shared.notificationType_user_reported_sick',
      description: 'User reported sick',
      defaultMessage: 'Sjukanmäld',
    },
    notificationType_resource_decline_time_change: {
      id: 'shared.notificationType_resource_decline_time_change',
      description: 'Resource decline time change',
      defaultMessage: 'Nekat av resurs',
    },
    notificationType_booking_started: {
      id: 'shared.notificationType_booking_started',
      description: 'Booking started',
      defaultMessage: 'Påbörjat pass',
    },
  }
);

// enum WorkShiftStatus
const workShiftStatus = defineMessages<`workShiftStatus_${WorkShiftStatus}`>({
  workShiftStatus_completed: {
    id: 'shared.workShiftStatus_completed',
    description: 'Completed',
    defaultMessage: 'Genomfört',
  },
  workShiftStatus_job_offer: {
    id: 'shared.workShiftStatus_job_offer',
    description: 'Job offer',
    defaultMessage: 'Direktbokning',
  },
  workShiftStatus_paid: {
    id: 'shared.workShiftStatus_paid',
    description: 'Paid',
    defaultMessage: 'Utbetald',
  },
  workShiftStatus_request_for_interest: {
    id: 'shared.workShiftStatus_request_for_interest',
    description: 'Request for interest',
    defaultMessage: 'Intresseförfrågan',
  },
  workShiftStatus_sourcing_complete: {
    id: 'shared.workShiftStatus_sourcing_complete',
    description: 'Sourcing complete',
    defaultMessage: 'Fullbokad',
  },
});

// enum BookingType
const bookingType = defineMessages<`bookingType_${BookingType}`>({
  bookingType_job_offer: {
    id: 'shared.bookingType_job_offer',
    description: 'Job offer',
    defaultMessage: 'Direktbokning',
  },
  bookingType_request_for_interest: {
    id: 'shared.bookingType_request_for_interest',
    description: 'Request for interest',
    defaultMessage: 'Intresseförfrågan',
  },
});

// enum RestaurantVerticalCategory
const restaurantVerticalCategory =
  defineMessages<`restaurantVerticalCategory_${RestaurantVerticalCategory}`>({
    restaurantVerticalCategory_bar: {
      id: 'shared.restaurantVerticalCategory_bar',
      description: 'Bar',
      defaultMessage: 'Bar',
    },
    restaurantVerticalCategory_chef: {
      id: 'shared.restaurantVerticalCategory_chef',
      description: 'Chef',
      defaultMessage: 'Kock',
    },
    restaurantVerticalCategory_event: {
      id: 'shared.restaurantVerticalCategory_event',
      description: 'Event',
      defaultMessage: 'Event',
    },
    restaurantVerticalCategory_service: {
      id: 'shared.restaurantVerticalCategory_service',
      description: 'Service',
      defaultMessage: 'Servis',
    },
    restaurantVerticalCategory_wash: {
      id: 'shared.restaurantVerticalCategory_wash',
      description: 'Wash',
      defaultMessage: 'Diskare',
    },
    restaurantVerticalCategory_other: {
      id: 'shared.restaurantVerticalCategory_other',
      description: 'Other',
      defaultMessage: 'Annat',
    },
  });

// enum BookingStatus
const bookingStatus = defineMessages<`bookingStatus_${BookingStatus}`>({
  bookingStatus_approved_by_contact: {
    id: 'shared.bookingStatus_approved_by_contact',
    description: 'Approved by contact',
    defaultMessage: 'Attesterad',
  },
  bookingStatus_awaiting_contact_approval: {
    id: 'shared.bookingStatus_awaiting_contact_approval',
    description: 'Awaiting contact approval',
    defaultMessage: 'Tidrapporterad',
  },
  bookingStatus_contact_declined_time_report: {
    id: 'shared.bookingStatus_contact_declined_time_report',
    description: 'Contact declined time report',
    defaultMessage: 'Tidrapporten nekad',
  },
  bookingStatus_employer_reported_no_show: {
    id: 'shared.bookingStatus_employer_reported_no_show',
    description: 'Employer reported no show',
    defaultMessage: 'Dök inte upp',
  },
  bookingStatus_interested: {
    id: 'shared.bookingStatus_interested',
    description: 'Interested',
    defaultMessage: 'Ansökt',
  },
  bookingStatus_job_accepted: {
    id: 'shared.bookingStatus_job_accepted',
    description: 'Job accepted',
    defaultMessage: 'Bokad',
  },
  bookingStatus_job_offer: {
    id: 'shared.bookingStatus_job_offer',
    description: 'Job offer',
    defaultMessage: 'Inbjuden',
  },
  bookingStatus_job_offer_declined_by_resource: {
    id: 'shared.bookingStatus_job_offer_declined_by_resource',
    description: 'Job offer declined by resource',
    defaultMessage: 'Nekad inbjudan av resurs',
  },
  bookingStatus_paid: {
    id: 'shared.bookingStatus_paid',
    description: 'Paid',
    defaultMessage: 'Betalad',
  },
  bookingStatus_passed_due_no_response: {
    id: 'shared.bookingStatus_passed_due_no_response',
    description: 'Passed due no response',
    defaultMessage: 'Inga svarande',
  },
  bookingStatus_request_for_interest: {
    id: 'shared.bookingStatus_request_for_interest',
    description: 'Request for interest',
    defaultMessage: 'Förfrågad',
  },
  bookingStatus_resource_reported_sick: {
    id: 'shared.bookingStatus_resource_reported_sick',
    description: 'Resource reported sick',
    defaultMessage: 'Sjukanmäld',
  },
  bookingStatus_rfi_declined_by_resource: {
    id: 'shared.bookingStatus_rfi_declined_by_resource',
    description: 'RFI declined by resource',
    defaultMessage: 'Nekad förfrågan av resurs',
  },
});

// enum ExtraBookingStatus
const extraBookingStatus =
  defineMessages<`extraBookingStatus_${ExtraBookingStatus}`>({
    extraBookingStatus_approved_by_contact: {
      id: 'shared.extraBookingStatus_approved_by_contact',
      description: 'approved_by_contact',
      defaultMessage: 'Attesterad',
    },
    extraBookingStatus_awaiting_contact_approval: {
      id: 'shared.extraBookingStatus_awaiting_contact_approval',
      description: 'awaiting_contact_approval',
      defaultMessage: 'Tidrapporterad',
    },
    extraBookingStatus_canceled: {
      id: 'shared.extraBookingStatus_canceled',
      description: 'canceled',
      defaultMessage: 'Avbokad',
    },
    extraBookingStatus_confirmed_canceled: {
      id: 'shared.extraBookingStatus_confirmed_canceled',
      description: 'confirmed_canceled',
      defaultMessage: 'Avbokning bekräftad',
    },
    extraBookingStatus_contact_declined_time_report: {
      id: 'shared.extraBookingStatus_contact_declined_time_report',
      description: 'contact_declined_time_report',
      defaultMessage: 'Tidrapporten nekad',
    },
    extraBookingStatus_declined_by_resource: {
      id: 'shared.extraBookingStatus_declined_by_resource',
      description: 'declined_by_resource',
      defaultMessage: 'Nekad av resurs',
    },
    extraBookingStatus_denied: {
      id: 'shared.extraBookingStatus_denied',
      description: 'denied',
      defaultMessage: 'Nekad av kund',
    },
    extraBookingStatus_employer_reported_no_show: {
      id: 'shared.extraBookingStatus_employer_reported_no_show',
      description: 'employer_reported_no_show',
      defaultMessage: 'Dök inte upp',
    },
    extraBookingStatus_interested: {
      id: 'shared.extraBookingStatus_interested',
      description: 'interested',
      defaultMessage: 'Ansökt',
    },
    extraBookingStatus_job_accepted: {
      id: 'shared.extraBookingStatus_job_accepted',
      description: 'job_accepted',
      defaultMessage: 'Bokad',
    },
    extraBookingStatus_job_offer: {
      id: 'shared.extraBookingStatus_job_offer',
      description: 'job_offer',
      defaultMessage: 'Inbjuden',
    },
    extraBookingStatus_not_reported: {
      id: 'shared.extraBookingStatus_not_reported',
      description: 'not_reported',
      defaultMessage: 'Genomfört',
    },
    extraBookingStatus_not_started: {
      id: 'shared.extraBookingStatus_not_started',
      description: 'not_started',
      defaultMessage: 'Ej startat',
    },
    extraBookingStatus_ongoing: {
      id: 'shared.extraBookingStatus_ongoing',
      description: 'ongoing',
      defaultMessage: 'Pågående',
    },
    extraBookingStatus_paid: {
      id: 'shared.extraBookingStatus_paid',
      description: 'paid',
      defaultMessage: 'Betalad',
    },
    extraBookingStatus_passed_due_no_response: {
      id: 'shared.extraBookingStatus_passed_due_no_response',
      description: 'passed_due_no_response',
      defaultMessage: 'Inga svarande',
    },
    extraBookingStatus_request_for_interest: {
      id: 'shared.extraBookingStatus_request_for_interest',
      description: 'request_for_interest',
      defaultMessage: 'Förfrågad',
    },
    extraBookingStatus_resource_reported_sick: {
      id: 'shared.extraBookingStatus_resource_reported_sick',
      description: 'resource_reported_sick',
      defaultMessage: 'Sjukanmäld',
    },
  });

// enum BackOfficeMainBookingStatus
const backOfficeMainBookingStatus =
  defineMessages<`backOfficeMainBookingStatus_${BackOfficeMainBookingStatus}`>({
    backOfficeMainBookingStatus_accepted: {
      id: 'shared.backOfficeMainBookingStatus_accepted',
      description: 'Accepted',
      defaultMessage: 'Bokad',
    },
    backOfficeMainBookingStatus_canceled: {
      id: 'shared.backOfficeMainBookingStatus_canceled',
      description: 'Canceled',
      defaultMessage: 'Avbruten',
    },
    backOfficeMainBookingStatus_completed: {
      id: 'shared.backOfficeMainBookingStatus_completed',
      description: 'Completed',
      defaultMessage: 'Genomförd',
    },
    backOfficeMainBookingStatus_requested: {
      id: 'shared.backOfficeMainBookingStatus_requested',
      description: 'Requested',
      defaultMessage: 'Pågående förfrågan',
    },
    backOfficeMainBookingStatus_started: {
      id: 'shared.backOfficeMainBookingStatus_started',
      description: 'Started',
      defaultMessage: 'Startad',
    },
  });

// enum BackOfficeSubBookingStatus
const backOfficeSubBookingStatus =
  defineMessages<`backOfficeSubBookingStatus_${BackOfficeSubBookingStatus}`>({
    backOfficeSubBookingStatus_canceled_by_customer: {
      id: 'shared.backOfficeSubBookingStatus_canceled_by_customer',
      description: 'canceled_by_customer',
      defaultMessage: 'Avbokat av kund',
    },
    backOfficeSubBookingStatus_confirmed_canceled: {
      id: 'shared.backOfficeSubBookingStatus_confirmed_canceled',
      description: 'confirmed_canceled',
      defaultMessage: 'Avbokning bekräftad',
    },
    backOfficeSubBookingStatus_declined_by_resource: {
      id: 'shared.backOfficeSubBookingStatus_declined_by_resource',
      description: 'declined_by_resource',
      defaultMessage: 'Nekat av resurs',
    },
    backOfficeSubBookingStatus_denied_by_customer: {
      id: 'shared.backOfficeSubBookingStatus_denied_by_customer',
      description: 'denied_by_customer',
      defaultMessage: 'Nekat av kund',
    },
    backOfficeSubBookingStatus_interested: {
      id: 'shared.backOfficeSubBookingStatus_interested',
      description: 'interested',
      defaultMessage: 'Ansökt',
    },
    backOfficeSubBookingStatus_job_offer: {
      id: 'shared.backOfficeSubBookingStatus_job_offer',
      description: 'job_offer',
      defaultMessage: 'Inbjuden',
    },
    backOfficeSubBookingStatus_no_applicants: {
      id: 'shared.backOfficeSubBookingStatus_no_applicants',
      description: 'no_applicants',
      defaultMessage: 'Inga svarande',
    },
    backOfficeSubBookingStatus_no_show: {
      id: 'shared.backOfficeSubBookingStatus_no_show',
      description: 'no_show',
      defaultMessage: 'Frånvaro',
    },
    backOfficeSubBookingStatus_not_time_reported: {
      id: 'shared.backOfficeSubBookingStatus_not_time_reported',
      description: 'not_time_reported',
      defaultMessage: 'Ej tidrapporterat',
    },
    backOfficeSubBookingStatus_reported_sick: {
      id: 'shared.backOfficeSubBookingStatus_reported_sick',
      description: 'reported_sick',
      defaultMessage: 'Sjukanmäld',
    },
    backOfficeSubBookingStatus_request_for_interest: {
      id: 'shared.backOfficeSubBookingStatus_request_for_interest',
      description: 'request_for_interest',
      defaultMessage: 'Ej svarat',
    },
    backOfficeSubBookingStatus_time_report_approved: {
      id: 'shared.backOfficeSubBookingStatus_time_report_approved',
      description: 'time_report_approved',
      defaultMessage: 'Attesterad',
    },
    backOfficeSubBookingStatus_time_report_awaiting_approval: {
      id: 'shared.backOfficeSubBookingStatus_time_report_awaiting_approval',
      description: 'time_report_awaiting_approval',
      defaultMessage: 'Tidrapporterad',
    },
    backOfficeSubBookingStatus_time_report_declined: {
      id: 'shared.backOfficeSubBookingStatus_time_report_declined',
      description: 'time_report_declined',
      defaultMessage: 'Tidrapport nekad av kund',
    },
  });

// enum Region
const region = defineMessages<`region_${Region}`>({
  region_Alvsborg: {
    id: 'shared.region_Alvsborg',
    description: 'Älvsborg',
    defaultMessage: 'Älvsborg',
  },
  region_Blekinge: {
    id: 'shared.region_Blekinge',
    description: 'Blekinge',
    defaultMessage: 'Blekinge',
  },
  region_Dalarna: {
    id: 'shared.region_Dalarna',
    description: 'Dalarna',
    defaultMessage: 'Dalarna',
  },
  region_Gavleborg: {
    id: 'shared.region_Gavleborg',
    description: 'Gävleborg',
    defaultMessage: 'Gävleborg',
  },
  region_Goteborg: {
    id: 'shared.region_Goteborg',
    description: 'Göteborg',
    defaultMessage: 'Göteborg',
  },
  region_Gotland: {
    id: 'shared.region_Gotland',
    description: 'Gotland',
    defaultMessage: 'Gotland',
  },
  region_Halland: {
    id: 'shared.region_Halland',
    description: 'Halland',
    defaultMessage: 'Halland',
  },
  region_Jamtland: {
    id: 'shared.region_Jamtland',
    description: 'Jämtland',
    defaultMessage: 'Jämtland',
  },
  region_Jonkoping: {
    id: 'shared.region_Jonkoping',
    description: 'Jönkoping',
    defaultMessage: 'Jönkoping',
  },
  region_Kalmar: {
    id: 'shared.region_Kalmar',
    description: 'Kalmar',
    defaultMessage: 'Kalmar',
  },
  region_Kronoberg: {
    id: 'shared.region_Kronoberg',
    description: 'Kronoberg',
    defaultMessage: 'Kronoberg',
  },
  region_Malmo: {
    id: 'shared.region_Malmo',
    description: 'Malmö',
    defaultMessage: 'Malmö',
  },
  region_Norrbotten: {
    id: 'shared.region_Norrbotten',
    description: 'Norrbotten',
    defaultMessage: 'Norrbotten',
  },
  region_Oland: {
    id: 'shared.region_Oland',
    description: 'Öland',
    defaultMessage: 'Öland',
  },
  region_Orebro: {
    id: 'shared.region_Orebro',
    description: 'Örebro',
    defaultMessage: 'Örebro',
  },
  region_Ostergotland: {
    id: 'shared.region_Ostergotland',
    description: 'Östergotland',
    defaultMessage: 'Östergotland',
  },
  region_Skane: {
    id: 'shared.region_Skane',
    description: 'Skåne',
    defaultMessage: 'Skåne',
  },
  region_Skaraborg: {
    id: 'shared.region_Skaraborg',
    description: 'Skaraborg',
    defaultMessage: 'Skaraborg',
  },
  region_Smaland: {
    id: 'shared.region_Smaland',
    description: 'Småland',
    defaultMessage: 'Småland',
  },
  region_Sodermanland: {
    id: 'shared.region_Sodermanland',
    description: 'Södermanland',
    defaultMessage: 'Södermanland',
  },
  region_Stockholm: {
    id: 'shared.region_Stockholm',
    description: 'Stockholm',
    defaultMessage: 'Stockholm',
  },
  region_Uppsala: {
    id: 'shared.region_Uppsala',
    description: 'Uppsala',
    defaultMessage: 'Uppsala',
  },
  region_Varmland: {
    id: 'shared.region_Varmland',
    description: 'Värmland',
    defaultMessage: 'Värmland',
  },
  region_Vasterbotten: {
    id: 'shared.region_Vasterbotten',
    description: 'Västerbotten',
    defaultMessage: 'Västerbotten',
  },
  region_Vasternorrland: {
    id: 'shared.region_Vasternorrland',
    description: 'Västernorrland',
    defaultMessage: 'Västernorrland',
  },
  region_Vastmanland: {
    id: 'shared.region_Vastmanland',
    description: 'Västmanland',
    defaultMessage: 'Västmanland',
  },
});

// enum Language
const language = defineMessages<`language_${Language}`>({
  language_english: {
    id: 'shared.language_english',
    description: 'English',
    defaultMessage: 'Engelska',
  },
  language_swedish: {
    id: 'shared.language_swedish',
    description: 'swedish',
    defaultMessage: 'Svenska',
  },
});

// Generic messages
const generic = defineMessages({
  generic_blocked: {
    id: 'shared.generic_blocked',
    description: 'Blocked',
    defaultMessage: 'Blockerad',
  },
  generic_yes: {
    id: 'shared.generic_yes',
    description: 'Yes',
    defaultMessage: 'Ja',
  },
  generic_no: {
    id: 'shared.generic_no',
    description: 'No',
    defaultMessage: 'Nej',
  },
  generic_year: {
    id: 'shared.generic_year',
    description: 'Year',
    defaultMessage: 'År',
  },
  generic_qty: {
    id: 'shared.generic_qty',
    description: 'Abbreviation for "quantity"',
    defaultMessage: '{quantity} st',
  },
  generic_currency_per_hour: {
    id: 'shared.generic_currency_per_hour',
    description: 'Currency per hour',
    defaultMessage: '{currency}/h',
  },
});

// Form messages
const form = defineMessages({
  form_labelFirstName: {
    id: 'shared.form_labelFirstName',
    description: 'Label first name',
    defaultMessage: 'Förnamn',
  },
  form_labelLastName: {
    id: 'shared.form_labelLastName',
    description: 'Label last name',
    defaultMessage: 'Efternamn',
  },
  form_labelEmail: {
    id: 'shared.form_labelEmail',
    description: 'Label email',
    defaultMessage: 'E-post',
  },
  form_labelPhone: {
    id: 'shared.form_labelPhone',
    description: 'Label phone',
    defaultMessage: 'Telefonnummer',
  },
  form_labelMobileNumber: {
    id: 'shared.form_labelMobileNumber',
    description: 'Label mobile number',
    defaultMessage: 'Telefonnummer',
  },
  form_labelCategory: {
    id: 'shared.form_labelCategory',
    description: 'Label category',
    defaultMessage: 'Kategori',
  },
  form_labelName: {
    id: 'shared.form_labelName',
    description: 'Label name',
    defaultMessage: 'Namn',
  },
  form_labelNameCompany: {
    id: 'shared.form_labelNameCompany',
    description: 'Label name company',
    defaultMessage: 'Företagsnamn',
  },
  form_labelNameLocation: {
    id: 'shared.form_labelNameLocation',
    description: 'Label name location',
    defaultMessage: 'Arbetsplatsens namn',
  },
  form_labelStreet: {
    id: 'shared.form_labelStreet',
    description: 'Label street',
    defaultMessage: 'Adress',
  },
  form_labelPostalCode: {
    id: 'shared.form_labelPostalCode',
    description: 'Label postal code',
    defaultMessage: 'Postnummer',
  },
  form_labelCity: {
    id: 'shared.form_labelCity',
    description: 'Label city',
    defaultMessage: 'Stad',
  },
  form_labelLatitude: {
    id: 'shared.form_labelLatitude',
    description: 'Label latitude',
    defaultMessage: 'Latitud',
  },
  form_labelLongitude: {
    id: 'shared.form_labelLongitude',
    description: 'Label longitude',
    defaultMessage: 'Longitud',
  },
  form_labelBillingCity: {
    id: 'shared.form_labelBillingCity',
    description: 'Label billing city',
    defaultMessage: 'Fakturastad',
  },
  form_labelBillingPostalCode: {
    id: 'shared.form_labelBillingPostalCode',
    description: 'Label billing postal code',
    defaultMessage: 'Fakturapostnummer',
  },
  form_labelBillingStreet: {
    id: 'shared.form_labelBillingStreet',
    description: 'Label billing street',
    defaultMessage: 'Fakturaadress',
  },
  form_labelBillingReference: {
    id: 'shared.form_labelBillingReference',
    description: 'Label billing reference',
    defaultMessage: 'Fakturareferens',
  },
  form_labelEInvoice: {
    id: 'shared.form_labelEInvoice',
    description: 'Label e invoice',
    defaultMessage: 'E-post för e-faktura',
  },
  form_labelOrganisationNumber: {
    id: 'shared.form_labelOrganisationNumber',
    description: 'Label organisation number',
    defaultMessage: 'Organisationsnummer',
  },
  form_labelRegion: {
    id: 'shared.form_labelRegion',
    description: 'Label region',
    defaultMessage: 'Region',
  },
  form_labelRole: {
    id: 'shared.form_labelRole',
    description: 'Label role',
    defaultMessage: 'Behörighet',
  },
  form_labelWeb: {
    id: 'shared.form_labelWeb',
    description: 'Label web',
    defaultMessage: 'Webbplats',
  },
  form_labelContactUser: {
    id: 'shared.form_labelContactUser',
    description: 'Label contact user',
    defaultMessage: 'Kontaktperson',
  },
  form_labelDescription: {
    id: 'shared.form_labelDescription',
    description: 'Label description',
    defaultMessage: 'Beskrivning',
  },
  form_labelDescriptionRole: {
    id: 'shared.form_labelDescriptionRole',
    description: 'Label description role',
    defaultMessage: 'Rollbeskrivning',
  },
  form_labelDescriptionJob: {
    id: 'shared.form_labelDescriptionJob',
    description: 'Label description job',
    defaultMessage: 'Uppdragsbeskrivning',
  },
  form_labelOldPassword: {
    id: 'shared.form_labelOldPassword',
    description: 'Label old password',
    defaultMessage: 'Nuvarande lösenord',
  },
  form_labelNewPassword: {
    id: 'shared.form_labelNewPassword',
    description: 'Label new password',
    defaultMessage: 'Nytt lösenord',
  },
  form_labelNewPasswordRepeat: {
    id: 'shared.form_labelNewPasswordRepeat',
    description: 'Label new password repeat',
    defaultMessage: 'Upprepa nytt lösenord',
  },
  form_labelResourceCategoryName: {
    id: 'shared.form_labelResourceCategoryName',
    description: 'Label resource category name',
    defaultMessage: 'Yrke',
  },
  form_labelResourceCategoryLevel: {
    id: 'shared.form_labelResourceCategoryLevel',
    description: 'Label resource category level',
    defaultMessage: 'Nivå',
  },
  form_labelPassword: {
    id: 'shared.form_labelPassword',
    description: 'Label password',
    defaultMessage: 'Lösenord',
  },
  form_labelInternalComment: {
    id: 'shared.form_labelInternalComment',
    description: 'Label internal comment',
    defaultMessage: 'Intern notering',
  },
  form_labelEmergencyContactName: {
    id: 'shared.form_labelEmergencyContactName',
    description: 'Label emergency contact name',
    defaultMessage: 'Kontaktpersonens namn',
  },
  form_labelEmergencyContactPhone: {
    id: 'shared.form_labelEmergencyContactPhone',
    description: 'Label emergency contact phone',
    defaultMessage: 'Kontaktpersonens telefonnummer',
  },
  form_labelStatus: {
    id: 'shared.form_labelStatus',
    description: 'Label status',
    defaultMessage: 'Status',
  },
  form_labelCompany: {
    id: 'shared.form_labelCompany',
    description: 'Label company',
    defaultMessage: 'Företag',
  },
  form_labelTemplateName: {
    id: 'shared.form_labelTemplateName',
    description: 'Label template name',
    defaultMessage: 'Namn på mall',
  },
  form_labelLocations: {
    id: 'shared.form_labelLocations',
    description: 'Label locations',
    defaultMessage: 'Arbetsplatser',
  },
  form_labelOccupationCategory: {
    id: 'shared.form_labelOccupationCategory',
    description: 'Label occupation category',
    defaultMessage: 'Yrke',
  },
  form_labelWorkShiftSlot: {
    id: 'shared.form_labelWorkShiftSlot',
    description: 'Label work shift slot',
    defaultMessage: 'Arbetspass',
  },
  form_labelAddition: {
    id: 'shared.form_labelAddition',
    description: 'Label addition',
    defaultMessage: 'Tillägg',
  },
  form_labelLegacyCustomerId: {
    id: 'shared.form_labelLegacyCustomerId',
    description: 'Label legacy customer id',
    defaultMessage: 'Legacy customer id',
  },
  form_labelLegacyId: {
    id: 'shared.form_labelLegacyId',
    description: 'Label legacy id',
    defaultMessage: 'Legacy id',
  },
  form_labelsocialSecurityNumber: {
    id: 'shared.form_labelsocialSecurityNumber',
    description: 'Label social security number',
    defaultMessage: 'Personnummer',
  },
});

// Table messages
const table = defineMessages({
  table_columnName: {
    id: 'shared.table_columnName',
    description: 'Column name',
    defaultMessage: 'Namn',
  },
  table_columnAwards: {
    id: 'shared.table_columnAwards',
    description: 'Column awards',
    defaultMessage: 'Utmärkelser',
  },
  table_columnNameLocation: {
    id: 'shared.table_columnNameLocation',
    description: 'Column name location',
    defaultMessage: 'Arbetsplatsens namn',
  },
  table_columnCompany: {
    id: 'shared.table_columnCompany',
    description: 'Column company',
    defaultMessage: 'Kund',
  },
  table_columnLocation: {
    id: 'shared.table_columnLocation',
    description: 'Column location',
    defaultMessage: 'Arbetsplats',
  },
  table_columnAddress: {
    id: 'shared.table_columnAddress',
    description: 'Column address',
    defaultMessage: 'Adress',
  },
  table_columnContactUser: {
    id: 'shared.table_columnContactUser',
    description: 'Column contactUser',
    defaultMessage: 'Kontaktperson',
  },
  table_columnPhone: {
    id: 'shared.table_columnPhone',
    description: 'Column phone',
    defaultMessage: 'Telefonnummer',
  },
  table_columnMobileNumber: {
    id: 'shared.table_columnMobileNumber',
    description: 'Column mobile number',
    defaultMessage: 'Telefonnummer',
  },
  table_columnEmail: {
    id: 'shared.table_columnEmail',
    description: 'Column email',
    defaultMessage: 'E-post',
  },
  table_columnRole: {
    id: 'shared.table_columnRole',
    description: 'Column role',
    defaultMessage: 'Behörighet',
  },
  table_columnSignup: {
    id: 'shared.table_columnSignup',
    description: 'Column signup date',
    defaultMessage: 'Registrering',
  },
  table_columnApproved: {
    id: 'shared.table_columnApproved',
    description: 'Column approved',
    defaultMessage: 'Granskning',
  },
  table_columnNote: {
    id: 'shared.table_columnNote',
    description: 'Column note',
    defaultMessage: 'Notering',
  },
  table_columnOccupationCategory: {
    id: 'shared.table_columnOccupationCategory',
    description: 'Column occupation category',
    defaultMessage: 'Yrke',
  },
  table_columnUpdatedAt: {
    id: 'shared.table_columnUpdatedAt',
    description: 'Column updated at',
    defaultMessage: 'Senast ändrad',
  },
  table_columnCreatedAt: {
    id: 'shared.table_columnCreatedAt',
    description: 'Column created at',
    defaultMessage: 'Skapad',
  },
  table_columnCreatedBy: {
    id: 'shared.table_columnCreatedBy',
    description: 'Column created by',
    defaultMessage: 'Bokare',
  },
  table_columnLocations: {
    id: 'shared.table_columnLocations',
    description: 'Column locations',
    defaultMessage: 'Arbetsplatser',
  },
  table_emptyTable: {
    id: 'shared.table_emptyTable',
    description: 'Empty table',
    defaultMessage: 'Hittade inga resultat',
  },
  table_columnWorkExperience: {
    id: 'shared.table_columnWorkExperience',
    description: 'Column work experience',
    defaultMessage: 'Arbetserfarenhet',
  },
  table_columnResourceCategoryName: {
    id: 'shared.table_columnResourceCategoryName',
    description: 'Column resource category name',
    defaultMessage: 'Yrke',
  },
  table_columnResourceCategoryLevel: {
    id: 'shared.table_columnResourceCategoryLevel',
    description: 'Column resource category level',
    defaultMessage: 'Nivå',
  },
  table_columnResourceCategoryLevels: {
    id: 'shared.table_columnResourceCategoryLevels',
    description: 'Column resource category levels',
    defaultMessage: 'Nivåer',
  },
  table_columnRegion: {
    id: 'shared.table_columnRegion',
    description: 'Column region',
    defaultMessage: 'Region',
  },
  table_columnInformation: {
    id: 'shared.table_columnInformation',
    description: 'Column information',
    defaultMessage: 'Information',
  },
  table_columnFavourite: {
    id: 'shared.table_columnFavourite',
    description: 'Column Favourite',
    defaultMessage: 'Favorit',
  },
  table_columnResource: {
    id: 'shared.table_columnResource',
    description: 'Column resource',
    defaultMessage: 'Resurs',
  },
  table_columnVerticalProfileCategory: {
    id: 'shared.table_columnVerticalProfileCategory',
    description: 'Column vertical profile category',
    defaultMessage: 'Yrkesgrupp',
  },
  table_columnResourceLevelsName: {
    id: 'shared.table_columnResourceLevelsName',
    description: 'Column resource levels name',
    defaultMessage: 'Nivå',
  },
  table_columnDate: {
    id: 'shared.table_columnDate',
    description: 'Column date',
    defaultMessage: 'Datum',
  },
  table_columnWorkingHours: {
    id: 'shared.table_columnWorkingHours',
    description: 'Column working hours',
    defaultMessage: 'Arbetstid',
  },
  table_columnTimeReport: {
    id: 'shared.table_columnTimeReport',
    description: 'Column time report',
    defaultMessage: 'Rapporterad tid',
  },
  table_columnTitle: {
    id: 'shared.table_columnTitle',
    description: 'Column title',
    defaultMessage: 'Rubrik',
  },
  table_columnMessage: {
    id: 'shared.table_columnMessage',
    description: 'Column message',
    defaultMessage: 'Meddelande',
  },
  table_columnType: {
    id: 'shared.table_columnType',
    description: 'Column type',
    defaultMessage: 'Typ',
  },
  table_columnInvoiced: {
    id: 'shared.table_columnInvoiced',
    description: 'Column invoiced',
    defaultMessage: 'Fakturerad',
  },
  table_columnPaid: {
    id: 'shared.table_columnPaid',
    description: 'Column paid',
    defaultMessage: 'Utbetald lön',
  },
  table_columnApprovedByContact: {
    id: 'shared.table_columnApprovedByContact',
    description: 'Column approved by contact',
    defaultMessage: 'Attesterad',
  },
  table_columnStartTime: {
    id: 'shared.table_columnStartTime',
    description: 'Column start time',
    defaultMessage: 'Starttid',
  },
  table_columnEndTime: {
    id: 'shared.table_columnEndTime',
    description: 'Column end time',
    defaultMessage: 'Sluttid',
  },
  table_columnBookingType: {
    id: 'shared.table_columnBookingType',
    description: 'Column booking type',
    defaultMessage: 'Bokningstyp',
  },
  table_columnStatus: {
    id: 'shared.table_columnStatus',
    description: 'Column status',
    defaultMessage: 'Status',
  },
  table_columnLevels: {
    id: 'shared.table_columnLevels',
    description: 'Column levels',
    defaultMessage: 'Nivåer',
  },
  table_columnReviewed: {
    id: 'shared.table_columnReviewed',
    description: 'Column reviewed',
    defaultMessage: 'Granskad',
  },
  table_columnReportedStart: {
    id: 'shared.table_columnReportedStart',
    description: 'Column reported start',
    defaultMessage: 'Startat pass',
  },
  table_columnSuggestedTime: {
    id: 'shared.table_columnSuggestedTime',
    description: 'Column suggested time',
    defaultMessage: 'Föreslagen tid',
  },
  table_columnSubStatus: {
    id: 'shared.table_columnSubStatus',
    description: 'Column sub status',
    defaultMessage: 'Substatus',
  },
  table_columnBookedTime: {
    id: 'shared.table_columnBookedTime',
    description: 'Column booked time',
    defaultMessage: 'Bokad tid',
  },
  table_columnActive: {
    id: 'shared.table_columnActive',
    description: 'Column active',
    defaultMessage: 'Aktiv',
  },
  table_columnReceiver: {
    id: 'shared.table_columnReceiver',
    description: 'Column receiver',
    defaultMessage: 'Mottagare',
  },
  table_columnRespondent: {
    id: 'shared.table_columnRespondent',
    description: 'Column respondent',
    defaultMessage: 'Svarande',
  },
});

// RestaurantWorkExperience
const restaurantWorkExperienceYears =
  defineMessages<`workExperienceYears_${RestaurantWorkExperienceYearsKey}`>({
    workExperienceYears_go: {
      id: 'shared.workExperienceYears_go',
      description: 'Work experience years from one to three years',
      defaultMessage: '1-3',
    },
    workExperienceYears_pro: {
      id: 'shared.workExperienceYears_pro',
      description: 'Work experience years from three to six years',
      defaultMessage: '3-6',
    },
    workExperienceYears_premium: {
      id: 'shared.workExperienceYears_premium',
      description: 'Work experience years more than six years',
      defaultMessage: '6+',
    },
  });

export const shared = {
  region,
  verticalCategory,
  notificationType,
  workShiftStatus,
  userRole,
  form,
  table,
  restaurantVerticalCategory,
  generic,
  language,
  bookingStatus,
  bookingType,
  extraBookingStatus,
  channelOrigin,
  restaurantWorkExperienceYears,
  backOfficeMainBookingStatus,
  backOfficeSubBookingStatus,
};
