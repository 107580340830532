import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import moment from 'moment';
import { IntlProvider } from 'react-intl';

import 'moment/locale/sv';

import { messages } from 'lang/messages';

export type SupportedLocales = 'sv';

type TranslationsContextProviderProps = {
  children: ReactNode;
};

export const TranslationsContext = createContext<
  [SupportedLocales, Dispatch<SetStateAction<SupportedLocales>>]
>(['sv', () => {}]);

export const TranslationsContextProvider = ({
  children,
}: TranslationsContextProviderProps) => {
  const [language, setLanguage] = useState<SupportedLocales>('sv');

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const getMessages = useCallback((lang: SupportedLocales) => {
    switch (lang) {
      case 'sv':
      default:
        return messages.sv;
    }
  }, []);

  const value = useMemo<
    [SupportedLocales, Dispatch<SetStateAction<SupportedLocales>>]
  >(() => [language, setLanguage], [language]);

  return (
    <TranslationsContext.Provider value={value}>
      <IntlProvider
        key={language}
        messages={getMessages(language)}
        locale={language}
        defaultLocale="sv"
      >
        {children}
      </IntlProvider>
    </TranslationsContext.Provider>
  );
};

export const TranslationsContextConsumer = TranslationsContext.Consumer;
