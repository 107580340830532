import {
  RestaurantVerticalCategory,
  RestaurantWorkExperience,
} from 'api/graphql';

export const removeDuplicatedWorkExperiences = (
  workExperiences: RestaurantWorkExperience[]
) => {
  const getLatestWorkExperience = (key: RestaurantVerticalCategory) => {
    const sorted = workExperiences
      .filter(({ category }) => category === key)
      .sort((exp1, exp2) => {
        const previousExperienceTime = new Date(exp1.createdAt).getTime();
        const nextExperienceTime = new Date(exp2.createdAt).getTime();
        return nextExperienceTime - previousExperienceTime;
      });
    return sorted.length > 0 ? sorted[0] : false;
  };

  const result = [] as RestaurantWorkExperience[];
  Object.values(RestaurantVerticalCategory).forEach((key) => {
    const workExperience = getLatestWorkExperience(key);
    if (!workExperience) {
      return;
    }
    result.push(workExperience);
  });

  return result;
};
