import { RestaurantVerticalCategory } from 'api/graphql';
import { Names } from 'types/icon';

const iconsRestaurantVerticalCategory: {
  [key in RestaurantVerticalCategory]: Names;
} = {
  bar: 'glass-martini-alt',
  chef: 'hat-chef',
  event: 'glass-cheers',
  service: 'utensils',
  wash: 'pump-soap',
  other: 'ellipsis',
};

export const resource = {
  iconsRestaurantVerticalCategory,
};
