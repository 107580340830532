import { useCallback } from 'react';

type NotifyParams = {
  title: string;
  body?: string;
  onClick?: () => void;
};

export const useDesktopNotification = () => {
  const notificationIsAvailable = 'Notification' in window;

  const permissionIsGranted = notificationIsAvailable
    ? Notification.permission === 'granted'
    : false;

  const requestPermission = useCallback(async () => {
    if (!notificationIsAvailable) {
      console.warn('Your browser does not support desktop notifications');
      return;
    }

    if (permissionIsGranted) {
      return;
    }

    await Notification.requestPermission();
  }, [permissionIsGranted, notificationIsAvailable]);

  const notify = useCallback(
    ({ title, body, onClick }: NotifyParams) => {
      if (!permissionIsGranted || !notificationIsAvailable) {
        return;
      }

      const notification = new Notification(title, {
        body,
      });

      if (onClick) {
        notification.onclick = () => {
          onClick();
        };
      }
    },
    [permissionIsGranted, notificationIsAvailable]
  );

  return { notify, requestPermission };
};
