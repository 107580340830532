import { ChangeEvent, MutableRefObject, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { Icon } from 'components/ui/general';
import { CheckboxSelectors } from 'consts/cypress';

import styles from './Checkbox.module.scss';

export type CheckboxProps = {
  name: string;
  value?: string;
  label?: ReactNode;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: FieldErrors;
  defaultChecked?: boolean;
  checkboxRef?: MutableRefObject<HTMLInputElement | null>;
};

export const Checkbox = ({
  name,
  value,
  ariaLabel,
  className,
  disabled,
  register,
  validation = {},
  onChange,
  onBlur,
  label,
  error,
  defaultChecked,
  checkboxRef,
}: CheckboxProps) => {
  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  return (
    <>
      <label
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles.hasLabel]: !!label,
        })}
        data-cy={CheckboxSelectors.Root}
      >
        <input
          type="checkbox"
          value={value}
          disabled={disabled}
          aria-label={ariaLabel}
          className={styles.input}
          defaultChecked={defaultChecked}
          {...registerHolder}
          ref={(event) => {
            if (checkboxRef) checkboxRef.current = event;
            registerHolder.ref(event);
          }}
          onChange={(event) => {
            registerHolder.onChange(event);
            onChange?.(event);
          }}
          onBlur={(event) => {
            registerHolder.onBlur(event);
            onBlur?.(event);
          }}
        />
        <div className={styles.checkboxHolder}>
          <Icon name="square" font="nuuk" className={styles.square} />
          <Icon
            name="check-square"
            font="honolulu"
            className={styles.checkSquare}
          />
        </div>
        {!!label && (
          <div className={styles.labelHolder}>
            <div className={styles.label}>
              {label}
              {getValidation.required ? ' *' : ''}
            </div>
          </div>
        )}
      </label>
      <Error error={error} />
    </>
  );
};
