import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.userRole,
  ...shared.form,
  heading: {
    id: 'EditUserModal.heading',
    description: 'Heading',
    defaultMessage: 'Redigera användare',
  },
  button: {
    id: 'EditUserModal.button',
    description: 'Button',
    defaultMessage: 'Spara',
  },
  trigger: {
    id: 'EditUserModal.trigger',
    description: 'Trigger',
    defaultMessage: 'Redigera',
  },
  editUserSuccess: {
    id: 'EditUserModal.editUserSuccess',
    description: 'Edit user success',
    defaultMessage: 'Användaren är uppdaterad',
  },
});
