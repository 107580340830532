import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { usePopperTooltip } from 'react-popper-tooltip';

import { Portal } from 'components/tools';
import { TooltipSelectors } from 'consts/cypress';
import { Durations, Easings } from 'consts/transition';
import { useKeyPress, useScroll } from 'hooks';

import styles from './Tooltip.module.scss';

export type TooltipProps = {
  children: ReactNode;
  tip: ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  trigger?: 'click' | 'hover';
  classNameTrigger?: string;
  classNameTooltip?: string;
  color?: 'dark' | 'light'; // color names from $all-colors
  onClick?: MouseEventHandler<HTMLButtonElement>;
  maxWidth?: string | number;
};

export const Tooltip = ({
  children,
  tip,
  placement = 'top',
  trigger = 'click',
  classNameTrigger,
  classNameTooltip,
  color = 'dark',
  onClick,
  maxWidth,
}: TooltipProps) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const padding = useMemo(
    () => ({
      top: 56,
      left: 0,
    }),
    []
  );
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    forceUpdate,
  } = usePopperTooltip(
    {
      trigger,
      placement,
      visible: controlledVisible,
      onVisibleChange: setControlledVisible,
    },
    {
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom', 'right', 'left'],
            padding,
          },
        },
      ],
    }
  );

  const closeTooltip = useCallback(() => {
    if (controlledVisible) setControlledVisible(false);
  }, [controlledVisible]);

  useKeyPress(27, () => {
    closeTooltip();
  });

  useScroll(() => {
    closeTooltip();
  });

  useEffect(() => {
    forceUpdate?.();
  }, [children, forceUpdate]);

  const Trigger = useMemo<any>(() => {
    switch (trigger) {
      case 'hover':
        return 'div';

      default:
        return 'button';
    }
  }, [trigger]);

  return (
    <>
      <Trigger
        type={trigger === 'click' ? 'button' : undefined}
        ref={setTriggerRef}
        className={classNames(styles.trigger, classNameTrigger)}
        data-cy={TooltipSelectors.Trigger}
        onClick={onClick}
      >
        {children}
      </Trigger>
      <AnimatePresence>
        {visible && (
          <Portal selector="#tooltip">
            <motion.div
              ref={setTooltipRef}
              transition={{ duration: Durations.Fast, ease: Easings.InOut }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              {...getTooltipProps({
                className: classNames(styles.tooltip, classNameTooltip, {
                  [styles[`${color || 'dark'}Color`]]: true,
                }),
                style: { maxWidth },
              })}
              data-cy={TooltipSelectors.Tooltip}
            >
              <div className={styles.inner}>{tip}</div>
              <div {...getArrowProps({ className: styles.arrow })} />
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};
