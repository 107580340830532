import { ReactNode, useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { QueryParameters } from 'consts/stepper';
import { useUrlQuery } from 'hooks';

import styles from './Stepper.module.scss';

export type StepperProps = {
  steps: {
    id: string;
    label: ReactNode;
    content: ReactNode;
    contentStripPadding?: boolean;
    disabled?: boolean;
  }[];
  activeStepId?: string;
  className?: string;
  renderTop?: boolean;
};

export const Stepper = ({
  steps,
  activeStepId,
  className,
  renderTop = true,
}: StepperProps) => {
  const { get, set } = useUrlQuery();
  const getActiveStepId = useMemo(
    () => get(QueryParameters.ActiveStepId) || activeStepId || steps[0].id,
    [activeStepId, steps, get]
  );

  const renderNavigation = useCallback(
    () =>
      steps.map(({ id, label, disabled }, index) => (
        <button
          key={id}
          type="button"
          onClick={() => set(QueryParameters.ActiveStepId, id)}
          className={classNames(styles.button, {
            [styles.active]: getActiveStepId === id,
            [styles.previous]:
              steps.findIndex((step) => step.id === getActiveStepId) > index,
          })}
          disabled={disabled}
        >
          {label}
        </button>
      )),
    [getActiveStepId, steps, set]
  );

  const renderContent = useCallback(
    () =>
      steps.map(({ id, content, contentStripPadding }) => {
        if (getActiveStepId === id) {
          return (
            <div
              key={id}
              className={classNames(styles.content, {
                [styles.contentStripPadding]: contentStripPadding,
              })}
            >
              {content}
            </div>
          );
        }

        return null;
      }),
    [getActiveStepId, steps]
  );

  return (
    <div className={classNames(styles.root, className)}>
      {renderTop && (
        <div className={styles.top}>
          <nav className={styles.navigation}>{renderNavigation()}</nav>
        </div>
      )}
      {renderContent()}
    </div>
  );
};
