/**
 * When creating a path with params you should use a function as below:
 *
 * ExampleParams: (id?: number) => (id ? `/example/${id}` : `/example/:id`)
 * - Paths.ExampleParams() --> '/example/:id'
 * - Paths.ExampleParams(123) --> '/example/123'
 */

export const Paths = Object.freeze({
  Landing: '/',
  Login: '/login',
  Companies: '/companies',
  Company: (companyId?: string | number) =>
    companyId ? `/companies/${companyId}` : '/companies/:companyId',
  CompanyContact: (companyId?: string | number, contactId?: string | number) =>
    companyId && contactId
      ? `/companies/${companyId}/contacts/${contactId}`
      : '/companies/:companyId/contacts/:contactId',
  CompanyLocation: (
    companyId?: string | number,
    locationId?: string | number
  ) =>
    companyId && locationId
      ? `/companies/${companyId}/locations/${locationId}`
      : '/companies/:companyId/locations/:locationId',
  ForgotPassword: '/forgot-password',
  ResetPassword: (email?: string, nonce?: string) =>
    email && nonce
      ? `/reset-password/${email}/${nonce}`
      : '/reset-password/:email/:nonce',
  Settings: '/settings',
  ResourceCategories: '/resource-categories',
  ResourceCategory: (resourceCategoryId?: string | number) =>
    resourceCategoryId
      ? `/resource-categories/${resourceCategoryId}`
      : '/resource-categories/:resourceCategoryId',
  Resources: '/resources',
  Resource: (resourceId?: string | number) =>
    resourceId ? `/resources/${resourceId}` : '/resources/:resourceId',
  Users: '/users',
  User: (userId?: string | number) =>
    userId ? `/users/${userId}` : '/users/:userId',
  Templates: '/templates',
  Bookings: '/bookings',
  BookingsPreview: (workShiftSlotId?: string | number) =>
    workShiftSlotId
      ? `/bookings/preview/${workShiftSlotId}`
      : '/bookings/preview/:workShiftSlotId',
  Booking: '/bookings/booking',
  Chat: '/chat',
  ChatGroups: '/chat/groups',
  ChatBroadcasts: '/chat/broadcasts',
  Assignments: '/assignments',
  Notifications: '/notifications',
  LegacyImport: '/legacy-import',
  Attests: '/attests',
});
