import { useState, useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Scalars,
  useCustomBroadcastChatGroupRespondersLazyQuery,
} from 'api/graphql';
import { Button, Empty, Container } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { table } from 'utils';

import { NavigateToChat } from './subcomponents';

import { texts } from './BroadcastRespondentsModal.text';

type BroadcastRespondentsModalProps = {
  respondents: number;
  chatGroupId: Scalars['ID'];
};

export const BroadcastRespondentsModal = ({
  respondents,
  chatGroupId,
}: BroadcastRespondentsModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [fetchRespondents, { data, loading, error }] =
    useCustomBroadcastChatGroupRespondersLazyQuery();

  const fetchData = useCallback(
    ({ pageIndex, pageSize }: TableFetchData) => {
      if (!isOpen) return;

      fetchRespondents({
        variables: {
          id: chatGroupId,
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
          },
        },
      });
    },
    [chatGroupId, fetchRespondents, isOpen]
  );

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'firstName',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }: any) => <p>{`${firstName} ${lastName}`}</p>,
      },
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => <NavigateToChat userId={id} chatGroupId={chatGroupId} />,
      },
    ],
    [chatGroupId]
  );
  return (
    <>
      <Button size="xs" color="secondary" onClick={() => setIsOpen(true)}>
        {String(respondents)}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        middleSize="lg"
        middleStripPadding
      >
        <Container fullWidth vertical>
          <h5>
            <FormattedMessage {...texts.heading} />
          </h5>
        </Container>
        <Table
          pagination
          isInModalMiddle
          lastTdRight
          totalPages={table.getTotalPages(data?.chatGroupResponders.meta)}
          columns={columns}
          data={data?.chatGroupResponders.edges || []}
          onFetchData={fetchData}
          loading={loading}
          handleQueryParameters={false}
          empty={
            <Empty
              title={<FormattedMessage {...texts.table_emptyTable} />}
              message={error?.message}
            />
          }
        />
      </Modal>
    </>
  );
};
