import { useMemo, useCallback, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  CustomNotificationSubscribeDocument,
  CustomNotificationSubscribeSubscription,
  CustomNotificationSubscribeSubscriptionVariables,
  NotificationType,
  useCustomNotificationsLazyQuery,
} from 'api/graphql';
import { Empty } from 'components/ui/general';
import { Table, TableFetchData } from 'components/ui/table';
import { DateFormat } from 'consts/date';
import { useAuth } from 'hooks';
import { format, table } from 'utils';

import { Redirect } from './subcomponents';

import { texts } from './NotificationsTable.text';

export const NotificationsTable = () => {
  const { user } = useAuth();
  const [
    fetchNotifications,
    { data, loading, error, subscribeToMore, refetch },
  ] = useCustomNotificationsLazyQuery();

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      CustomNotificationSubscribeSubscription,
      CustomNotificationSubscribeSubscriptionVariables
    >({
      document: CustomNotificationSubscribeDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        if (subscriptionData.data.notificationSubscribe) {
          refetch();
        }

        return prev;
      },
    });

    return () => unsubscribe?.();
  }, [subscribeToMore, refetch]);

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnTitle} />,
        accessor: 'title',
      },
      {
        Header: <FormattedMessage {...texts.table_columnMessage} />,
        accessor: 'message',
      },
      {
        Header: <FormattedMessage {...texts.table_columnType} />,
        accessor: 'type',
        Cell: ({
          row: {
            original: { type },
          },
        }: any) => (
          <FormattedMessage
            {...texts[`notificationType_${type as NotificationType}`]}
          />
        ),
      },
      {
        Header: <FormattedMessage {...texts.table_columnDate} />,
        accessor: 'createdAt',
        Cell: ({
          row: {
            original: { createdAt },
          },
        }: any) => format.date({ date: createdAt }).format(DateFormat.Date),
      },
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => (
          <Redirect notification={original} />
        ),
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: TableFetchData) => {
      const defaultSorting = [
        {
          field: 'createdAt',
          direction: 'desc',
        },
      ];

      fetchNotifications({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            userId: user?.id,
          },
          sorting: {
            sorting: sortBy.length
              ? sortBy.map(({ id, desc }) => ({
                  field: id,
                  direction: desc ? 'desc' : 'asc',
                }))
              : defaultSorting,
          },
        },
      });
    },
    [fetchNotifications, user]
  );

  return (
    <Table
      pagination
      sort
      lastTdRight
      totalPages={table.getTotalPages(data?.notifications.meta)}
      columns={columns}
      data={data?.notifications.edges || []}
      onFetchData={fetchData}
      loading={loading}
      empty={
        <Empty
          title={<FormattedMessage {...texts.table_emptyTable} />}
          message={error?.message}
        />
      }
    />
  );
};
