import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  ButtonProps,
  Grid,
  Gutter,
  Information,
} from 'components/ui/general';
import { Modal, ModalProps } from 'components/ui/modals';
import { ActionModalSelectors } from 'consts/cypress';
import { capitalizeFirstLetter } from 'utils';

import { texts } from './ActionModal.text';

import styles from './ActionModal.module.scss';

export type ActionModalProps = {
  children?: ReactNode;
  trigger?: ReactNode;
  triggerDisabled?: boolean;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  classNameTrigger?: string;
  heading?: ReactNode;
  button?: ButtonProps;
  buttonMiddle?: ButtonProps;
  as?: keyof JSX.IntrinsicElements;
  size?: ModalProps['middleSize'];
  transparent?: ModalProps['middleTransparent'];
  stripPadding?: ModalProps['middleStripPadding'];
  buttonCancel?: ButtonProps | null;
  error?: {
    message: string;
  };
  errorBackground?: boolean;
  [key: string]: any;
};

export const ActionModal = ({
  isOpen,
  setIsOpen,
  children,
  trigger,
  triggerDisabled,
  classNameTrigger,
  heading,
  button,
  buttonMiddle,
  as,
  size,
  transparent,
  stripPadding,
  buttonCancel,
  error,
  errorBackground,
  ...props
}: ActionModalProps) => {
  const [controlledIsOpen, setControlledIsOpen] = useState(false);

  const getIsOpen = useMemo(
    () => (typeof isOpen === 'boolean' ? isOpen : controlledIsOpen),
    [controlledIsOpen, isOpen]
  );

  const getSetIsOpen = useMemo(
    () => (typeof setIsOpen === 'function' ? setIsOpen : setControlledIsOpen),
    [setIsOpen]
  );

  const renderButtonCancel = useCallback(() => {
    if (buttonCancel === null) {
      return null;
    }

    return (
      <Grid.Item>
        <Button
          color="secondary"
          size="lg"
          onClick={() => getSetIsOpen(false)}
          {...(buttonCancel || {})}
        >
          {buttonCancel?.children || (
            <FormattedMessage {...texts.buttonCancel} />
          )}
        </Button>
      </Grid.Item>
    );
  }, [buttonCancel, getSetIsOpen]);

  return (
    <>
      {!!trigger && (
        <button
          type="button"
          onClick={() => getSetIsOpen(true)}
          className={classNames(styles.trigger, classNameTrigger)}
          data-cy={ActionModalSelectors.Trigger}
          disabled={triggerDisabled}
        >
          {trigger}
        </button>
      )}
      <Modal
        isOpen={getIsOpen}
        onClose={() => getSetIsOpen(false)}
        middleSize={size}
        middleTransparent={transparent}
        middleStripPadding={stripPadding}
      >
        <Gutter
          gutter={{ bottom: 4 }}
          as={as}
          {...props}
          className={classNames(props?.className, {
            [styles.errorBackground]: errorBackground,
          })}
        >
          <Gutter.Item>
            <Gutter gutter={{ bottom: 2 }}>
              {!!heading && (
                <Gutter.Item>
                  <h6>{heading}</h6>
                </Gutter.Item>
              )}
              {!!children && <Gutter.Item>{children}</Gutter.Item>}
              {!!error && (
                <Gutter.Item data-cy={ActionModalSelectors.Error}>
                  <div className={styles.error}>
                    <Information status="error">
                      {capitalizeFirstLetter(error.message)}
                    </Information>
                  </div>
                </Gutter.Item>
              )}
            </Gutter>
          </Gutter.Item>
          {(!!button || !!buttonCancel || !!buttonMiddle) && (
            <Gutter.Item>
              <Grid align="end" gutter={{ left: 2, bottom: 2 }}>
                {!!button && (
                  <Grid.Item>
                    <Button size="lg" {...button} />
                  </Grid.Item>
                )}
                {!!buttonMiddle && (
                  <Grid.Item>
                    <Button size="lg" {...buttonMiddle} />
                  </Grid.Item>
                )}
                {renderButtonCancel()}
              </Grid>
            </Gutter.Item>
          )}
        </Gutter>
      </Modal>
    </>
  );
};
