import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.userRole,
  ...shared.form,
  heading: {
    id: 'CreateUserModal.heading',
    description: 'Heading',
    defaultMessage: 'Ny användare',
  },
  button: {
    id: 'CreateUserModal.button',
    description: 'Button',
    defaultMessage: 'Skapa ny användare',
  },
  trigger: {
    id: 'CreateUserModal.trigger',
    description: 'Trigger',
    defaultMessage: 'Ny användare',
  },
  createUserSuccess: {
    id: 'CreateUserModal.createUserSuccess',
    description: 'Create user success',
    defaultMessage: 'Användaren är skapad',
  },
});
