import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import {
  addToast as addToastRedux,
  deleteToast as deleteToastRedux,
  deleteAllToasts as deleteAllToastsRedux,
  Toast,
} from 'redux/toast';

export const useToast = (): {
  addToast: (toast: {
    title?: Toast['title'];
    message?: Toast['message'];
    type?: Toast['type'];
  }) => void;
  deleteToast: (toast: { id: Toast['id'] }) => void;
  deleteAllToasts: () => void;
} => {
  const dispatch = useDispatch();

  const addToast = useCallback(
    (toast: {
      title?: Toast['title'];
      message?: Toast['message'];
      type?: Toast['type'];
    }) => dispatch(addToastRedux(toast)),
    [dispatch]
  );

  const deleteToast = useCallback(
    (toast: { id: Toast['id'] }) => dispatch(deleteToastRedux(toast)),
    [dispatch]
  );

  const deleteAllToasts = useCallback(
    () => dispatch(deleteAllToastsRedux()),
    [dispatch]
  );

  return { addToast, deleteToast, deleteAllToasts };
};
