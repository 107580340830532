import { useIntl } from 'react-intl';

import { UserRole } from 'api/graphql';
import { Select, SelectProps, Text, TextProps } from 'components/ui/forms';
import { Grid, Gutter } from 'components/ui/general';
import { UserFormSelectors } from 'consts/cypress';
import { useValidate } from 'hooks';

import { texts } from '../UserForm.text';

type RequiredProps = {
  register: TextProps['register'];
  control: SelectProps['control'];
  errors: {
    [key: string]: any;
  };
  defaultValues?: {
    [key: string]: any;
  };
  disabled?: boolean;
  password?: boolean;
};

export const Required = ({
  register,
  control,
  errors,
  defaultValues,
  disabled,
  password,
}: RequiredProps) => {
  const { formatMessage } = useIntl();
  const { isEmail } = useValidate();

  return (
    <Gutter gutter={{ bottom: 2 }}>
      <Gutter.Item>
        <Grid gutter={{ left: 1 }}>
          <Grid.Item width={6} data-cy={UserFormSelectors.FirstName}>
            <Text
              name="firstName"
              label={formatMessage(texts.form_labelFirstName)}
              register={register}
              validation={{ required: true }}
              error={errors?.firstName}
              fullWidth
              defaultValue={defaultValues?.firstName}
              disabled={disabled}
            />
          </Grid.Item>
          <Grid.Item width={6} data-cy={UserFormSelectors.LastName}>
            <Text
              name="lastName"
              label={formatMessage(texts.form_labelLastName)}
              register={register}
              validation={{ required: true }}
              error={errors?.lastName}
              fullWidth
              defaultValue={defaultValues?.lastName}
              disabled={disabled}
            />
          </Grid.Item>
        </Grid>
      </Gutter.Item>
      <Gutter.Item data-cy={UserFormSelectors.Role}>
        <Select
          name="role"
          label={formatMessage(texts.form_labelRole)}
          control={control}
          fullWidth
          validation={{ required: true }}
          error={errors?.role}
          options={Object.entries(UserRole).map(([, value]) => ({
            label: formatMessage(texts[`userRole_${value as UserRole}`]),
            value,
          }))}
          defaultValue={defaultValues?.role}
          disabled
        />
      </Gutter.Item>
      <Gutter.Item data-cy={UserFormSelectors.Email}>
        <Text
          name="email"
          type="email"
          label={formatMessage(texts.form_labelEmail)}
          register={register}
          validation={{ required: true, validate: isEmail }}
          error={errors?.email}
          fullWidth
          defaultValue={defaultValues?.email}
          disabled={disabled}
        />
      </Gutter.Item>
      {!!password && (
        <Gutter.Item data-cy={UserFormSelectors.Password}>
          <Text
            name="password"
            type="password"
            label={formatMessage(texts.form_labelPassword)}
            register={register}
            validation={{
              required: true,
              minLength: {
                value: 8,
                message: formatMessage(texts.passwordValidationLength),
              },
            }}
            error={errors?.password}
            fullWidth
            defaultValue={defaultValues?.password}
            disabled={disabled}
          />
        </Gutter.Item>
      )}
    </Gutter>
  );
};
