import { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  CustomNotificationsQuery,
  NotificationType,
  ChannelOrigin,
  NotificationReferenceType,
  Scalars,
  useWorkShiftSlotLazyQuery,
  UserRole,
} from 'api/graphql';
import { Button } from 'components/ui/general';
import { DateFormat } from 'consts/date';
import { Paths } from 'consts/router';
import { QueryParameters } from 'consts/table';
import { useAuth, useCreateChannel, useToast } from 'hooks';
import {
  setDateInMilliseconds,
  setWorkShiftIdAndWorkShiftSlotIdAndLocationId,
} from 'redux/bookings';
import { format } from 'utils';

import { texts } from '../NotificationsTable.text';

type RedirectProps = {
  notification: CustomNotificationsQuery['notifications']['edges'][0];
};

export const Redirect = ({ notification }: RedirectProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const { role } = useAuth();

  const { createChannel, loading: loadingChannel } = useCreateChannel();

  const [getWorkShiftSlot, { loading: loadingWorkShiftSlot }] =
    useWorkShiftSlotLazyQuery();

  const acceptedNotificationTypes = [
    NotificationType.NewCompany,
    NotificationType.NewResource,
    NotificationType.UserReportedSick,
    NotificationType.TimeReportSubmitted,
    NotificationType.AcceptedJobOffer,
    NotificationType.RequestForInterest,
    NotificationType.RfiAllDeclined,
    NotificationType.JobOfferAllDeclined,
    NotificationType.ResourceTimeChange,
    NotificationType.BookingStarted,
  ];

  const handleRedirectError = useCallback(() => {
    addToast({
      title: formatMessage(texts.redirectError),
      type: 'error',
    });
  }, [addToast, formatMessage]);

  const handleAllDeclined = useCallback(
    async (workShiftSlotId: Scalars['ID']) => {
      const { data, error } = await getWorkShiftSlot({
        variables: { id: workShiftSlotId },
      });

      if (error) {
        addToast({
          title: formatMessage(texts.getWorkShiftSlotError),
          message: error.message,
          type: 'error',
        });
        return;
      }

      if (data?.workShiftSlot) {
        const { startTime, workShift } = data.workShiftSlot;

        dispatch(
          setDateInMilliseconds(
            format.date({ date: startTime }).format(DateFormat.Milliseconds)
          )
        );
        dispatch(
          setWorkShiftIdAndWorkShiftSlotIdAndLocationId({
            workShiftId: workShift.id,
            workShiftSlotId,
            locationId: workShift.location.id,
          })
        );

        if (role === UserRole.Admin) {
          navigate(Paths.BookingsPreview(workShiftSlotId));
          return;
        }

        navigate(Paths.Bookings);
      }
    },
    [addToast, dispatch, formatMessage, getWorkShiftSlot, navigate, role]
  );

  const handleRedirect = useCallback(async () => {
    const { type, booking, referenceId, referenceType } = notification;

    switch (type) {
      case NotificationType.NewCompany: {
        if (
          referenceType === NotificationReferenceType.CompanyId &&
          referenceId
        ) {
          navigate(Paths.Company(referenceId));
          return;
        }

        handleRedirectError();
        break;
      }

      case NotificationType.NewResource: {
        if (referenceType === NotificationReferenceType.UserId && referenceId) {
          navigate(Paths.Resource(referenceId));
          return;
        }

        handleRedirectError();
        break;
      }

      case NotificationType.UserReportedSick: {
        if (booking) {
          await createChannel({
            origin: ChannelOrigin.OneToOne,
            userIds: [booking.createdBy.id],
          });
          return;
        }

        handleRedirectError();
        break;
      }

      case NotificationType.TimeReportSubmitted: {
        if (booking) {
          navigate({
            pathname: Paths.Attests,
            search: `${QueryParameters.SearchTerm}=${booking.id}`,
          });
          return;
        }

        handleRedirectError();
        break;
      }

      case NotificationType.RfiAllDeclined:
      case NotificationType.JobOfferAllDeclined: {
        if (
          referenceType === NotificationReferenceType.WorkShiftSlotId &&
          referenceId
        ) {
          await handleAllDeclined(referenceId);
          return;
        }

        handleRedirectError();
        break;
      }

      case NotificationType.AcceptedJobOffer:
      case NotificationType.RequestForInterest:
      case NotificationType.ResourceTimeChange:
      case NotificationType.BookingStarted: {
        if (booking) {
          const { startTime, workShiftId, workShiftSlot } = booking;

          dispatch(
            setDateInMilliseconds(
              format.date({ date: startTime }).format(DateFormat.Milliseconds)
            )
          );
          dispatch(
            setWorkShiftIdAndWorkShiftSlotIdAndLocationId({
              workShiftId,
              workShiftSlotId: workShiftSlot.id,
              locationId: workShiftSlot.workShift.location.id,
            })
          );

          if (role === UserRole.Admin) {
            navigate(Paths.BookingsPreview(workShiftSlot.id));
            return;
          }

          navigate(Paths.Bookings);
          return;
        }

        handleRedirectError();
        break;
      }

      default:
        break;
    }
  }, [
    createChannel,
    dispatch,
    handleAllDeclined,
    navigate,
    notification,
    role,
    handleRedirectError,
  ]);

  if (!acceptedNotificationTypes.includes(notification.type)) {
    return null;
  }

  return (
    <Button
      iconRight={{ name: 'angle-right' }}
      naked
      stripPadding
      size="sm"
      color="dark"
      loading={loadingChannel || loadingWorkShiftSlot}
      disabled={loadingChannel || loadingWorkShiftSlot}
      onClick={handleRedirect}
    />
  );
};
