import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Scalars, useCloseChatGroupMutation } from 'api/graphql';
import { Textarea } from 'components/ui/forms';
import { ActionModal, Button } from 'components/ui/general';
import { useToast } from 'hooks';

import { texts } from './CloseBroadcastModal.text';

type CloseBroadcastModalProps = {
  trigger?: ReactNode;
  chatGroupId: Scalars['ID'];
};

export const CloseBroadcastModal = ({
  trigger,
  chatGroupId,
}: CloseBroadcastModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const { formatMessage } = useIntl();
  const { addToast } = useToast();

  const [closeBroadcast, { loading, error }] = useCloseChatGroupMutation({
    onCompleted: (data) => {
      if (data.closeChatGroup?.id) {
        addToast({
          title: formatMessage(texts.closeBroadcastSuccess),
          type: 'success',
        });
      }
    },
  });

  const onSubmit = useCallback(
    ({ broadcastMessage }) => {
      closeBroadcast({
        variables: {
          id: chatGroupId,
          message: broadcastMessage,
        },
      });
    },
    [chatGroupId, closeBroadcast]
  );

  useEffect(() => {
    if (!isOpen) reset();
  }, [reset, isOpen]);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.buttonCloseBroadcast} />,
        type: 'submit',
        color: 'error',
        loading,
        disabled: loading,
      }}
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      trigger={
        trigger || (
          <Button color="secondary" as="div" size="sm">
            <FormattedMessage {...texts.buttonCloseTrigger} />
          </Button>
        )
      }
    >
      <Textarea
        name="broadcastMessage"
        label={<FormattedMessage {...texts.labelBroadcastMessage} />}
        register={register}
        fullWidth
        error={errors.broadcastMessage}
      />
    </ActionModal>
  );
};
