import { useState, ReactEventHandler, useMemo } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Spinner, SpinnerProps } from 'components/ui/general';
import { ImageSelectors } from 'consts/cypress';
import { Durations, Easings } from 'consts/transition';

import styles from './Image.module.scss';

type SharedImageProps = {
  src: string;
  alt: string;
  className?: string;
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  position?: string;
  placeholder?: 'dark' | 'mu'; // color names from $all-colors
  rootMargin?: string;
  spinner?: boolean;
  spinnerSize?: SpinnerProps['size'];
  spinnerColor?: SpinnerProps['color'];
  onLoad?: ReactEventHandler<HTMLImageElement>;
  onError?: ReactEventHandler<HTMLImageElement>;
};

export type ImageProps =
  | (SharedImageProps & {
      width: number;
      height: number;
      cover?: boolean;
    })
  | (SharedImageProps & {
      width?: number;
      height?: number;
      cover: boolean;
    });

export const Image = ({
  width,
  height,
  src,
  alt,
  className,
  cover,
  fit,
  position,
  placeholder,
  rootMargin = '150px 0px',
  spinner = true,
  spinnerSize,
  spinnerColor,
  onLoad,
  onError,
}: ImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin,
  });

  const getAspectRatio = useMemo(() => {
    if (!cover && height && width) {
      return { paddingBottom: `${(height / width) * 100}%` };
    }
  }, [cover, height, width]);

  const getPosition = useMemo(() => {
    if (position) {
      return { objectPosition: position };
    }
  }, [position]);

  return (
    <div
      ref={ref}
      className={classNames(styles.root, className, {
        [styles.loaded]: loaded,
        [styles.cover]: cover,
        [styles[`fit-${fit}`]]: !!fit,
        [styles[`placeholder-${placeholder}`]]: !!placeholder,
      })}
      style={getAspectRatio}
      data-cy={ImageSelectors.Root}
    >
      {spinner && (
        <AnimatePresence>
          {inView && !loaded && (
            <motion.div
              key="spinner"
              className={styles.spinner}
              transition={{ duration: Durations.Medium, ease: Easings.Ease }}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              data-cy={ImageSelectors.Spinner}
            >
              <Spinner visible size={spinnerSize} color={spinnerColor} />
            </motion.div>
          )}
        </AnimatePresence>
      )}
      {inView ? (
        <img
          src={src}
          alt={alt}
          className={styles.image}
          style={getPosition}
          onLoad={(event) => {
            setLoaded(true);
            onLoad?.(event);
          }}
          onError={(event) => onError?.(event)}
          data-cy={ImageSelectors.Image}
        />
      ) : null}
    </div>
  );
};
