/*
  This hook is fetching everything by comparing edges.length and meta.total.
  It will fill the cache with the responding data, and to prevent the cache
  to be applied in more places than it should we need something unique. The
  unique key that every cache will have is "limit: 23". This is very unsafe,
  but for now it will work. It will be a problem if another query uses the
  same limit because it will then render everything it finds in cache. So
  "limit: 23" should be pretty safe because it's a weird limit to set.

  const [fetch, { data, loading, error, ...rest }] = useLazyQueryInterval<
    ExamplesQuery,
    ExamplesQueryVariables
  >({
    document: ExamplesDocument, // Document
    name: 'examples', // Key name of the document query
    options: {} // LazyQueryHookOptions without some keys
  });
  
  useEffect(() => {
    fetch({
      variables: {
        filter: {
          searchTerm: 'example'
        }
      }
    });
  }, [fetch]);
*/

import { useCallback, useMemo } from 'react';

import {
  LazyQueryHookOptions,
  LazyQueryResult,
  useLazyQuery,
} from '@apollo/client';
import { DocumentNode, OperationVariables } from '@apollo/client/core';

export const useLazyQueryInterval = <
  Query = any,
  QueryVariables extends OperationVariables = OperationVariables,
>({
  document,
  name,
  options,
}: {
  document: DocumentNode;
  name: string;
  options?: Omit<
    LazyQueryHookOptions<Query, QueryVariables>,
    'variables' | 'fetchPolicy' | 'notifyOnNetworkStatusChange'
  >;
}): [
  (
    options?: LazyQueryHookOptions<Query, QueryVariables>
  ) => Promise<LazyQueryResult<Query, QueryVariables>>,
  LazyQueryResult<Query, QueryVariables> & { progress: number },
] => {
  // This is the unique cache key that's used as limit in the queries.
  // Is it unsafe? Yes.
  const limitAndUnsafeCacheKey = 23;

  const [fetch, query] = useLazyQuery<Query, QueryVariables>(document, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    ...options,
    onCompleted: async (data) => {
      const { edges, meta } = (data as Record<string, any>)?.[name] || {};
      options?.onCompleted?.(data);

      // Something went wrong, don't continue
      if (!Array.isArray(edges) || !meta) {
        return;
      }

      // Everything is already fetched, don't continue
      if (edges.length >= meta.total) {
        return;
      }

      // Fetch more
      await query.fetchMore({
        variables: {
          ...query.variables,
          filter: {
            ...((query.variables as Record<string, any>)?.filter || {}),
            limit: meta?.limit,
            offset: edges?.length,
          },
        },
        updateQuery(previousQueryResult, { fetchMoreResult }) {
          const getPreviousQueryResult = previousQueryResult as Record<
            string,
            any
          >;
          const getFetchMoreResult = fetchMoreResult as Record<string, any>;

          if (!getFetchMoreResult?.[name]) {
            return previousQueryResult;
          }

          return {
            [name]: {
              data: getFetchMoreResult[name].data,
              edges: [
                ...getPreviousQueryResult[name].edges,
                ...getFetchMoreResult[name].edges,
              ],
              meta: getFetchMoreResult[name].meta,
              __typename: getPreviousQueryResult[name].__typename,
            },
          } as unknown as Query;
        },
      });
    },
  });

  const lazyFetch = useCallback(
    async (
      lazyFetchOptions: LazyQueryHookOptions<Query, QueryVariables> = {}
    ) => {
      const variables = {
        ...(lazyFetchOptions.variables || {}),
        filter: {
          ...((lazyFetchOptions.variables as Record<string, any>)?.filter ||
            {}),
          limit: limitAndUnsafeCacheKey,
        },
      } as unknown as QueryVariables;

      const result = await fetch({
        ...lazyFetchOptions,
        variables,
      });

      return result;
    },
    [fetch]
  );

  const getResult = useMemo(() => {
    const { edges, meta } = (query.data as Record<string, any>)?.[name] || {};

    // Not called yet
    if (!Array.isArray(edges) || typeof meta?.total !== 'number') {
      return {
        ...query,
        progress: 0,
      };
    }

    // Response is empty
    if (edges.length === 0 && meta.total === 0) {
      return {
        ...query,
        progress: 100,
      };
    }

    // Calculate progress and set the correct loading state
    const progress = Math.round((100 * edges.length) / meta.total);

    return {
      ...query,
      loading: progress < 100,
      progress,
    };
  }, [query, name]);

  return [lazyFetch, getResult];
};
