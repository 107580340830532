import union from 'lodash/union';
import { Moment } from 'moment';

import { WorkShiftSlot } from 'api/graphql';
import { DateFormat } from 'consts/date';
import { format } from 'utils';

/**
 * Data format: `${date}__${name}__${uuidv4()}?__${workShiftId}`: `${value}`
 */
const collectSlots = (data: {
  [key: string]: any;
}): {
  endTime: Moment;
  startTime: Moment;
  resourceQuantity: number;
  id?: string | number;
}[] => {
  let uniqueIdentifiers: string[] = [];
  let workShiftSlots: {
    [key: string]: any;
  } = {};
  const dataKeys = Object.keys(data);
  const allowedNames = ['endTime', 'startTime', 'resourceQuantity'];

  dataKeys.forEach((key) => {
    const [, name, id] = key.split('__');

    if (allowedNames.includes(name)) {
      uniqueIdentifiers = union([...uniqueIdentifiers, id]);
    }
  });

  uniqueIdentifiers.forEach((uniqueIdentifier) => {
    dataKeys.forEach((key) => {
      const [date, name, uuidv4, workShiftId] = key.split('__');
      const value = data[key];

      if (uniqueIdentifier === uuidv4 && allowedNames.includes(name)) {
        workShiftSlots = {
          ...workShiftSlots,
          [uniqueIdentifier]: {
            ...workShiftSlots[uniqueIdentifier],
            ...(workShiftId ? { id: workShiftId } : {}),
            [name]:
              name === 'resourceQuantity'
                ? Number(value)
                : format
                    .date({ date: `${date} ${value}` })
                    .format(DateFormat.Full),
          },
        };
      }
    });
  });

  return Object.keys(workShiftSlots).map((key) => {
    const workShiftSlot = workShiftSlots[key];
    const endTimeShouldBeTomorrow = format
      .date({ date: workShiftSlot.endTime })
      .isSameOrBefore(format.date({ date: workShiftSlot.startTime }));

    if (endTimeShouldBeTomorrow) {
      workShiftSlot.endTime = format
        .date({ date: workShiftSlot.endTime })
        .add(1, 'days')
        .format(DateFormat.Full);
    }

    return workShiftSlot;
  });
};

/**
 * Format slots so the ones with the same "start time day" will be in the same
 * array
 */
export type FormatSlots = {
  date: Moment;
  workShiftSlots?: {
    endTime: string;
    startTime: string;
    resourceQuantity: number;
    id: string | number;
    fullyBooked: boolean;
  }[];
}[];

const formatSlots = (slots: WorkShiftSlot[]) => {
  let dates: FormatSlots = [];

  slots.forEach(({ startTime, endTime, resourceQuantity, id, fullyBooked }) => {
    const getDate = dates.find(({ date }) =>
      format.date({ date }).isSame(format.date({ date: startTime }), 'day')
    );
    const workShiftSlot = {
      startTime: format
        .date({ date: startTime })
        .format(DateFormat.HourMinutes),
      endTime: format.date({ date: endTime }).format(DateFormat.HourMinutes),
      resourceQuantity,
      id,
      fullyBooked,
    };

    if (getDate) {
      dates = [
        ...dates.filter(
          ({ date }) =>
            !format
              .date({ date })
              .isSame(format.date({ date: startTime }), 'day')
        ),
        {
          ...getDate,
          workShiftSlots: [...(getDate?.workShiftSlots || []), workShiftSlot],
        },
      ];
      return;
    }

    dates = [
      ...dates,
      {
        date: format.date({ date: startTime }),
        workShiftSlots: [workShiftSlot],
      },
    ];
  });

  return dates;
};

export const workShift = {
  collectSlots,
  formatSlots,
};
