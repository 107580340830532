import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Container.module.scss';

export type ContainerProps = {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  horizontal?: boolean;
  vertical?: boolean;
};

export const Container = ({
  children,
  fullWidth = false,
  className,
  horizontal = true,
  vertical = false,
  ...rest
}: ContainerProps) => {
  return (
    <div
      className={classNames(styles.root, className, {
        [styles.fullWidth]: fullWidth,
        [styles.horizontal]: horizontal,
        [styles.vertical]: vertical,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
