import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { BackOfficeMainBookingStatus, WorkShiftSlotQuery } from 'api/graphql';
import { useTable } from 'hooks';
import {
  BookingsRootState,
  setWorkShiftIdAndWorkShiftSlotIdAndLocationId,
} from 'redux/bookings';
import { removeDuplicates } from 'utils';

export const useBookings = () => {
  const dispatch = useDispatch();
  const { setAllQueryParameters, clearAllQueryParameters } = useTable();

  const selectAllWorkShiftSlots = useCallback(() => {
    clearAllQueryParameters();
    setAllQueryParameters((previouseQueryParameters) => ({
      ...previouseQueryParameters,
      filter: [
        `backOfficeMainStatus__${BackOfficeMainBookingStatus.Started}`,
        `backOfficeMainStatus__${BackOfficeMainBookingStatus.Accepted}`,
        `backOfficeMainStatus__${BackOfficeMainBookingStatus.Completed}`,
      ],
    }));
    dispatch(setWorkShiftIdAndWorkShiftSlotIdAndLocationId(null));
  }, [dispatch, setAllQueryParameters, clearAllQueryParameters]);

  const selectWorkShiftSlot = useCallback(
    ({
      workShiftId,
      workShiftSlotId,
      locationId,
    }: NonNullable<
      BookingsRootState['bookings']['workShiftIdAndWorkShiftSlotIdAndLocationId']
    >) => {
      clearAllQueryParameters();
      dispatch(
        setWorkShiftIdAndWorkShiftSlotIdAndLocationId({
          workShiftId,
          workShiftSlotId,
          locationId,
        })
      );
    },
    [clearAllQueryParameters, dispatch]
  );

  const setDefaultFilter = useCallback(
    (workShiftSlot: WorkShiftSlotQuery['workShiftSlot']) => {
      const defaultFilter = workShiftSlot?.defaultFilter;
      if (!defaultFilter?.length) return;

      const filter = removeDuplicates(defaultFilter).map(
        (status) => `backOfficeMainStatus__${status}`
      );

      setAllQueryParameters((previouseQueryParameters) => ({
        ...previouseQueryParameters,
        filter,
      }));
    },
    [setAllQueryParameters]
  );

  return {
    selectAllWorkShiftSlots,
    selectWorkShiftSlot,
    setDefaultFilter,
  };
};
