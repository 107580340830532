import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  buttonTextPrev: {
    id: 'Calendar.buttonTextPrev',
    description: 'Button text prev',
    defaultMessage: 'Föregående',
  },
  buttonTextNext: {
    id: 'Calendar.buttonTextNext',
    description: 'Button text next',
    defaultMessage: 'Nästa',
  },
});
