import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  lastMessageFiles: {
    id: 'Channel.lastMessageFiles',
    description: 'Last message files',
    defaultMessage: 'Har skickat fil(er)',
  },
  lastMessageImages: {
    id: 'Channel.lastMessageImages',
    description: 'Last message images',
    defaultMessage: 'Har skickat bild(er)',
  },
});
