import { FocusEventHandler, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import {
  Controller,
  RegisterOptions,
  Control as ControlType,
  FieldErrors,
} from 'react-hook-form';
import ReactSelect, {
  OptionsOrGroups,
  GroupBase,
  ActionMeta,
} from 'react-select';

import { Error } from 'components/ui/forms';
import { Information } from 'components/ui/general';
import { SelectSelectors } from 'consts/cypress';
import { Names } from 'types/icon';

import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  LoadingIndicator,
  LoadingMessage,
  MultiValue,
  MultiValueRemove,
  NoOptionsMessage,
  Option,
} from './subcomponents';

import styles from './Select.module.scss';

export type SelectProps<Option = unknown> = {
  control: ControlType<any, any>;
  name: string;
  className?: string;
  options: OptionsOrGroups<any, GroupBase<any>>;
  validation?: RegisterOptions;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  ariaLabel?: string;
  isSearchable?: boolean;
  color?: 'light'; // color names from $all-colors
  size?: 'sm' | 'md' | 'lg';
  error?: FieldErrors;
  onChange?: (
    option: Option | readonly Option[] | null,
    actionMeta: ActionMeta<Option>
  ) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  isMulti?: boolean;
  label?: ReactNode;
  isClearable?: boolean;
  iconMultiValueRemove?: Names;
  iconClearIndicator?: Names;
  iconDropdownIndicator?: Names;
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  multiLabelWithNumber?: string;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  success?: boolean;
  information?: ReactNode;
  defaultValue?: {
    [key: string]: any;
  };
};

export const Select = ({
  control,
  name,
  className,
  options,
  validation = {},
  disabled,
  loading,
  fullWidth,
  placeholder,
  ariaLabel,
  isSearchable = false,
  color = 'light',
  size = 'md',
  error,
  onChange,
  onBlur,
  isMulti,
  label,
  isClearable,
  iconMultiValueRemove = 'times',
  iconClearIndicator = 'times',
  iconDropdownIndicator = 'angle-down',
  noOptionsMessage,
  multiLabelWithNumber,
  closeMenuOnSelect,
  hideSelectedOptions,
  success,
  information,
  defaultValue,
}: SelectProps) => {
  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  return (
    <>
      <div
        className={classNames(styles.root, className, {
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled || loading,
          [styles.isSearchable]: isSearchable,
          [styles.error]: !!error,
          [styles.success]: success,
          [styles[`${color}Color`]]: color,
          [styles[`${size}Size`]]: size,
        })}
        data-cy={SelectSelectors.Root}
      >
        <Controller
          rules={getValidation}
          defaultValue={defaultValue}
          name={name}
          control={control}
          render={({
            field: {
              name: innerName,
              ref: innerRef,
              value: innerValue,
              onChange: innerOnChange,
              onBlur: innerOnBlur,
            },
          }) => (
            <ReactSelect
              {...{
                label,
                validation: getValidation,
                iconMultiValueRemove,
                iconClearIndicator,
                iconDropdownIndicator,
                multiLabelWithNumber,
              }}
              isMulti={isMulti}
              isClearable={isClearable}
              isSearchable={isSearchable}
              name={innerName}
              ref={innerRef}
              value={innerValue}
              closeMenuOnSelect={closeMenuOnSelect || !isMulti}
              hideSelectedOptions={hideSelectedOptions}
              classNamePrefix="react-select"
              options={loading ? [] : options || []}
              placeholder={placeholder || '\u00a0'}
              isDisabled={disabled || loading}
              isLoading={loading}
              aria-label={ariaLabel || placeholder}
              noOptionsMessage={noOptionsMessage}
              components={{
                Control,
                LoadingIndicator,
                LoadingMessage,
                NoOptionsMessage,
                DropdownIndicator,
                MultiValueRemove,
                ClearIndicator,
                Option,
                MultiValue,
              }}
              onChange={(newValue, actionMeta) => {
                innerOnChange(newValue, actionMeta);
                onChange?.(newValue, actionMeta);
              }}
              onBlur={(event) => {
                innerOnBlur();
                onBlur?.(event);
              }}
            />
          )}
        />
      </div>
      {!!information && (
        <Information className={styles.informationText}>
          {information}
        </Information>
      )}
      <Error error={error} size={size === 'sm' ? 'sm' : 'md'} />
    </>
  );
};
