import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import {
  CustomResourcesDocument,
  UserRole,
  UsersDocument,
  useSetLocationFavouriteMutation,
} from 'api/graphql';
import { useAuth, useToast } from 'hooks';

import { texts } from './ToggleLocationFavourite.text';

import styles from './ToggleLocationFavourite.module.scss';

type ToggleLocationFavouriteProps = {
  locationId: string | number;
  userId: string | number;
  defaultFavourite: boolean;
  className?: string;
  children: (props: { isFavourited: boolean; isLoading: boolean }) => ReactNode;
};

export const ToggleLocationFavourite = ({
  locationId,
  userId,
  defaultFavourite,
  className,
  children,
}: ToggleLocationFavouriteProps) => {
  const { role } = useAuth();
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const [setLocationFavourite, { loading, error }] =
    useSetLocationFavouriteMutation({
      refetchQueries: [UsersDocument, CustomResourcesDocument],
    });
  const [favourite, setFavourite] = useState<boolean>(defaultFavourite);
  const props = useMemo(
    () => ({ isFavourited: favourite, isLoading: loading }),
    [favourite, loading]
  );

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.setLocationFavouriteError),
        message: error.message,
        type: 'error',
      });
    }
  }, [addToast, error, formatMessage]);

  const handleSetLocationFavourite = useCallback(async () => {
    const { data } = await setLocationFavourite({
      variables: {
        id: locationId,
        userId,
        favourite: !favourite,
      },
    });

    if (data?.setLocationFavourite) {
      setFavourite(data.setLocationFavourite.favourite);
      addToast({
        title: formatMessage(texts.setLocationFavouriteSuccess),
        type: 'success',
      });
    }
  }, [
    addToast,
    formatMessage,
    locationId,
    setLocationFavourite,
    userId,
    favourite,
  ]);

  if (role === UserRole.Admin) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={handleSetLocationFavourite}
      className={classNames(styles.button, className)}
    >
      {children(props)}
    </button>
  );
};
