import { lazy } from 'react';

export const routes = {
  Landing: lazy(() => import('./Landing')),
  Login: lazy(() => import('./Login')),
  Companies: lazy(() => import('./Companies')),
  Company: lazy(() => import('./Company')),
  CompanyContact: lazy(() => import('./CompanyContact')),
  CompanyLocation: lazy(() => import('./CompanyLocation')),
  ForgotPassword: lazy(() => import('./ForgotPassword')),
  ResetPassword: lazy(() => import('./ResetPassword')),
  NotFound: lazy(() => import('./NotFound')),
  Settings: lazy(() => import('./Settings')),
  ResourceCategories: lazy(() => import('./ResourceCategories')),
  ResourceCategory: lazy(() => import('./ResourceCategory')),
  Resources: lazy(() => import('./Resources')),
  Resource: lazy(() => import('./Resource')),
  Users: lazy(() => import('./Users')),
  User: lazy(() => import('./User')),
  Booking: lazy(() => import('./Booking')),
  Templates: lazy(() => import('./Templates')),
  Bookings: lazy(() => import('./Bookings')),
  BookingsPreview: lazy(() => import('./BookingsPreview')),
  Chat: lazy(() => import('./Chat')),
  ChatGroups: lazy(() => import('./ChatGroups')),
  ChatBroadcasts: lazy(() => import('./ChatBroadcasts')),
  Assignments: lazy(() => import('./Assignments')),
  Notifications: lazy(() => import('./Notifications')),
  LegacyImport: lazy(() => import('./LegacyImport')),
  Attests: lazy(() => import('./Attests')),
};
