import { combineReducers } from '@reduxjs/toolkit';

import auth from 'redux/auth';
import bookings from 'redux/bookings';
import gdpr from 'redux/gdpr';
import notification from 'redux/notification';
import router from 'redux/router';
import toast from 'redux/toast';
import workShiftResources from 'redux/workShiftResources';

export const reducers = combineReducers({
  router,
  auth,
  toast,
  gdpr,
  workShiftResources,
  bookings,
  notification,
});

export type RootState = ReturnType<typeof reducers>;
