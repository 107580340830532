import { useEffect } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { CustomMessagesQuery } from 'api/graphql';
import { Icon, Spinner } from 'components/ui/general';
import { useDownloadFile, useToast } from 'hooks';

import { texts } from '../Message.text';

import styles from '../Message.module.scss';

type MessageFileProps = {
  file: CustomMessagesQuery['messages']['edges'][0]['files'][0];
  createdByMe: boolean;
};

export const MessageFile = ({ file, createdByMe }: MessageFileProps) => {
  const { originalName, uri } = file;
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const { download, error, loading } = useDownloadFile();

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.errorDownload),
        type: 'error',
      });
    }
  }, [error, addToast, formatMessage]);

  return (
    <div
      className={classNames(styles.file, {
        [styles.fileLoading]: loading,
      })}
    >
      <button
        type="button"
        className={styles.fileInner}
        onClick={() => download(uri)}
      >
        <div className={styles.iconFile}>
          <Icon name="file-alt" />
        </div>
        <div>{originalName}</div>
        <div className={styles.iconAndSpinner}>
          <Icon name="arrow-down-to-line" className={styles.iconDownload} />
          <Spinner
            visible={loading}
            size="sm"
            className={styles.fileSpinner}
            color={createdByMe ? 'light' : 'dark'}
          />
        </div>
      </button>
    </div>
  );
};
