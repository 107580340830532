import { Suspense, useMemo } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { UserRole } from 'api/graphql';
import { ScrollToTop, RouterListener, PrivateRoute } from 'components/tools';
import { Layout } from 'components/ui/general';
import { ToastManager, NotificationManager } from 'components/ui/modals';
import { Loading, Error } from 'components/ui/router';
import { Paths } from 'consts/router';
import { routes } from 'routes';
import { sentry } from 'utils';

export const Router = () => {
  const allRoutes = useMemo(
    () => [
      {
        path: Paths.Login,
        element: <routes.Login />,
        isPrivate: false,
      },
      {
        path: Paths.ForgotPassword,
        element: <routes.ForgotPassword />,
        isPrivate: false,
      },
      {
        path: Paths.ResetPassword(),
        element: <routes.ResetPassword />,
        isPrivate: false,
      },
      {
        path: Paths.Landing,
        element: <routes.Landing />,
      },
      {
        path: Paths.Companies,
        element: <routes.Companies />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Company(),
        element: <routes.Company />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.CompanyContact(),
        element: <routes.CompanyContact />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.CompanyLocation(),
        element: <routes.CompanyLocation />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.Settings,
        element: <routes.Settings />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.ResourceCategories,
        element: <routes.ResourceCategories />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.ResourceCategory(),
        element: <routes.ResourceCategory />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Resources,
        element: <routes.Resources />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.Resource(),
        element: <routes.Resource />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.Users,
        element: <routes.Users />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.User(),
        element: <routes.User />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Booking,
        element: <routes.Booking />,
        allowedRoles: [UserRole.CompanyAdmin, UserRole.CompanyUser],
      },
      {
        path: Paths.Templates,
        element: <routes.Templates />,
        allowedRoles: [UserRole.CompanyAdmin, UserRole.CompanyUser],
      },
      {
        path: Paths.Bookings,
        element: <routes.Bookings />,
        allowedRoles: [UserRole.CompanyAdmin, UserRole.CompanyUser],
      },
      {
        path: Paths.BookingsPreview(),
        element: <routes.BookingsPreview />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Chat,
        element: <routes.Chat />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.ChatGroups,
        element: <routes.ChatGroups />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.ChatBroadcasts,
        element: <routes.ChatBroadcasts />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Assignments,
        element: <routes.Assignments />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Notifications,
        element: <routes.Notifications />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CompanyAdmin,
          UserRole.CompanyUser,
        ],
      },
      {
        path: Paths.LegacyImport,
        element: <routes.LegacyImport />,
        allowedRoles: [UserRole.Admin],
      },
      {
        path: Paths.Attests,
        element: <routes.Attests />,
        allowedRoles: [UserRole.CompanyAdmin, UserRole.CompanyUser],
      },
    ],
    []
  );

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <RouterListener />
        <ScrollToTop />
        <Layout>
          <sentry.ErrorBoundary fallback={<Error />}>
            <Suspense fallback={<Loading />}>
              <Routes>
                {allRoutes.map(
                  ({ path, element, isPrivate = true, allowedRoles }) => {
                    if (isPrivate) {
                      return (
                        <Route
                          key={path}
                          path={path}
                          element={<PrivateRoute allowedRoles={allowedRoles} />}
                        >
                          <Route path={path} element={element} />
                        </Route>
                      );
                    }
                    return <Route key={path} path={path} element={element} />;
                  }
                )}
                <Route path="*" element={<routes.NotFound />} />
              </Routes>
            </Suspense>
          </sentry.ErrorBoundary>
        </Layout>
        <ToastManager />
        <NotificationManager />
      </QueryParamProvider>
    </BrowserRouter>
  );
};
