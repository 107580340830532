import { MouseEventHandler, ReactNode } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Grid, Icon } from 'components/ui/general';
import { TableSelectors } from 'consts/cypress';

import { texts } from './Pagination.text';

import styles from './Pagination.module.scss';

type PaginationProps = {
  classNamePagination?: string;
  gotoPage?: (pageIndex: number) => void;
  canPreviousPage: boolean;
  previousPage: MouseEventHandler;
  nextPage: MouseEventHandler;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
  pageOptions: number[];
  paginationBeside?: ReactNode;
  isInModalMiddle?: boolean;
};

export const Pagination = ({
  classNamePagination,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  paginationBeside,
  isInModalMiddle,
}: PaginationProps) => {
  return (
    <div
      className={classNames(styles.root, classNamePagination, {
        [styles.isInModalMiddle]: isInModalMiddle,
      })}
      data-cy={TableSelectors.Pagination}
    >
      <Grid gutter={{ left: 3 }}>
        <Grid.Item>
          <div className={styles.paginationBeside}>{paginationBeside}</div>
        </Grid.Item>
        <Grid.Item>
          <div className={styles.navigation}>
            <button
              type="button"
              onClick={() => gotoPage?.(0)}
              disabled={!canPreviousPage}
              className={styles.navigationButton}
            >
              <Icon name="angle-double-left" font="kyoto" />
            </button>
            <button
              type="button"
              onClick={previousPage}
              disabled={!canPreviousPage}
              className={styles.navigationButton}
            >
              <Icon name="angle-left" font="kyoto" />
            </button>
            <div className={styles.navigationText}>
              <FormattedMessage {...texts.page} /> {pageIndex + 1}{' '}
              <FormattedMessage {...texts.of} /> {pageOptions.length}
            </div>
            <button
              type="button"
              onClick={nextPage}
              disabled={!canNextPage}
              className={styles.navigationButton}
            >
              <Icon name="angle-right" font="kyoto" />
            </button>
            <button
              type="button"
              onClick={() => gotoPage?.(pageCount - 1)}
              disabled={!canNextPage}
              className={styles.navigationButton}
            >
              <Icon name="angle-double-right" font="kyoto" />
            </button>
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
};
