export enum QueryParameters {
  ActivePaneId = 'activePaneId',
}

export enum CompanyPanes {
  Information = 'companyInformation',
  Contacts = 'companyContacts',
  Locations = 'companyLocations',
}

export enum CompanyContactPanes {
  Information = 'companyContactInformation',
  Locations = 'companyContactLocations',
}

export enum CompanyLocationPanes {
  Information = 'companyLocationInformation',
  Contacts = 'companyLocationContacts',
}

export enum SettingsPanes {
  Account = 'settingsAccount',
}

export enum ResourcePanes {
  Profile = 'resourceProfile',
  Files = 'resourceFiles',
  WorkReferences = 'resourceWorkReferences',
}

export enum ResourceCategoryPanes {
  Resources = 'resourceCategoryResources',
  Regions = 'resourceCategoryRegions',
}

export enum UsersPanes {
  Feed = 'usersFeed',
}

export enum UserPanes {
  Account = 'userAccount',
}
