import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  title: {
    id: 'Error.title',
    description: 'Error title when router fails',
    defaultMessage: 'Något gick fel, prova igen senare',
  },
  button: {
    id: 'Error.button',
    description: 'Error button when router fails',
    defaultMessage: 'Ladda om sidan',
  },
});
