import { ReactNode, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UnreadMessagesListener } from 'components/ui/chat';
import { Header } from 'components/ui/general';
import { NotificationsListener } from 'components/ui/notification';
import { Consents } from 'consts/gdpr';
import { Paths } from 'consts/router';
import { useTabAccess, useAuth, useDesktopNotification } from 'hooks';
import { selectConsent } from 'redux/gdpr';
import { sentry } from 'utils';

import styles from './Layout.module.scss';

type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const { user, loggedIn } = useAuth();
  const consent = useSelector(selectConsent);
  const { pathname } = useLocation();
  const { requestPermission } = useDesktopNotification();

  const shouldBeResponsive = useMemo(
    () =>
      pathname.includes(Paths.ForgotPassword) ||
      pathname.includes(`/${Paths.ResetPassword().split('/')[1]}`),
    [pathname]
  );

  useTabAccess();

  useEffect(() => {
    if (consent.includes(Consents.All)) {
      sentry.setUser(user ? { email: user.email } : null);
    }
  }, [user, consent]);

  useEffect(() => {
    if (loggedIn) {
      requestPermission();
    }
  }, [loggedIn, requestPermission]);

  return (
    <>
      <div
        className={classNames(styles.root, {
          [styles.shouldBeResponsive]: shouldBeResponsive,
        })}
      >
        <Header />
        <div className={styles.content}>{children}</div>
      </div>
      {!!loggedIn && (
        <>
          <NotificationsListener />
          <UnreadMessagesListener />
        </>
      )}
    </>
  );
};
