import {
  Channel,
  CompanyProfile,
  Message,
  RestaurantProfile,
} from 'api/graphql';

const companyProfile = (item: any): CompanyProfile | undefined =>
  item?.__typename === 'CompanyProfile' ? item : undefined;

const restaurantProfile = (item: any): RestaurantProfile | undefined =>
  item?.__typename === 'RestaurantProfile' ? item : undefined;

const message = (item: any): Message | undefined =>
  item?.__typename === 'Message' ? item : undefined;

const channel = (item: any): Channel | undefined =>
  item?.__typename === 'Channel' ? item : undefined;

export const typeGuards = {
  companyProfile,
  restaurantProfile,
  message,
  channel,
};
