export enum Emails {
  Admin = 'cypress.admin@interactivesolutions.se',
  CompanyAdmin = 'cypress.company_admin@interactivesolutions.se',
  CompanyUser = 'cypress.company_user@interactivesolutions.se',
  User = 'cypress.user@interactivesolutions.se',
}

export enum Passwords {
  Admin = 'helloWorld',
  CompanyAdmin = 'helloWorld',
  CompanyUser = 'helloWorld',
  User = 'helloWorld',
}

/**
 * When selecting elements for Cypress you should use data-cy="". When defining
 * and selecting elements you should use this constants file. Try to follow this
 * naming convention:
 *
 * export enum <ComponentName>Selectors {
 *   Heading = '<component-name>-heading',
 *   Paragraph = '<component-name>-paragraph'
 * }
 *
 * So lets say you have a Button component where you want to select the content
 * and the spinner, that would result in:
 *
 * export enum ButtonSelectors {
 *   Content = 'button-content',
 *   Spinner = 'button-spinner'
 * }
 */

export enum LoginSelectors {
  Email = 'login-email',
  Password = 'login-password',
  Form = 'login-form',
  ForgotPassword = 'login-forgot-password',
}

export enum ForgotPasswordSelectors {
  Email = 'forgot-password-email',
  Form = 'forgot-password-form',
  SuccessResponse = 'forgot-password-success-response',
  ToLogin = 'forgot-password-to-login',
}

export enum ResetPasswordSelectors {
  NewPassword = 'reset-password-new-password',
  NewPasswordConfirm = 'reset-password-new-password-confirm',
  Form = 'reset-password-form',
  ErrorResponse = 'reset-password-error-response',
}

export enum TableSelectors {
  Root = 'table-root',
  Search = 'table-search',
  Pagination = 'table-pagination',
  Head = 'table-head',
  HeadTr = 'table-head-tr',
  HeadTh = 'table-head-th',
  Body = 'table-body',
  BodyTr = 'table-body-tr',
  BodyTd = 'table-body-td',
}

export enum CheckboxSelectors {
  Root = 'checkbox-root',
}

export enum ErrorSelectors {
  Root = 'error-root',
}

export enum FileSelectors {
  Root = 'file-root',
  Input = 'file-input',
}

export enum RadioSelectors {
  Root = 'radio-root',
}

export enum SelectSelectors {
  Root = 'select-root',
  LoadingIndicator = 'select-loading-indicator',
}

export enum SwitchSelectors {
  Root = 'switch-root',
  Input = 'switch-input',
  Controlled = 'switch-controlled',
  ControlledInput = 'switch-controlled-input',
}

export enum TextSelectors {
  Root = 'text-root',
  Input = 'text-input',
}

export enum TextareaSelectors {
  Root = 'textarea-root',
  Textarea = 'textarea-textarea',
}

export enum GdprConsentSelectors {
  Root = 'gdpr-consent-root',
}

export enum ModalSelectors {
  Root = 'modal-root',
}

export enum ToastManagerSelectors {
  Toasts = 'toast-manager-toasts',
}

export enum NotificationManagerSelectors {
  Notifications = 'notification-manager-notifications',
}

export enum AccordionSelectors {
  Root = 'accordion-root',
  Content = 'accordion-content',
  Item = 'accordion-item',
  Label = 'accordion-label',
}

export enum TooltipSelectors {
  Trigger = 'tooltip-trigger',
  Tooltip = 'tooltip-tooltip',
}

export enum TabsSelectors {
  Root = 'tabs-root',
  Label = 'tabs-label',
  Content = 'tabs-content',
  Beside = 'tabs-beside',
}

export enum MenuSelectors {
  Root = 'menu-root',
  Trigger = 'menu-trigger',
  Menu = 'menu-menu',
  Item = 'menu-item',
}

export enum CompaniesTableSelectors {
  Pick = 'companies-table-pick',
}

export enum ResourcesTableSelectors {
  Pick = 'resources-table-pick',
}

export enum ContentWithTabsSelectors {
  Heading = 'content-with-tabs-heading',
  Tag = 'content-with-tabs-tag',
  Avatar = 'content-with-tabs-avatar',
}

export enum DeleteCompanyModalSelectors {
  Trigger = 'delete-company-modal-trigger',
  Button = 'delete-company-modal-button',
}

export enum CompanyFormSelectors {
  Category = 'company-form-category',
  Name = 'company-form-name',
  Street = 'company-form-street',
  PostalCode = 'company-form-postal-code',
  City = 'company-form-city',
  Latitude = 'company-form-latitude',
  Longitude = 'company-form-longitude',
  OrganisationNumber = 'company-form-organisation-number',
  ContactUserFirstName = 'company-form-contact-user-first-name',
  ContactUserLastName = 'company-form-contact-user-last-name',
  ContactUserEmail = 'company-form-contact-user-email',
  ContactUserMobileNumber = 'company-form-contact-user-mobile-number',
  BillingStreet = 'company-form-billing-street',
  BillingPostalCode = 'company-form-billing-postal-code',
  BillingCity = 'company-form-billing-city',
  BillingReference = 'company-form-billing-reference',
  LocationName = 'company-form-location-name',
  LocationStreet = 'company-form-location-street',
  LocationPostalCode = 'company-form-location-postal-code',
  LocationCity = 'company-form-location-city',
  LocationOrganisationNumber = 'company-form-location-organisation-bumber',
  LocationBillingStreet = 'company-form-location-billingStreet',
  LocationBillingPostalCode = 'company-form-location-billing-postal-code',
  LocationBillingCity = 'company-form-location-billing-city',
  LocationBillingReference = 'company-form-location-billing-reference',
  LocationRegion = 'company-form-location-region',
  LocationLatitude = 'company-form-location-latitude',
  LocationLongitude = 'company-form-location-longitude',
  EInvoice = 'company-form-location-e-invoice',
}

export enum CreateCompanyModalSelectors {
  Trigger = 'create-company-modal-trigger',
  Button = 'create-company-modal-button',
}

export enum EditCompanyModalSelectors {
  Trigger = 'edit-company-modal-trigger',
  Button = 'edit-company-modal-button',
}

export enum CreateContactUserModalSelectors {
  Trigger = 'create-contact-user-modal-trigger',
  Button = 'create-contact-user-modal-button',
}

export enum EditContactUserModalSelectors {
  Trigger = 'edit-contact-user-modal-trigger',
  Button = 'edit-contact-user-modal-button',
}

export enum DeleteContactUserModalSelectors {
  Trigger = 'delete-contact-user-modal-trigger',
  Button = 'delete-contact-user-modal-button',
}

export enum ContactFormSelectors {
  Role = 'contact-form-role',
  FirstName = 'contact-form-first-name',
  LastName = 'contact-form-last-name',
  Email = 'contact-form-email',
  MobileNumber = 'contact-form-mobile-number',
  Description = 'contact-form-description',
}

export enum ContactsTableSelectors {
  Pick = 'contacts-table-pick',
}

export enum SwitchLocationCompanyProfileSelectors {
  Root = 'switch-location-company-profile-root',
}

export enum AvatarSelectors {
  Root = 'avatar-root',
}

export enum LocationsTableSelectors {
  Pick = 'locations-table-pick',
}

export enum CompanyLocationInformationImageSelectors {
  Image = 'company-location-information-images-image',
}

export enum EditLocationModalSelectors {
  Trigger = 'edit-location-modal-trigger',
  Button = 'edit-location-modal-button',
}

export enum DeleteLocationModalSelectors {
  Trigger = 'delete-location-modal-trigger',
  Button = 'delete-location-modal-button',
}

export enum LocationFormSelectors {
  Name = 'location-form-name',
  Street = 'location-form-street',
  PostalCode = 'location-form-postal-code',
  City = 'location-form-city',
  ContactUser = 'location-form-contact-user',
  Description = 'location-form-description',
  Web = 'location-form-web',
  OrganisationNumber = 'location-form-organisation-number',
  BillingStreet = 'location-form-billing-street',
  BillingPostalCode = 'location-form-billing-postal-code',
  BillingCity = 'location-form-billing-city',
  BillingReference = 'location-form-billing-reference',
  EInvoice = 'location-form-e-invoice',
  Region = 'location-form-region',
  Latitude = 'location-form-latitude',
  Longitude = 'location-form-longitude',
}

export enum SwitchLocationContactCompanyProfileSelectors {
  Switch = 'switch-location-contact-company-profile-switch',
}

export enum ActionModalSelectors {
  Trigger = 'action-modal-trigger',
  Error = 'action-modal-error',
}

export enum ImageSelectors {
  Root = 'image-root',
  Spinner = 'image-spinner',
  Image = 'image-image',
}

export enum HeaderSelectors {
  Root = 'header-root',
  Landing = 'header-landing',
  Companies = 'header-companies',
  Company = 'header-company',
  ResourceCategories = 'header-resource-categories',
  Resources = 'header-resources',
  Users = 'header-users',
  Templates = 'header-templates',
  Bookings = 'header-bookings',
  Assignments = 'header-assignments',
  Notifications = 'header-notifications',
  Chat = 'header-chat',
  Attests = 'header-attests',
}

export enum UserFormSelectors {
  FirstName = 'user-form-first-name',
  LastName = 'user-form-last-name',
  Role = 'user-form-role',
  Email = 'user-form-email',
  MobileNumber = 'user-form-mobile-number',
  Password = 'user-form-password',
}

export enum EditUserModalSelectors {
  Trigger = 'edit-user-modal-trigger',
  Button = 'edit-user-modal-button',
}

export enum DeleteUserModalSelectors {
  Trigger = 'delete-user-modal-trigger',
  Button = 'delete-user-modal-button',
}

export enum ChangePasswordModalSelectors {
  Trigger = 'change-password-modal-trigger',
  Button = 'change-password-modal-button',
  OldPassword = 'change-password-modal-old-password',
  NewPassword = 'change-password-modal-new-password',
  NewPasswordRepeat = 'change-password-modal-new-password-repeat',
}

export enum UserAccountSelectors {
  Name = 'user-account-name',
}

export enum CreateUserModalSelectors {
  Trigger = 'create-user-modal-trigger',
  Button = 'create-user-modal-button',
}

export enum ResourceProfileSelectors {
  Name = 'resource-profile-name',
}

export enum EditResourceModalSelectors {
  Trigger = 'edit-resource-modal-trigger',
  Button = 'edit-resource-modal-button',
}

export enum ResetResourcePasswordModalSelectors {
  Trigger = 'reset-resource-password-modal-trigger',
  Button = 'reset-resource-password-modal-button',
}

export enum BlockResourceModalSelectors {
  Button = 'block-resource-modal-button',
}

export enum ResourceFormSelectors {
  FirstName = 'resource-form-first-name',
  LastName = 'resource-form-last-name',
  Email = 'resource-form-email',
  MobileNumber = 'resource-form-mobile-number',
  InternalComment = 'resource-form-internal-comment',
  RestaurantProfileEmergencyContactName = 'resource-form-restaurant-profile-emergency-contact-name',
  RestaurantProfileEmergencyContactPhone = 'resource-form-restaurant-profile-emergency-contact-phone',
  RestaurantProfileDescription = 'resource-form-restaurant-profile-description',
}

export enum CardSelectors {
  Root = 'card-root',
}

export enum EmptySelectors {
  Root = 'empty-root',
  Title = 'empty-title',
  Message = 'empty-message',
}

export enum CreateResourceLevelModalSelectors {
  Trigger = 'create-resource-level-modal-trigger',
  Button = 'create-resource-level-modal-button',
}

export enum ResourceLevelFormSelectors {
  Name = 'resource-level-form-name',
}

export enum CreateResourceCategoryModalSelectors {
  Trigger = 'create-resource-category-modal-trigger',
  Button = 'create-resource-category-modal-button',
}

export enum ResourceCategoryFormSelectors {
  Name = 'resource-category-form-name',
  LevelId = 'resource-category-form-level-id',
  DefaultSalary = 'resource-category-form-default-salary',
}

export enum ChatSelectors {
  Root = 'chat-root',
  Channels = 'chat-channels',
  Feed = 'chat-feed',
}

export enum BookingsSelectors {
  Root = 'bookings-root',
  SelectLocation = 'bookings-select-location',
  SelectDate = 'bookings-select-date',
  SelectWorkShiftSlot = 'bookings-select-work-shift-slot',
  Wrapper = 'bookings-wrapper',
}
