import { useMemo, useCallback } from 'react';

import classNames from 'classnames';
import {
  Controller,
  Control,
  RegisterOptions,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import TimeField from 'react-simple-timefield';

import { Error } from 'components/ui/forms';
import { Icon } from 'components/ui/general';
import { DateFormat } from 'consts/date';
import { format } from 'utils';

import styles from './Time.module.scss';

export type TimeProps = {
  name: string;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  defaultValue?: string;
  showSeconds?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  validation?: RegisterOptions;
  error?: FieldErrors;
  fullWidth?: boolean;
  onChange?: (value: string) => void;
  step?: {
    hours?: number;
    minutes?: number;
    seconds?: number;
  };
};

export const Time = ({
  name,
  control,
  setValue,
  getValues,
  defaultValue,
  showSeconds,
  disabled,
  readOnly,
  className,
  validation,
  error,
  fullWidth,
  onChange,
  step = { minutes: 5 },
}: TimeProps) => {
  const getDefaultValue = useMemo(() => {
    if (defaultValue) return defaultValue;
    if (showSeconds) return '00:00:00';
    return '00:00';
  }, [defaultValue, showSeconds]);

  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  const handleOnClick = useCallback(
    (type: 'add' | 'subtract') => {
      if (disabled || readOnly) return;

      const [hour, minute, second] = getValues(name).split(':');
      const currentTime = format.date().set({
        hour,
        minute,
        second,
      });

      if (type === 'add') {
        const value = currentTime
          .add(step.hours, 'hours')
          .add(step.minutes, 'minutes')
          .add(step.seconds, 'seconds')
          .format(
            DateFormat[showSeconds ? 'HourMinuteSeconds' : 'HourMinutes']
          );

        setValue(name, value);
        onChange?.(value);
      }

      if (type === 'subtract') {
        const value = currentTime
          .subtract(step.hours, 'hours')
          .subtract(step.minutes, 'minutes')
          .subtract(step.seconds, 'seconds')
          .format(
            DateFormat[showSeconds ? 'HourMinuteSeconds' : 'HourMinutes']
          );

        setValue(name, value);
        onChange?.(value);
      }
    },
    [disabled, readOnly, getValues, name, setValue, showSeconds, step, onChange]
  );

  return (
    <>
      <div
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles.readOnly]: readOnly,
          [styles.showSeconds]: showSeconds,
          [styles.fullWidth]: fullWidth,
        })}
      >
        <Controller
          rules={getValidation}
          name={name}
          control={control}
          defaultValue={getDefaultValue}
          render={({ field }) => (
            <TimeField
              {...field}
              input={
                <input
                  type="text"
                  className={styles.input}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              }
              colon=":"
              showSeconds={showSeconds}
            />
          )}
        />
        <button
          type="button"
          onClick={() => handleOnClick('add')}
          className={styles.buttonAdd}
          disabled={disabled}
        >
          <Icon name="angle-up" font="ericeira" />
        </button>
        <button
          type="button"
          onClick={() => handleOnClick('subtract')}
          className={styles.buttonSubtract}
          disabled={disabled}
        >
          <Icon name="angle-down" font="ericeira" />
        </button>
      </div>
      <Error error={error} />
    </>
  );
};
