import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { Moment } from 'moment';
import { useForm } from 'react-hook-form';

import { Text } from 'components/ui/forms';
import { DatePickerSingle, DatePickerSingleProps } from 'components/ui/general';
import { TableProps } from 'components/ui/table';
import { DateFormat } from 'consts/date';
import { useClickOutside, useKeyPress, useTable } from 'hooks';
import { format } from 'utils';

import styles from './FilterDate.module.scss';

export type FilterDateProps = {
  date: {
    name: string;
    placeholder: string;
    datePicker?: DatePickerSingleProps;
  };
  handleQueryParameters?: TableProps['handleQueryParameters'];
  onFilterChange: (date: Moment | null, name: string) => void;
};

export const FilterDate = ({
  date,
  handleQueryParameters,
  onFilterChange,
}: FilterDateProps) => {
  const { getFilterQueryParameter } = useTable();
  const ref = useRef(null);
  const defaultValue = useMemo(() => {
    let collectDefaultValue: Moment | null = null;
    const getFilter = getFilterQueryParameter();

    if (!getFilter?.length || !handleQueryParameters) {
      return collectDefaultValue;
    }

    getFilter.forEach((filter) => {
      const [getName, getDate] = filter.split('__');

      if (getName === date.name) {
        collectDefaultValue = format.date({ date: getDate });
      }
    });

    return collectDefaultValue;
  }, [date.name, getFilterQueryParameter, handleQueryParameters]);
  const [currentDate, setCurrentDate] = useState<Moment | null>(defaultValue);
  const [focused, setFocused] = useState<boolean>(true);
  const { register, setValue } = useForm();
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  useClickOutside(ref, () => {
    setDatePickerVisible(false);
  });

  useKeyPress(27, () => {
    setDatePickerVisible(false);
  });

  useEffect(() => {
    setValue(
      date.name,
      currentDate ? currentDate.format(DateFormat.DateStamp) : ''
    );
    setDatePickerVisible(false);
  }, [currentDate, setValue, date.name]);

  const handleDateChange = useCallback(
    (dateChange: Moment | null) => {
      let getDateChange: Moment | null = format.date({ date: dateChange });
      if (currentDate?.isSame(getDateChange, 'date')) getDateChange = null;

      setCurrentDate(getDateChange);
      onFilterChange(getDateChange, date.name);
    },
    [currentDate, date.name, onFilterChange]
  );

  return (
    <div
      className={classNames(styles.root, {
        [styles.datePickerVisible]: datePickerVisible,
      })}
      ref={ref}
    >
      <button
        type="button"
        className={styles.textHolder}
        onClick={() => setDatePickerVisible(!datePickerVisible)}
      >
        <fieldset disabled className={styles.textFieldset}>
          <Text
            register={register}
            name={date.name}
            fullWidth
            iconRight={{ name: 'calendar-day' }}
            placeholder={date.placeholder}
          />
        </fieldset>
      </button>
      <DatePickerSingle
        date={currentDate}
        onDateChange={handleDateChange}
        initialVisibleMonth={() => currentDate || format.date()}
        focused={focused}
        onFocusChange={(focusChange) => setFocused(focusChange.focused)}
        numberOfMonths={1}
        {...date.datePicker}
        className={classNames(styles.datePicker, date.datePicker?.className)}
      />
    </div>
  );
};
