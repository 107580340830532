import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'api/graphql';

// Types
type WorkShiftResourcesState = {
  inviteToAnyOfSlots: (string | number)[] | string | number | null;
  inviteToAllSlots: (string | number)[] | null;
  workShiftSlotsResources: {
    slotId: string | number;
    resources: User[];
  }[];
};

export type WorkShiftResourcesRootState = {
  workShiftResources: WorkShiftResourcesState;
};

// Selectors
export const selectWorkShiftResources = ({
  workShiftResources,
}: WorkShiftResourcesRootState) => workShiftResources;
export const selectInviteToAnyOfSlots = ({
  workShiftResources,
}: WorkShiftResourcesRootState) => workShiftResources.inviteToAnyOfSlots;
export const selectInviteToAllSlots = ({
  workShiftResources,
}: WorkShiftResourcesRootState) => workShiftResources.inviteToAllSlots;
export const selectWorkShiftSlotsResources = ({
  workShiftResources,
}: WorkShiftResourcesRootState) => workShiftResources.workShiftSlotsResources;

// Reducers
const initialState: WorkShiftResourcesState = {
  inviteToAnyOfSlots: null,
  inviteToAllSlots: null,
  workShiftSlotsResources: [],
};

const workShiftResourcesSlice = createSlice({
  name: 'workShiftResources',
  initialState,
  reducers: {
    setInviteToAnyOfSlots: (
      state,
      action: PayloadAction<WorkShiftResourcesState['inviteToAnyOfSlots']>
    ) => {
      state.inviteToAnyOfSlots = action.payload;
      state.inviteToAllSlots = null;
    },
    setInviteToAllSlots: (
      state,
      action: PayloadAction<WorkShiftResourcesState['inviteToAllSlots']>
    ) => {
      state.inviteToAllSlots = action.payload;
      state.inviteToAnyOfSlots = null;
    },
    addWorkShiftSlotsResources: (
      state,
      action: PayloadAction<WorkShiftResourcesState['workShiftSlotsResources']>
    ) => {
      const currentWorkShiftSlotsResources = current(
        state.workShiftSlotsResources
      );

      action.payload.forEach((workShiftSlotResources) => {
        const slotIdAlreadyExists = currentWorkShiftSlotsResources.find(
          ({ slotId }) => slotId === workShiftSlotResources.slotId
        );

        if (!slotIdAlreadyExists) {
          state.workShiftSlotsResources = [
            ...state.workShiftSlotsResources,
            workShiftSlotResources,
          ];
          return;
        }

        state.workShiftSlotsResources = [
          ...state.workShiftSlotsResources.filter(
            ({ slotId }) => slotId !== workShiftSlotResources.slotId
          ),
          {
            ...slotIdAlreadyExists,
            resources: [
              ...slotIdAlreadyExists.resources,
              ...workShiftSlotResources.resources.filter(
                (resource) =>
                  !slotIdAlreadyExists.resources.find(
                    ({ id }) => id === resource.id
                  )
              ),
            ],
          },
        ];
      });
    },
    removeWorkShiftSlotsResource: (
      state,
      action: PayloadAction<
        {
          slotId: string | number;
          resource: User;
        }[]
      >
    ) => {
      const currentWorkShiftSlotsResources = current(
        state.workShiftSlotsResources
      );

      action.payload.forEach((workShiftSlotResources) => {
        const getWorkShiftSlotResources = currentWorkShiftSlotsResources.find(
          ({ slotId }) => slotId === workShiftSlotResources.slotId
        );
        if (!getWorkShiftSlotResources) return;

        state.workShiftSlotsResources = [
          ...state.workShiftSlotsResources.filter(
            ({ slotId }) => slotId !== workShiftSlotResources.slotId
          ),
          {
            ...getWorkShiftSlotResources,
            resources: getWorkShiftSlotResources.resources.filter(
              ({ id }) => id !== workShiftSlotResources.resource.id
            ),
          },
        ];
      });
    },
    setInitialState: () => initialState,
  },
});

export default workShiftResourcesSlice.reducer;

// Actions
export const {
  setInviteToAnyOfSlots,
  setInviteToAllSlots,
  setInitialState,
  addWorkShiftSlotsResources,
  removeWorkShiftSlotsResource,
} = workShiftResourcesSlice.actions;
