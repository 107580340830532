import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  buttonHideChannel: {
    id: 'HideChannelModal.buttonHideChannel',
    description: 'Button hide channel',
    defaultMessage: 'Dölj',
  },
  hideChannelTitle: {
    id: 'HideChannelModal.hideChannelTitle',
    description: 'Hide channel modal message',
    defaultMessage: 'Vill du dölja chatten?',
  },
  hideChatChannelError: {
    id: 'HideChannelModal.hideChatChannelError',
    description: 'Hide chat channel error',
    defaultMessage: 'Kunde inte dölja chatten',
  },
  hideChatChannelSuccess: {
    id: 'HideChannelModal.hideChatChannelSuccess',
    description: 'Hide chat channel success',
    defaultMessage: 'Chatten är dold',
  },
  tooltip: {
    id: 'HideChannelModal.tooltip',
    description: 'Tooltip',
    defaultMessage: 'Dölj chatt',
  },
});
