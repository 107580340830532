import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  setLocationFavouriteError: {
    id: 'ToggleLocationFavourite.setLocationFavouriteError',
    description: 'Set location favourite error',
    defaultMessage: 'Kunde inte ändra favoritmarkering',
  },
  setLocationFavouriteSuccess: {
    id: 'ToggleLocationFavourite.setLocationFavouriteSuccess',
    description: 'Set location favourite success',
    defaultMessage: 'Har ändrat favoritmarkering',
  },
});
