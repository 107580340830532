import { UseFormSetValue } from 'react-hook-form';

import { Company } from 'api/graphql';
import { SelectProps, TextProps } from 'components/ui/forms';
import { Gutter } from 'components/ui/general';

import { Location, Billing } from './subcomponents';

type LocationFormProps = {
  company: Company;
  register: TextProps['register'];
  control: SelectProps['control'];
  setValue: UseFormSetValue<any>;
  errors: {
    [key: string]: any;
  };
  defaultValues?: {
    [key: string]: any;
  };
  disabled?: boolean;
  edit?: boolean;
  showSameBilling?: boolean;
};

export const LocationForm = ({
  company,
  register,
  control,
  setValue,
  errors,
  defaultValues,
  disabled,
  edit,
  showSameBilling,
}: LocationFormProps) => {
  return (
    <Gutter gutter={{ bottom: 4 }}>
      <Gutter.Item>
        <Location
          {...{
            company,
            register,
            control,
            edit,
            errors,
            defaultValues,
            disabled,
            setValue,
          }}
        />
      </Gutter.Item>
      <Gutter.Item>
        <Billing
          {...{
            company,
            register,
            errors,
            defaultValues,
            disabled,
            showSameBilling,
            setValue,
          }}
        />
      </Gutter.Item>
    </Gutter>
  );
};
