import { ComponentProps } from 'react';

import { components } from 'react-select';

import { Icon } from 'components/ui/general';

export const MultiValueRemove = (
  props: ComponentProps<typeof components.MultiValueRemove>
) => {
  const { selectProps }: { [key: string]: any } = props;
  const { iconMultiValueRemove } = selectProps;

  if (iconMultiValueRemove)
    return (
      <components.MultiValueRemove {...props}>
        <Icon name={iconMultiValueRemove} font="ericeira" />
      </components.MultiValueRemove>
    );

  return <components.MultiValueRemove {...props} />;
};
