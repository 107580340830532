import { BackOfficeMainBookingStatus } from 'api/graphql';

export const getTagColorForWorkShiftSlot = (
  summarizedBookingStatus: BackOfficeMainBookingStatus
) => {
  switch (summarizedBookingStatus) {
    case BackOfficeMainBookingStatus.Requested:
      return 'warning';
    case BackOfficeMainBookingStatus.Accepted:
      return 'success';
    case BackOfficeMainBookingStatus.Started:
      return 'primary';
    case BackOfficeMainBookingStatus.Completed:
      return 'dark';
    case BackOfficeMainBookingStatus.Canceled:
    default:
      return 'error';
  }
};
