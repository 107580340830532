import { ReactNode } from 'react';

import classNames from 'classnames';

import { Icon } from 'components/ui/general';

import styles from './Information.module.scss';

export type InformationProps = {
  children: ReactNode;
  status?: 'info' | 'success' | 'error' | 'warning' | 'light' | 'dark';
  icon?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
};

export const Information = ({
  children,
  status = 'info',
  icon = true,
  className,
  size = 'md',
}: InformationProps) => {
  if (children) {
    return (
      <div
        className={classNames(styles.root, className, {
          [styles[`${status}Status`]]: status,
          [styles[`${size}Size`]]: size,
        })}
      >
        {icon && (
          <Icon
            name="exclamation-circle"
            font={size === 'lg' ? 'madrid' : 'ericeira'}
            className={styles.icon}
          />
        )}
        <div className={styles.children}>{children}</div>
      </div>
    );
  }

  return null;
};
