import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  useMessageWorkShiftMutation,
  useSendMessageMutation,
} from 'api/graphql';
import { SendImages, SendFiles, HideChannelModal } from 'components/ui/chat';
import { Textarea, TextareaProps } from 'components/ui/forms';
import { Button, Grid } from 'components/ui/general';
import { QueryParameters } from 'consts/chat';
import { Paths } from 'consts/router';
import { useToast } from 'hooks';

import { texts } from './SendMessage.text';

import styles from './SendMessage.module.scss';

type SendMessageProps = {
  channelId?: string | number;
  workShiftId?: string | number;
  onHeightChange?: TextareaProps['onHeightChange'];
  disabled?: boolean;
};

export const SendMessage = ({
  channelId,
  workShiftId,
  onHeightChange,
  disabled,
}: SendMessageProps) => {
  const navigate = useNavigate();
  const { handleSubmit, register, reset } = useForm();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const [sendMessage, sendMessageData] = useSendMessageMutation();
  const [messageWorkShift, messageWorkShiftData] =
    useMessageWorkShiftMutation();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const loading = useMemo(
    () => sendMessageData.loading || messageWorkShiftData.loading,
    [messageWorkShiftData.loading, sendMessageData.loading]
  );

  useEffect(() => {
    return () => reset();
  }, [channelId, workShiftId, reset]);

  useEffect(() => {
    if (sendMessageData.error) {
      addToast({
        title: formatMessage(texts.sendMessageError),
        message: sendMessageData.error.message,
        type: 'error',
      });
    }
  }, [addToast, sendMessageData.error, formatMessage]);

  useEffect(() => {
    if (messageWorkShiftData.error) {
      addToast({
        title: formatMessage(texts.sendMessageError),
        message: messageWorkShiftData.error.message,
        type: 'error',
      });
    }
  }, [addToast, messageWorkShiftData.error, formatMessage]);

  const focusTextarea = useCallback(() => {
    const { current } = textareaRef;
    if (current && !disabled) current.focus();
  }, [disabled]);

  const onSubmit = useCallback(
    async ({ message }) => {
      if (!message?.replace(/\s/g, '')) {
        return;
      }

      if (channelId) {
        const response = await sendMessage({
          variables: {
            input: { channelId, message },
          },
        });

        if (response.data?.sendMessage.success) {
          reset();
          focusTextarea();
        }
      }

      if (workShiftId) {
        const response = await messageWorkShift({
          variables: { id: workShiftId, message },
        });

        if (response.data?.messageWorkShift) {
          navigate(
            {
              pathname: Paths.Chat,
              search: `${QueryParameters.ChannelId}=${response.data.messageWorkShift.id}`,
            },
            {
              replace: true,
            }
          );
        }
      }
    },
    [
      channelId,
      sendMessage,
      reset,
      focusTextarea,
      navigate,
      workShiftId,
      messageWorkShift,
    ]
  );

  const onEnterPress = useCallback(
    (event) => {
      if (event.keyCode === 13 && event.shiftKey === false) {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    },
    [handleSubmit, onSubmit]
  );

  useEffect(() => {
    focusTextarea();
  }, [focusTextarea, channelId]);

  return (
    <div className={styles.root}>
      <Grid
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        gutter={{ bottom: 1.5, left: 1.5 }}
        align="bottom"
      >
        {!!channelId && (
          <Grid.Item>
            <SendFiles channelId={channelId} disabled={disabled} />
          </Grid.Item>
        )}
        {!!channelId && (
          <Grid.Item>
            <SendImages channelId={channelId} disabled={disabled} />
          </Grid.Item>
        )}
        {!!channelId && (
          <Grid.Item>
            <HideChannelModal channelId={channelId} disabled={disabled} />
          </Grid.Item>
        )}
        <Grid.Item fill>
          <Textarea
            name="message"
            register={register}
            fullWidth
            minRows={1}
            maxRows={3}
            placeholder={formatMessage(texts.textareaPlaceholder)}
            onHeightChange={onHeightChange}
            onKeyDown={onEnterPress}
            textareaRef={textareaRef}
            disabled={disabled || loading}
          />
        </Grid.Item>
        <Grid.Item>
          <Button
            type="submit"
            fullWidth
            disabled={disabled || loading}
            loading={loading}
            size="lg"
          >
            <FormattedMessage {...texts.sendMessageButton} />
          </Button>
        </Grid.Item>
      </Grid>
    </div>
  );
};
