export enum QueryParameters {
  ActiveStepId = 'activeStepId',
}

export enum WorkShiftSteps {
  PreSelection = 'preSelection',
  DateAndTime = 'dateAndTime',
  Summary = 'summary',
  SelectResources = 'selectResources',
}
