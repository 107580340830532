import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, delay } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'redux/reducers';

type NotificationAction = {
  type: string;
  payload?: any;
};

export type NotificationTypes =
  | 'light'
  | 'dark'
  | 'error'
  | 'success'
  | 'info'
  | 'warning';

export type Notification = {
  id: number;
  timestamp: number;
  title?: string;
  message?: string;
  type?: NotificationTypes;
};

type NotificationState = {
  notifications: Notification[];
};

export type NotificationRootState = {
  notification: NotificationState;
};

// Selectors
export const selectNotification = ({ notification }: NotificationRootState) =>
  notification;
export const selectNotifications = createSelector(
  [selectNotification],
  ({ notifications }) =>
    notifications.slice().sort((a, b) => a.timestamp - b.timestamp)
);

// Reducers
const initialState: NotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (
      state,
      _action: PayloadAction<{
        title?: Notification['title'];
        message?: Notification['message'];
        type?: Notification['type'];
      }>
    ) => state,
    addNotificationSuccess: (state, action: PayloadAction<Notification>) => {
      const prevNotifications = state.notifications;

      if (prevNotifications.length > 2) {
        prevNotifications.shift();
      }

      state.notifications = [...prevNotifications, action.payload];
    },
    deleteNotification: (
      state,
      action: PayloadAction<{ id: Notification['id'] }>
    ) => {
      state.notifications = state.notifications.filter(
        ({ id }) => id !== action.payload.id
      );
    },
    deleteAllNotifications: (state) => {
      state.notifications = initialState.notifications;
    },
  },
});

export default notificationSlice.reducer;

// Actions
export const {
  addNotification,
  addNotificationSuccess,
  deleteNotification,
  deleteAllNotifications,
} = notificationSlice.actions;

// Epics
const addNotificationEpic: Epic<
  NotificationAction,
  NotificationAction,
  RootState
> = (action$) =>
  action$.pipe(
    ofType(addNotification.type),
    mergeMap(({ payload }) =>
      of({
        type: addNotificationSuccess.type,
        payload: {
          id: uuidv4(),
          timestamp: new Date().getTime(),
          ...payload,
        },
      })
    )
  );

const deleteNotificationEpic: Epic<
  NotificationAction,
  NotificationAction,
  RootState
> = (action$) =>
  action$.pipe(
    ofType(addNotificationSuccess.type),
    mergeMap(({ payload }) =>
      of({ type: deleteNotification.type, payload }).pipe(delay(20000))
    )
  );

export const notificationEpics = combineEpics(
  addNotificationEpic,
  deleteNotificationEpic
);
