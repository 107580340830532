import { useCallback } from 'react';

export const useGoogleMaps = () => {
  const getAddressComponents = useCallback(
    (components: google.maps.places.PlaceResult['address_components']) => {
      let collectComponents: {
        [key in
          | 'streetNumber'
          | 'route'
          | 'postalTown'
          | 'administrativeAreaLevel1'
          | 'country'
          | 'postalCode']?: string;
      } = {};

      components?.forEach(({ types, long_name: longName }) => {
        switch (types[0]) {
          case 'street_number':
            collectComponents = {
              ...collectComponents,
              streetNumber: longName,
            };
            break;

          case 'route':
            collectComponents = {
              ...collectComponents,
              route: longName,
            };
            break;

          case 'postal_town':
            collectComponents = {
              ...collectComponents,
              postalTown: longName,
            };
            break;

          case 'administrative_area_level_1':
            collectComponents = {
              ...collectComponents,
              administrativeAreaLevel1: longName,
            };
            break;

          case 'country':
            collectComponents = {
              ...collectComponents,
              country: longName,
            };
            break;

          case 'postal_code':
            collectComponents = {
              ...collectComponents,
              postalCode: longName,
            };
            break;

          default:
            break;
        }
      });

      return collectComponents;
    },
    []
  );

  return { getAddressComponents };
};
