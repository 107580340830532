import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useCreateUserMutation, UserRole, UsersDocument } from 'api/graphql';
import { Text } from 'components/ui/forms';
import { ActionModal, Button, Gutter } from 'components/ui/general';
import { UserForm } from 'components/ui/user';
import { CreateUserModalSelectors } from 'consts/cypress';
import { Paths } from 'consts/router';
import { useToast, useValidate } from 'hooks';

import { texts } from './CreateUserModal.text';

type CreateUserModalProps = {
  trigger?: ReactNode;
};

export const CreateUserModal = ({ trigger }: CreateUserModalProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();
  const { isInt } = useValidate();

  const [createUser, { error, loading }] = useCreateUserMutation({
    refetchQueries: [UsersDocument],
  });

  const onSubmit = useCallback(
    async ({
      email,
      firstName,
      lastName,
      password,
      mobileNumber,
      legacyId,
    }) => {
      const { data } = await createUser({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            password,
            mobileNumber,
            role: UserRole.Admin,
            legacyId: legacyId || undefined,
          },
        },
      });

      if (data?.createUser) {
        navigate(Paths.User(data.createUser.userId));
        addToast({
          title: formatMessage(texts.createUserSuccess),
          type: 'success',
        });
      }
    },
    [createUser, addToast, formatMessage, navigate]
  );

  useEffect(() => {
    if (!isOpen) reset();
  }, [reset, isOpen]);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.button} />,
        type: 'submit',
        loading,
        disabled: loading,
        'data-cy': CreateUserModalSelectors.Button,
      }}
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      trigger={
        trigger || (
          <Button
            iconLeft={{ name: 'plus' }}
            as="div"
            data-cy={CreateUserModalSelectors.Trigger}
          >
            <FormattedMessage {...texts.trigger} />
          </Button>
        )
      }
    >
      <UserForm>
        <Gutter gutter={{ bottom: 2 }}>
          <Gutter.Item>
            <UserForm.Required
              {...{ register, control, errors }}
              password
              defaultValues={{
                role: {
                  label: formatMessage(texts.userRole_admin),
                  value: UserRole.Admin,
                },
              }}
            />
          </Gutter.Item>
          <Gutter.Item>
            <UserForm.MobileNumber {...{ register, errors }} />
          </Gutter.Item>
          <Gutter.Item>
            <Text
              name="legacyId"
              label={formatMessage(texts.form_labelLegacyId)}
              register={register}
              fullWidth
              validation={{
                validate: (value) => value?.length && isInt(value),
              }}
              error={errors?.legacyId}
            />
          </Gutter.Item>
        </Gutter>
      </UserForm>
    </ActionModal>
  );
};
