import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.userRole,
  ...shared.form,
  passwordValidationLength: {
    id: 'UserForm.passwordValidationLength',
    description: 'Pssword validation message',
    defaultMessage: 'Måste vara minst 8 tecken',
  },
});
