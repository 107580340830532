import { ChangeEvent, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { Icon } from 'components/ui/general';

import styles from './StyledOption.module.scss';

type StyledOptionProps = {
  label: ReactNode;
  type: 'radio' | 'checkbox';
  name: string;
  value?: string;
  className?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: FieldErrors;
  defaultChecked?: boolean;
};

export const StyledOption = ({
  label,
  type,
  name,
  value,
  className,
  disabled,
  register,
  validation = {},
  onChange,
  onBlur,
  error,
  defaultChecked,
}: StyledOptionProps) => {
  const registerHolder = useMemo(
    () => register(name, validation),
    [name, register, validation]
  );

  return (
    <>
      <label
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
        })}
      >
        <input
          type={type}
          disabled={disabled}
          value={value}
          className={styles.input}
          defaultChecked={defaultChecked}
          {...registerHolder}
          onChange={(event) => {
            registerHolder.onChange(event);
            onChange?.(event);
          }}
          onBlur={(event) => {
            registerHolder.onBlur(event);
            onBlur?.(event);
          }}
        />
        <div className={styles.content}>
          <div className={styles.circleHolder}>
            <Icon name="circle" font="kyoto" className={styles.circle} />
            <Icon
              name="circle-dot"
              font="kyoto"
              className={styles.circleCheck}
            />
          </div>
          <div className={styles.label}>{label}</div>
        </div>
      </label>
      <Error error={error} />
    </>
  );
};
