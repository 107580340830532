import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import {
  addNotification as addNotificationRedux,
  deleteNotification as deleteNotificationRedux,
  deleteAllNotifications as deleteAllNotificationsRedux,
  Notification,
} from 'redux/notification';

export const useNotification = (): {
  addNotification: (notification: {
    title?: Notification['title'];
    message?: Notification['message'];
    type?: Notification['type'];
  }) => void;
  deleteNotification: (notification: { id: Notification['id'] }) => void;
  deleteAllNotifications: () => void;
} => {
  const dispatch = useDispatch();

  const addNotification = useCallback(
    (notification: {
      title?: Notification['title'];
      message?: Notification['message'];
      type?: Notification['type'];
    }) => dispatch(addNotificationRedux(notification)),
    [dispatch]
  );

  const deleteNotification = useCallback(
    (notification: { id: Notification['id'] }) =>
      dispatch(deleteNotificationRedux(notification)),
    [dispatch]
  );

  const deleteAllNotifications = useCallback(
    () => dispatch(deleteAllNotificationsRedux()),
    [dispatch]
  );

  return { addNotification, deleteNotification, deleteAllNotifications };
};
