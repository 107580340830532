import { useEffect, useCallback, ReactNode } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { Portal } from 'components/tools';
import { Middle, MiddleProps, Side } from 'components/ui/modals/Modal/types';
import { ModalSelectors } from 'consts/cypress';
import { Durations, Easings } from 'consts/transition';
import { useKeyPress } from 'hooks';
import { Types, Aligns } from 'types/modal';
import { preventScroll } from 'utils';

import styles from './Modal.module.scss';

export type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  type?: Types;
  align?: Aligns;
  ariaLabel?: string;
  className?: string;
  classNameType?: string;
  classNameClose?: string;
  middleSize?: MiddleProps['size'];
  middleTransparent?: MiddleProps['transparent'];
  middleStripPadding?: MiddleProps['stripPadding'];
};

export const Modal = ({
  children,
  isOpen,
  onClose,
  type,
  align,
  ariaLabel,
  className,
  classNameType,
  classNameClose,
  middleSize,
  middleTransparent,
  middleStripPadding,
}: ModalProps) => {
  const closeModal = useCallback(() => {
    if (isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  useKeyPress(27, () => {
    closeModal();
  });

  useEffect(() => {
    preventScroll(isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => preventScroll(false);
  }, []);

  const getModalType = useCallback(() => {
    switch (type) {
      case 'side':
        return (
          <Side
            onClose={closeModal}
            align={align}
            classNameType={classNameType}
            classNameClose={classNameClose}
          >
            {children}
          </Side>
        );

      case 'middle':
      default:
        return (
          <Middle
            onClose={closeModal}
            size={middleSize}
            transparent={middleTransparent}
            stripPadding={middleStripPadding}
            classNameType={classNameType}
            classNameClose={classNameClose}
          >
            {children}
          </Middle>
        );
    }
  }, [
    children,
    closeModal,
    type,
    align,
    middleSize,
    middleTransparent,
    middleStripPadding,
    classNameType,
    classNameClose,
  ]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal selector="#modal">
          <motion.div
            key="root"
            role="dialog"
            aria-label={ariaLabel}
            className={classNames(styles.root, className)}
            transition={{ duration: Durations.Fast, ease: Easings.InOut }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            data-cy={ModalSelectors.Root}
          >
            <div className={styles.inner}>{getModalType()}</div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};
