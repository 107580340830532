import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  menuUpdateImage: {
    id: 'UserAvatar.menuUpdateImage',
    description: 'Menu update image',
    defaultMessage: 'Ladda upp ny bild',
  },
  menuDeleteImage: {
    id: 'UserAvatar.menuDeleteImage',
    description: 'Menu delete image',
    defaultMessage: 'Ta bort bild',
  },
  updateUserImageError: {
    id: 'UserAvatar.updateUserImageError',
    description: 'Update user image error',
    defaultMessage: 'Kunde inte uppdatera bilden',
  },
  updateUserImageSuccess: {
    id: 'UserAvatar.updateUserImageSuccess',
    description: 'Update user image success',
    defaultMessage: 'Bilden är uppdaterad',
  },
});
