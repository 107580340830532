import { useIntl } from 'react-intl';

import { Text, TextProps } from 'components/ui/forms';
import { UserFormSelectors } from 'consts/cypress';
import { useValidate } from 'hooks';

import { texts } from '../UserForm.text';

type MobileNumberProps = {
  register: TextProps['register'];
  errors: {
    [key: string]: any;
  };
  defaultValues?: {
    [key: string]: any;
  };
  disabled?: boolean;
};

export const MobileNumber = ({
  register,
  errors,
  defaultValues,
  disabled,
}: MobileNumberProps) => {
  const { formatMessage } = useIntl();
  const { isPhoneNumber } = useValidate();

  return (
    <div data-cy={UserFormSelectors.MobileNumber}>
      <Text
        name="mobileNumber"
        type="tel"
        label={formatMessage(texts.form_labelMobileNumber)}
        register={register}
        validation={{
          validate: (value) => value?.length && isPhoneNumber(value),
        }}
        error={errors?.mobileNumber}
        fullWidth
        defaultValue={defaultValues?.mobileNumber}
        disabled={disabled}
      />
    </div>
  );
};
