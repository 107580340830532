import { useContext, useEffect, useRef, useState } from 'react';

import { Status } from '@googlemaps/react-wrapper';

import { Text, TextProps } from 'components/ui/forms';
import { TranslationsContext } from 'context/translations';

type TextWithGoogleMapsAutocompleteProps = {
  text: TextProps;
  status: Status;
  onPlaceChanged?: (place: google.maps.places.PlaceResult) => void;
};

export const TextWithGoogleMapsAutocomplete = ({
  text,
  status,
  onPlaceChanged,
}: TextWithGoogleMapsAutocompleteProps) => {
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [lang] = useContext(TranslationsContext);

  useEffect(() => {
    if (status === Status.FAILURE) {
      console.warn(
        'Failed to load Google Maps API, but you can still type your value without results'
      );
    }
  }, [status]);

  useEffect(() => {
    const input = inputRef.current;
    if (!input || status !== Status.SUCCESS) return;

    const autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: {
        country: lang === 'sv' ? 'se' : null,
      },
    });
    const placeChanged = google.maps.event.addListener(
      autocomplete,
      'place_changed',
      () => setPlace(autocomplete.getPlace())
    );

    return () => google.maps.event.removeListener(placeChanged);
  }, [status, lang]);

  useEffect(() => {
    if (place && onPlaceChanged) {
      onPlaceChanged(place);
    }
  }, [onPlaceChanged, place]);

  return (
    <Text
      {...text}
      inputRef={inputRef}
      placeholder={text.placeholder || ''}
      disabled={text.disabled || status === Status.LOADING}
    />
  );
};
