import { useMemo } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { UserRole } from 'api/graphql';
import { JOGLOblueLogo } from 'assets/icons';
import { Link } from 'components/tools';
import { Container, Icon, Menu } from 'components/ui/general';
import { HeaderSelectors } from 'consts/cypress';
import { Paths } from 'consts/router';
import { useAuth } from 'hooks';
import { logoutStart } from 'redux/auth';
import { typeGuards } from 'utils';

import { Chat } from './subcomponents';

import { texts } from './Header.text';

import styles from './Header.module.scss';

export const Header = () => {
  const dispatch = useDispatch();
  const { loggedIn, user, role } = useAuth();

  const menuItems = useMemo(
    () => [
      {
        label: <FormattedMessage {...texts.linkSettings} />,
        closeOnClick: true,
        link: {
          to: Paths.Settings,
        },
      },
      {
        label: <FormattedMessage {...texts.linkLogout} />,
        onClick: () => dispatch(logoutStart()),
      },
    ],
    [dispatch]
  );

  const links = useMemo(() => {
    switch (role) {
      case UserRole.Admin:
        return [
          {
            to: Paths.Landing,
            children: <FormattedMessage {...texts.linkLanding} />,
            'data-cy': HeaderSelectors.Landing,
          },
          {
            to: Paths.Companies,
            children: <FormattedMessage {...texts.linkCompanies} />,
            'data-cy': HeaderSelectors.Companies,
          },
          {
            to: Paths.Resources,
            children: <FormattedMessage {...texts.linkResources} />,
            'data-cy': HeaderSelectors.Resources,
          },
          {
            to: Paths.ResourceCategories,
            children: <FormattedMessage {...texts.linkResourceCategories} />,
            'data-cy': HeaderSelectors.ResourceCategories,
          },
          {
            to: Paths.Users,
            children: <FormattedMessage {...texts.linkUsers} />,
            'data-cy': HeaderSelectors.Users,
          },
          {
            to: Paths.Assignments,
            children: <FormattedMessage {...texts.linkAssignments} />,
            'data-cy': HeaderSelectors.Assignments,
          },
          {
            to: Paths.Notifications,
            children: <FormattedMessage {...texts.linkNotifications} />,
            'data-cy': HeaderSelectors.Notifications,
          },
        ];

      case UserRole.CompanyAdmin:
      case UserRole.CompanyUser: {
        const companyProfile = user?.userProfile.map((profile) =>
          typeGuards.companyProfile(profile)
        )[0];

        return [
          companyProfile && {
            to: Paths.Company(companyProfile.company.id),
            children: <FormattedMessage {...texts.linkCompany} />,
            'data-cy': HeaderSelectors.Company,
          },
          {
            to: Paths.Resources,
            children: <FormattedMessage {...texts.linkResources} />,
            'data-cy': HeaderSelectors.Resources,
          },
          {
            to: Paths.Bookings,
            children: <FormattedMessage {...texts.linkBookings} />,
            'data-cy': HeaderSelectors.Bookings,
          },
          {
            to: Paths.Templates,
            children: <FormattedMessage {...texts.linkTemplates} />,
            'data-cy': HeaderSelectors.Templates,
          },
          {
            to: Paths.Attests,
            children: <FormattedMessage {...texts.linkAttests} />,
            'data-cy': HeaderSelectors.Attests,
          },
          {
            to: Paths.Notifications,
            children: <FormattedMessage {...texts.linkNotifications} />,
            'data-cy': HeaderSelectors.Notifications,
          },
        ].filter((item) => item);
      }

      default:
        return [];
    }
  }, [user, role]);

  if (!loggedIn) {
    return null;
  }

  return (
    <header className={styles.root} data-cy={HeaderSelectors.Root}>
      <Container className={styles.container} fullWidth>
        <Link to={Paths.Landing} className={styles.logoHolder}>
          <JOGLOblueLogo />
        </Link>
        <nav className={styles.navigation}>
          {links.map((link) => {
            if (link) {
              return (
                <Link
                  key={link.to}
                  className={({ isActive }) =>
                    classNames(styles.navigationItem, {
                      [styles.navigationItemActive]: isActive,
                    })
                  }
                  {...link}
                />
              );
            }
            return null;
          })}
          {role !== UserRole.User && (
            <Link
              to={Paths.Chat}
              data-cy={HeaderSelectors.Chat}
              className={({ isActive }) =>
                classNames(styles.navigationItem, {
                  [styles.navigationItemActive]: isActive,
                })
              }
            >
              <Chat />
            </Link>
          )}
          <Menu
            placement="bottom-end"
            className={styles.menu}
            classNameMenu={styles.menuMenu}
            classNameTrigger={styles.navigationItem}
            items={menuItems}
          >
            <Icon name="user-circle" font="nuuk" />
          </Menu>
        </nav>
      </Container>
    </header>
  );
};
