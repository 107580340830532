import { useState, useCallback, RefObject } from 'react';

import { useEventListener } from 'hooks';

type UseStayScrolled = {
  element: RefObject<Element>;
};

export const useStayScrolled = ({ element }: UseStayScrolled) => {
  const { current } = element;
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const isScrolled = useCallback(() => {
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      return Math.ceil(scrollTop) < scrollHeight - clientHeight;
    }

    return true;
  }, [current]);

  useEventListener({
    type: 'scroll',
    element,
    listener: () => setHasScrolled(isScrolled()),
    options: { passive: true },
  });

  const scrollToBottom = useCallback(() => {
    if (current) {
      const { scrollHeight, clientHeight } = current;
      current.scrollTop = scrollHeight - clientHeight;
    }
  }, [current]);

  // Don't use useCallback(), because it will not update the element
  const stayScrolled = () => {
    if (current && !hasScrolled) scrollToBottom();
  };

  return { stayScrolled, scrollToBottom, hasScrolled };
};
