import { ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { Gutter, Icon, Button, ButtonProps } from 'components/ui/general';
import { CardSelectors } from 'consts/cypress';
import { Names } from 'types/icon';

import styles from './AlertCard.module.scss';

export type AlertCardProps = {
  title?: ReactNode;
  message?: ReactNode;
  type?: 'info' | 'success' | 'error' | 'warning' | 'light' | 'dark';
  button?: ButtonProps | null;
  className?: string;
};

export const AlertCard = ({
  title,
  message,
  type = 'light',
  button,
  className,
}: AlertCardProps) => {
  const icons = useMemo<{
    [key in NonNullable<AlertCardProps['type']>]: Names | undefined;
  }>(
    () => ({
      info: 'info-circle',
      success: 'check-circle',
      warning: 'exclamation-triangle',
      error: 'exclamation-circle',
      light: undefined,
      dark: undefined,
    }),
    []
  );

  return (
    <div
      className={classNames(styles.root, className, {
        [styles[type]]: true,
      })}
      data-cy={CardSelectors.Root}
    >
      {!!icons[type] && (
        <div className={styles.iconHolder}>
          <Icon name={icons[type] as Names} font="kyoto" />
        </div>
      )}
      <div className={styles.titleAndMessage}>
        <Gutter gutter={{ bottom: 1 }}>
          {title && (
            <Gutter.Item>
              <h6 className={styles.title}>{title}</h6>
            </Gutter.Item>
          )}
          {message && (
            <Gutter.Item>
              <p className={styles.message}>{message}</p>
            </Gutter.Item>
          )}
        </Gutter>
      </div>
      {!!button && (
        <Button
          className={classNames(styles.button, button?.children)}
          aria-label="Button right"
          naked
          stripPadding
          {...button}
        >
          {button.children || (
            <span className={styles.buttonDefaultChildren}>
              <Icon name="times" font="kyoto" />
            </span>
          )}
        </Button>
      )}
    </div>
  );
};
