import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  ...shared.restaurantVerticalCategory,
  ...shared.region,
  heading: {
    id: 'BroadcastSelectionResourcesModal.heading',
    description: 'Broadcast select resources modal heading',
    defaultMessage: 'Broadcast - Urval',
  },
  filterResourceCategoryNamesLabel: {
    id: 'BroadcastSelectionResourcesModal.filterResourceCategoryNamesLabel',
    description: 'Filter resource category label',
    defaultMessage: 'Yrke',
  },
  filterRegionLabel: {
    id: 'BroadcastSelectionResourcesModal.filterRegionLabel',
    description: 'Filter region label',
    defaultMessage: 'Region',
  },
  filterResourceCategoryLevelsLabel: {
    id: 'BroadcastSelectionResourcesModal.filterResourceCategoryLevelsLabel',
    description: 'Filter resource category levels label',
    defaultMessage: 'Nivå',
  },
  buttonWriteMessage: {
    id: 'BroadcastSelectionResourcesModal.buttonWriteMessage',
    description: 'Button write message',
    defaultMessage: 'Skriv till {amount} st',
  },
  buttonSelectAll: {
    id: 'BroadcastSelectionResourcesModal.buttonSelectAll',
    description: 'Button select all',
    defaultMessage: 'Välj alla',
  },
});
