import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  ...shared.notificationType,
  getWorkShiftSlotError: {
    id: 'NotificationsTable.getWorkShiftSlotError',
    description: 'Get work shift slot error',
    defaultMessage: 'Kunde inte navigera till arbetspass',
  },
  redirectError: {
    id: 'NotificationsTable.redirectError',
    description: 'Redirect error',
    defaultMessage: 'Kunde inte navigera',
  },
});
