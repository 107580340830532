import { useMemo, useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { ChannelOrigin, useChatGroupsLazyQuery } from 'api/graphql';
import {
  BroadcastRespondentsModal,
  BroadcastReceiversModal,
  CloseBroadcastModal,
  BroadcastViewModal,
} from 'components/ui/chat';
import { Empty, Tag } from 'components/ui/general';
import { Table, TableFetchData } from 'components/ui/table';
import { DateFormat } from 'consts/date';
import { format, table } from 'utils';

import { texts } from './BroadcastsTable.text';

import styles from './BroadcastsTable.module.scss';

export const BroadcastsTable = () => {
  const [fetchChatGroups, { data, loading, error }] = useChatGroupsLazyQuery();

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnTitle} />,
        accessor: 'name',
      },
      {
        Header: <FormattedMessage {...texts.table_columnCreatedAt} />,
        accessor: 'createdAt',
        Cell: ({
          row: {
            original: { createdAt },
          },
        }: any) =>
          format.date({ date: createdAt }).format(DateFormat.DateStamp),
      },
      {
        Header: <FormattedMessage {...texts.table_columnStatus} />,
        accessor: 'closed',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { closed },
          },
        }: any) => (
          <Tag size="sm" color={closed ? 'error' : 'success'}>
            <FormattedMessage {...texts[closed ? 'closed' : 'active']} />
          </Tag>
        ),
      },
      {
        Header: <FormattedMessage {...texts.table_columnReceiver} />,
        accessor: 'receivers',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { receivers, id },
          },
        }: any) => (
          <BroadcastReceiversModal receivers={receivers} chatGroupId={id} />
        ),
      },
      {
        Header: <FormattedMessage {...texts.table_columnRespondent} />,
        accessor: 'responses',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { responses, id },
          },
        }: any) => (
          <BroadcastRespondentsModal respondents={responses} chatGroupId={id} />
        ),
      },
      {
        accessor: 'buttons',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { id, closed, name, messages },
          },
        }: any) => (
          <div className={styles.buttons}>
            {!closed && (
              <div className={styles.button}>
                <CloseBroadcastModal chatGroupId={id} />
              </div>
            )}
            <div className={styles.button}>
              <BroadcastViewModal title={name} message={messages[0]} />
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: TableFetchData) => {
      const defaultSorting = [
        {
          field: 'createdAt',
          direction: 'desc',
        },
      ];

      fetchChatGroups({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            origin: ChannelOrigin.Broadcast,
          },
          sorting: {
            sorting: sortBy.length
              ? sortBy.map(({ id, desc }) => ({
                  field: id,
                  direction: desc ? 'desc' : 'asc',
                }))
              : defaultSorting,
          },
        },
      });
    },
    [fetchChatGroups]
  );

  return (
    <Table
      pagination
      sort
      lastTdRight
      totalPages={table.getTotalPages(data?.chatGroups.meta)}
      columns={columns}
      data={data?.chatGroups.edges || []}
      onFetchData={fetchData}
      loading={loading}
      empty={
        <Empty
          title={<FormattedMessage {...texts.table_emptyTable} />}
          message={error?.message}
        />
      }
    />
  );
};
