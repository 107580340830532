import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { User } from 'api/graphql';
import {
  addWorkShiftSlotsResources,
  selectInviteToAllSlots,
  selectInviteToAnyOfSlots,
} from 'redux/workShiftResources';

export const useWorkShift = () => {
  const dispatch = useDispatch();
  const selectorInviteToAnyOfSlots = useSelector(selectInviteToAnyOfSlots);
  const selectorInviteToAllSlots = useSelector(selectInviteToAllSlots);

  const addWorkShiftSlotResource = useCallback(
    (resources: User[]) => {
      // Single work shift slot is selected
      if (typeof selectorInviteToAnyOfSlots === 'string') {
        dispatch(
          addWorkShiftSlotsResources([
            {
              slotId: selectorInviteToAnyOfSlots,
              resources,
            },
          ])
        );
        return;
      }

      // "Något utav passen" is selected
      if (
        Array.isArray(selectorInviteToAnyOfSlots) &&
        selectorInviteToAnyOfSlots
      ) {
        resources.forEach((resource) => {
          const availableForSlots =
            resource.availableForSlots || selectorInviteToAnyOfSlots;
          availableForSlots.forEach((slotId) => {
            dispatch(
              addWorkShiftSlotsResources([
                {
                  slotId,
                  resources: [resource],
                },
              ])
            );
          });
        });
        return;
      }

      // "Alla pass" is selected
      if (selectorInviteToAllSlots?.length) {
        selectorInviteToAllSlots.forEach((slotId) => {
          dispatch(
            addWorkShiftSlotsResources([
              {
                slotId,
                resources,
              },
            ])
          );
        });
      }
    },
    [dispatch, selectorInviteToAllSlots, selectorInviteToAnyOfSlots]
  );

  return { addWorkShiftSlotResource };
};
