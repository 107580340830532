import { useMemo } from 'react';

import { CustomMessagesQuery, Scalars } from 'api/graphql';
import { Icon } from 'components/ui/general';

import styles from '../Message.module.scss';

type ReadReceiptProps = {
  createdAt: Scalars['Time'];
  participants: CustomMessagesQuery['messages']['edges'][0]['channel']['participants'];
  userId?: Scalars['ID'];
};

export const ReadReceipt = ({
  createdAt,
  participants,
  userId,
}: ReadReceiptProps) => {
  const isUnread = useMemo(() => {
    const lastReadAt = participants?.find(
      ({ user: participant }) => participant.id !== userId
    )?.lastReadAt;

    if (!lastReadAt) {
      return true; // lastReadAt null or undefined means no one read the message
    }

    const timeDifference =
      new Date(lastReadAt).getTime() - new Date(createdAt).getTime();

    return timeDifference < 0;
  }, [createdAt, participants, userId]);

  return (
    <div className={styles.readReceipt}>
      {isUnread ? (
        <Icon
          name="check-circle"
          font="ericeira"
          className={styles.unreadReceiptIcon}
        />
      ) : (
        <Icon name="check-circle" font="lissabon" />
      )}
    </div>
  );
};
