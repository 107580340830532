import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Location, UserRole, useUpdateLocationMutation } from 'api/graphql';
import { ActionModal, Button } from 'components/ui/general';
import { LocationForm } from 'components/ui/location';
import { EditLocationModalSelectors } from 'consts/cypress';
import { useAuth, useToast } from 'hooks';

import { texts } from './EditLocationModal.text';

type EditLocationModalProps = {
  location: Location;
  trigger?: ReactNode;
};

export const EditLocationModal = ({
  location,
  trigger,
}: EditLocationModalProps) => {
  const { role } = useAuth();
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();

  const [updateLocation, { error, loading }] = useUpdateLocationMutation();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const onSubmit = useCallback(
    async ({
      name,
      street,
      postalCode,
      city,
      contactUser,
      description,
      web,
      organisationNumber,
      billingStreet,
      billingPostalCode,
      billingCity,
      billingReference,
      eInvoice,
      region,
      legacyCustomerId,
      latitude,
      longitude,
    }) => {
      const { data } = await updateLocation({
        variables: {
          id: location.id,
          input: {
            name,
            street,
            postalCode,
            city,
            userId: contactUser.value,
            description,
            web,
            organisationNumber:
              role === UserRole.Admin ? organisationNumber : undefined,
            billingStreet,
            billingPostalCode,
            billingCity,
            billingReference,
            eInvoice,
            region: region.value,
            legacyCustomerId: legacyCustomerId || undefined,
            latitude: typeof latitude !== 'undefined' ? String(latitude) : '',
            longitude:
              typeof longitude !== 'undefined' ? String(longitude) : '',
          },
        },
      });

      if (data?.updateLocation) {
        setIsOpen(false);
        addToast({
          title: formatMessage(texts.editCompanyLocationSuccess),
          type: 'success',
        });
      }
    },
    [addToast, formatMessage, location.id, role, updateLocation]
  );

  useEffect(() => {
    if (!isOpen) reset();
  }, [reset, isOpen]);

  const { company, contactUser, region } = location;

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.button} />,
        loading,
        disabled: loading,
        type: 'submit',
        'data-cy': EditLocationModalSelectors.Button,
      }}
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      trigger={
        trigger || (
          <Button
            color="secondary"
            as="div"
            data-cy={EditLocationModalSelectors.Trigger}
            size="sm"
          >
            <FormattedMessage {...texts.trigger} />
          </Button>
        )
      }
    >
      <LocationForm
        {...{ register, control, errors, company, setValue }}
        defaultValues={{
          ...location,
          contactUser: {
            label: `${contactUser.firstName} ${contactUser.lastName}`,
            value: contactUser.id,
          },
          region: {
            label: formatMessage(texts[`region_${region}`]),
            value: region,
          },
        }}
        edit
      />
    </ActionModal>
  );
};
