import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { User, useUpdateUserMutation } from 'api/graphql';
import { Text } from 'components/ui/forms';
import { ActionModal, Button, Gutter } from 'components/ui/general';
import { UserForm } from 'components/ui/user';
import { EditUserModalSelectors } from 'consts/cypress';
import { useToast, useValidate } from 'hooks';

import { texts } from './EditUserModal.text';

type EditUserModalProps = {
  user: User;
  trigger?: ReactNode;
};

export const EditUserModal = ({ user, trigger }: EditUserModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const { isInt } = useValidate();
  const [updateUser, { error, loading }] = useUpdateUserMutation();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      if (user) {
        const response = await updateUser({
          variables: {
            id: user.id,
            input: {
              ...data,
              legacyId: data.legacyId || undefined,
              role: data.role.value,
            },
          },
        });

        if (response.data?.updateUser) {
          setIsOpen(false);
          addToast({
            title: formatMessage(texts.editUserSuccess),
            type: 'success',
          });
        }
      }
    },
    [updateUser, user, addToast, formatMessage]
  );

  useEffect(() => {
    if (!isOpen) reset();
  }, [reset, isOpen]);

  if (!user) {
    return null;
  }

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.button} />,
        type: 'submit',
        loading,
        disabled: loading,
        'data-cy': EditUserModalSelectors.Button,
      }}
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      trigger={
        trigger || (
          <Button
            color="secondary"
            as="div"
            data-cy={EditUserModalSelectors.Trigger}
            size="sm"
          >
            <FormattedMessage {...texts.trigger} />
          </Button>
        )
      }
    >
      <UserForm>
        <Gutter gutter={{ bottom: 2 }}>
          <Gutter.Item>
            <UserForm.Required
              {...{ register, control, errors }}
              defaultValues={{
                ...user,
                role: {
                  label: formatMessage(texts[`userRole_${user.role}`]),
                  value: user.role,
                },
              }}
            />
          </Gutter.Item>
          <Gutter.Item>
            <UserForm.MobileNumber
              {...{ register, errors }}
              defaultValues={{
                ...user,
                role: {
                  label: formatMessage(texts[`userRole_${user.role}`]),
                  value: user.role,
                },
              }}
            />
          </Gutter.Item>
          <Gutter.Item>
            <Text
              name="legacyId"
              label={formatMessage(texts.form_labelLegacyId)}
              register={register}
              fullWidth
              validation={{
                validate: (value) => value?.length && isInt(value),
              }}
              error={errors?.legacyId}
              defaultValue={user.legacyId ? String(user.legacyId) : undefined}
            />
          </Gutter.Item>
        </Gutter>
      </UserForm>
    </ActionModal>
  );
};
