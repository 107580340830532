import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  updateSuccess: {
    id: 'SwitchLocationCompanyProfile.updateSuccess',
    description: 'Update success',
    defaultMessage: 'Sparat',
  },
  updateError: {
    id: 'SwitchLocationCompanyProfile.updateError',
    description: 'Update error',
    defaultMessage: 'Något gick fel',
  },
});
