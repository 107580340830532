import {
  ApolloClient,
  ApolloLink,
  concat,
  DefaultOptions,
  split,
} from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { ServerError } from '@apollo/client/link/utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Store } from '@reduxjs/toolkit';
import { createUploadLink } from 'apollo-upload-client';
import { catchError, from, mergeMap, of } from 'rxjs';

import { cache } from 'api/cache';
import { getEnvironment } from 'api/environment';
import {
  getLastRefresh,
  getRefreshing,
  getRefreshToken,
  getToken,
  getUser,
  logoutStart,
  refreshSessionStart,
} from 'redux/auth';
import { sentry } from 'utils';

const apiEnvironment = getEnvironment();

const uploadHttpLink = createUploadLink({ uri: `${apiEnvironment.apiUrl}` });

const wsLink = (store: Store) =>
  new WebSocketLink({
    uri: apiEnvironment.wssUrl || '',
    options: {
      reconnect: true,
      reconnectionAttempts: 5,
      lazy: true,
      connectionParams: () => {
        const token = getToken(store.getState());
        if (token) {
          return {
            Authorization: `jwt ${token}`,
          };
        }
        return {};
      },
    },
  });

// Inject the authentication token into the requests made by apollo
const authMiddleware = (store: Store) =>
  new ApolloLink((operation, forward) => {
    const token = getToken(store.getState());
    if (token && operation.operationName !== 'RefreshSession') {
      operation.setContext({
        headers: {
          authorization: `jwt ${token}`,
        },
      });
    } else {
      operation.setContext({
        headers: {},
      });
    }

    return forward(operation);
  });

// Utility function to check if the error is due to an expired token
function isUnauthorizedError(err: ErrorResponse): boolean {
  const { networkError } = err;
  return (networkError as ServerError)?.statusCode === 401;
}

// Delay promise
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function getNewToken(reduxStore: Store): Promise<string> {
  const state = reduxStore.getState();
  const token = getToken(state);
  const refreshToken = getRefreshToken(state);
  const refreshing = getRefreshing(state);
  const lastRefresh = getLastRefresh(state);
  const user = getUser(state);

  if (!refreshToken || !user) {
    return Promise.reject(new Error('No refresh token'));
  }

  if (!refreshing && lastRefresh != null && token != null) {
    const timeSinceLastRefresh = new Date().getTime() - lastRefresh;
    if (timeSinceLastRefresh < 20000) {
      return token;
    }
  }

  if (!refreshing && refreshToken != null) {
    reduxStore.dispatch({
      type: refreshSessionStart.type,
      payload: { refreshToken, userId: user.id },
    });
  }

  return delay(250).then(() => {
    return getNewToken(reduxStore);
  });
}

// Checks the incoming error from the Apollo client and if it's a 401 attempts
// to refresh the token
const errorHandler = (reduxStore: Store) => {
  // @ts-ignore
  return onError((err: ErrorResponse) => {
    const { networkError, operation, graphQLErrors, forward } = err;

    // Check if error is a token expired error
    if (isUnauthorizedError(err)) {
      // Attempt to get a new token
      return from(getNewToken(reduxStore)).pipe(
        mergeMap((newToken) => {
          // Update the headers with the new token
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `jwt ${newToken}`,
            },
          }));

          // Retry the request with the new token
          return forward(operation);
        }),
        catchError((_error) => {
          reduxStore.dispatch({ type: logoutStart.type });

          // Instead of throwing an error, handle it gracefully
          // This prevents the error from being forwarded
          return of(); // Return an empty observable to stop the error propagation
        })
      );
    }
    // Notify via sentry if something breaks
    else if (networkError?.message !== 'Network request failed') {
      sentry.withScope((scope) => {
        scope.setExtra('exception', err);
        scope.setExtra('operation', operation);
        scope.setExtra('graphQLErrors', graphQLErrors);

        const opName = operation.operationName
          ? operation.operationName
          : 'unknown operation';

        sentry.captureMessage(
          `Apollo error: ${opName}`,
          sentry.Severity.Warning
        );
      });
    }
  });
};

const retryLink = new RetryLink({
  attempts: { max: 2 },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

const splitLink = (store: Store) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(store),
    uploadHttpLink as unknown as ApolloLink
  );

// eslint-disable-next-line import/no-mutable-exports
export let apolloClient: ApolloClient<any>;

export const setLink = (reduxStore: Store) => {
  apolloClient.setLink(
    retryLink.concat(
      errorHandler(reduxStore).concat(
        concat(authMiddleware(reduxStore), splitLink(reduxStore))
      )
    )
  );
};

export const factory = (reduxStore: Store) => {
  apolloClient = new ApolloClient({
    cache,
    defaultOptions,
  });

  setLink(reduxStore);

  return apolloClient;
};
