import { ReactNode } from 'react';

import { Container, Information } from 'components/ui/general';

import styles from './Banner.module.scss';

export type BannerProps = {
  message: ReactNode;
  elementRight?: ReactNode;
};

export const Banner = ({ message, elementRight }: BannerProps) => {
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <Information status="error" size="lg" className={styles.message}>
            {message}
          </Information>
          {!!elementRight && (
            <div className={styles.elementRight}>{elementRight}</div>
          )}
        </div>
      </Container>
    </div>
  );
};
