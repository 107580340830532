// https://github.com/willnorris/imageproxy

export const imageProxy = ({
  options = [],
  url,
  filename,
}: {
  options?: string[];
  url?: string;
  filename?: string;
}) => {
  if (url) {
    const path = `${options.join(',')}/${url}`;
    const imageProxyHost = process.env.REACT_APP_IMAGE_PROXY_HOST;

    return `${imageProxyHost}/${path}`;
  } else if (filename) {
    const staticFilesHost = process.env.REACT_APP_STATIC_FILES_HOST;
    const staticFilesImagesBucket =
      process.env.REACT_APP_STATIC_FILES_IMAGES_BUCKET;
    const path = `${options.join(
      ','
    )}/${staticFilesHost}${staticFilesImagesBucket}/${filename}`;

    const imageProxyHost = process.env.REACT_APP_IMAGE_PROXY_HOST;
    return `${imageProxyHost}/${path}`;
  }

  return '';
};
