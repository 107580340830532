import { useState, useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Scalars,
  useCustomBroadcastChatGroupReceiversLazyQuery,
} from 'api/graphql';
import { BroadcastSendMessageModal } from 'components/ui/chat';
import { Button, Empty, Container } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { table } from 'utils';

import { texts } from './BroadcastReceiversModal.text';

type BroadcastReceiversModalProps = {
  receivers: number;
  chatGroupId: Scalars['ID'];
};

export const BroadcastReceiversModal = ({
  receivers,
  chatGroupId,
}: BroadcastReceiversModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBroadcastSendMessageModalOpen, setIsBroadcastSendMessageModalOpen] =
    useState(false);
  const [fetchReceivers, { data, loading, error }] =
    useCustomBroadcastChatGroupReceiversLazyQuery();

  const topBeside = useMemo(() => {
    return (
      <Button
        iconRight={{ name: 'arrow-right' }}
        onClick={() => {
          setIsOpen(false);
          setIsBroadcastSendMessageModalOpen(true);
        }}
      >
        <FormattedMessage {...texts.sendMessageToSameReceivers} />
      </Button>
    );
  }, []);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }: TableFetchData) => {
      if (!isOpen) return;

      fetchReceivers({
        variables: {
          id: chatGroupId,
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
          },
        },
      });
    },
    [chatGroupId, fetchReceivers, isOpen]
  );

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'firstName',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }: any) => `${firstName} ${lastName}`,
      },
    ],
    []
  );

  return (
    <>
      <Button size="xs" color="secondary" onClick={() => setIsOpen(true)}>
        {String(receivers)}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        middleSize="lg"
        middleStripPadding
      >
        <Container fullWidth vertical>
          <h5>
            <FormattedMessage {...texts.heading} />
          </h5>
        </Container>
        <Table
          pagination
          isInModalMiddle
          lastTdRight
          totalPages={table.getTotalPages(data?.chatGroupReceivers.meta)}
          columns={columns}
          data={data?.chatGroupReceivers.edges || []}
          onFetchData={fetchData}
          loading={loading}
          handleQueryParameters={false}
          topBeside={topBeside}
          empty={
            <Empty
              title={<FormattedMessage {...texts.table_emptyTable} />}
              message={error?.message}
            />
          }
        />
      </Modal>
      <BroadcastSendMessageModal
        isOpen={isBroadcastSendMessageModalOpen}
        setIsOpen={(broadcastSendMessageModalIsOpen) => {
          if (!broadcastSendMessageModalIsOpen) {
            setIsBroadcastSendMessageModalOpen(false);
            setIsOpen(true);
          }
        }}
        chatGroupId={chatGroupId}
      />
    </>
  );
};
