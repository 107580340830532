import { combineEpics, Epic } from 'redux-observable';

import { authEpics } from 'redux/auth';
import { notificationEpics } from 'redux/notification';
import { toastEpics } from 'redux/toast';

export const epics: Epic = combineEpics(
  authEpics,
  toastEpics,
  notificationEpics
);
