export enum QueryParameters {
  ChannelId = 'channelId',
  GroupId = 'groupId',
  WorkShiftId = 'workShiftId',
}

export enum DefaultFilterChannels {
  Limit = 10,
}

export enum DefaultFilterMessages {
  Limit = 20,
}
