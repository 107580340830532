import { ReactNode, useCallback, useMemo, MouseEventHandler } from 'react';

import classNames from 'classnames';

import { Link, LinkProps } from 'components/tools';
import { Icon, IconProps, Spinner } from 'components/ui/general';
import { Fonts } from 'types/icon';

import styles from './Button.module.scss';

export type ButtonProps = {
  children?: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  ghost?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'dark' | 'success' | 'info'; // color names from $all-colors
  target?: string;
  to?: LinkProps['to'];
  as?: keyof JSX.IntrinsicElements;
  fullWidth?: boolean;
  loading?: boolean;
  iconRight?: IconProps;
  iconLeft?: IconProps;
  rounded?: boolean;
  naked?: boolean;
  stripPadding?: boolean;
  viewOnly?: boolean;
  [key: string]: any;
};

export const Button = ({
  children,
  type,
  href,
  size = 'md',
  className,
  disabled,
  onClick,
  ghost,
  color = 'primary',
  target,
  to,
  as,
  fullWidth,
  loading,
  iconRight,
  iconLeft,
  rounded = true,
  naked,
  stripPadding,
  viewOnly,
  ...props
}: ButtonProps) => {
  const ElementType = useMemo<any>(() => {
    if (to || href) return Link;
    return as || 'button';
  }, [as, href, to]);

  const getType = useMemo(() => {
    if (type) {
      return type;
    }

    if (ElementType === 'button') {
      return 'button';
    }
  }, [ElementType, type]);

  const getIconFont = useCallback(
    ({ font }: { font?: Fonts }) => {
      if (font) {
        return font;
      }

      switch (size) {
        case 'sm':
        case 'md':
        case 'lg':
          return 'madrid';

        default:
          return 'madrid';
      }
    },
    [size]
  );

  const getSpinnerColor = useMemo(() => {
    if (naked || color === 'secondary') {
      return 'dark';
    }

    if (color === 'info') {
      return 'primary';
    }

    return 'light';
  }, [color, naked]);

  return (
    <ElementType
      {...props}
      type={getType}
      className={classNames(styles.root, className, {
        [styles.disabled]: disabled,
        [styles.ghost]: ghost,
        [styles[`${color}Color`]]: color,
        [styles[`${size}Size`]]: size,
        [styles.fullWidth]: fullWidth,
        [styles.loading]: loading,
        [styles.rounded]: rounded,
        [styles.hasChildren]: !!children,
        [styles.naked]: naked,
        [styles.stripPadding]: stripPadding,
        [styles.viewOnly]: viewOnly,
      })}
      to={!viewOnly ? to || href : undefined}
      disabled={disabled}
      onClick={onClick && !disabled && !viewOnly ? onClick : null}
      target={target && href ? target : null}
    >
      <span className={styles.content}>
        {!!iconLeft && (
          <div className={styles.iconLeft}>
            <Icon name={iconLeft.name} font={getIconFont(iconLeft)} />
          </div>
        )}
        {!!children && children}
        {!!iconRight && (
          <div className={styles.iconRight}>
            <Icon name={iconRight.name} font={getIconFont(iconRight)} />
          </div>
        )}
      </span>
      <div className={styles.spinner}>
        <Spinner visible={!!loading} size="sm" color={getSpinnerColor} />
      </div>
    </ElementType>
  );
};
