import { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { UserFilter, UserRole, useUsersLazyQuery } from 'api/graphql';
import { Button, Empty } from 'components/ui/general';
import { Table, TableFetchData } from 'components/ui/table';
import { CreateUserModal } from 'components/ui/user';
import { Paths } from 'consts/router';
import { table } from 'utils';

import { texts } from './UsersTable.text';

type UsersTableProps = {
  filter?: UserFilter;
};

export const UsersTable = ({ filter = {} }: UsersTableProps) => {
  const [fetchUsers, { data, loading, error }] = useUsersLazyQuery();
  const topBeside = useMemo(() => <CreateUserModal />, []);

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }: any) => `${firstName} ${lastName}`,
      },
      {
        Header: <FormattedMessage {...texts.table_columnMobileNumber} />,
        accessor: 'mobileNumber',
      },
      {
        Header: <FormattedMessage {...texts.table_columnEmail} />,
        accessor: 'email',
      },
      {
        Header: <FormattedMessage {...texts.table_columnRole} />,
        accessor: 'role',
        Cell: ({
          row: {
            original: { role },
          },
        }: any) => (
          <FormattedMessage {...texts[`userRole_${role as UserRole}`]} />
        ),
      },
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => (
          <Button
            iconRight={{ name: 'angle-right' }}
            naked
            stripPadding
            size="sm"
            to={Paths.User(id)}
            color="dark"
          />
        ),
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, searchTerm }: TableFetchData) => {
      fetchUsers({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            searchTerm,
            ...filter,
          },
          sorting: {
            sorting: sortBy.map(({ id, desc }) => ({
              field: id,
              direction: desc ? 'desc' : 'asc',
            })),
          },
        },
      });
    },
    [fetchUsers, filter]
  );

  return (
    <Table
      columns={columns}
      data={data?.users.edges || []}
      onFetchData={fetchData}
      pagination
      search
      loading={loading}
      totalPages={table.getTotalPages(data?.users.meta)}
      sort
      topBeside={topBeside}
      lastTdRight
      empty={
        <Empty
          title={<FormattedMessage {...texts.table_emptyTable} />}
          message={error?.message}
        />
      }
    />
  );
};
