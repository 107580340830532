import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useUserChangePasswordMutation } from 'api/graphql';
import { Text } from 'components/ui/forms';
import { ActionModal, Button, Gutter } from 'components/ui/general';
import { ChangePasswordModalSelectors } from 'consts/cypress';
import { useToast } from 'hooks';

import { texts } from './ChangePasswordModal.text';

type ChangePasswordModalProps = {
  trigger?: ReactNode;
};

export const ChangePasswordModal = ({ trigger }: ChangePasswordModalProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();
  const [changePassword, { error, loading }] = useUserChangePasswordMutation();
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = useCallback(
    async ({ oldPassword, newPassword }) => {
      const { data } = await changePassword({
        variables: {
          input: {
            oldPassword,
            newPassword,
          },
        },
      });

      if (data?.userChangePassword) {
        setIsOpen(false);
        addToast({
          title: formatMessage(texts.changePasswordSuccess),
          type: 'success',
        });
      }
    },
    [addToast, changePassword, formatMessage]
  );

  useEffect(() => {
    if (!isOpen) reset();
  }, [reset, isOpen]);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.button} />,
        type: 'submit',
        loading,
        disabled: loading,
        'data-cy': ChangePasswordModalSelectors.Button,
      }}
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      trigger={
        trigger || (
          <Button
            color="secondary"
            as="div"
            data-cy={ChangePasswordModalSelectors.Trigger}
            size="sm"
          >
            <FormattedMessage {...texts.trigger} />
          </Button>
        )
      }
    >
      <Gutter gutter={{ bottom: 2 }}>
        <Gutter.Item data-cy={ChangePasswordModalSelectors.OldPassword}>
          <Text
            type="password"
            name="oldPassword"
            label={formatMessage(texts.form_labelOldPassword)}
            register={register}
            error={errors?.oldPassword}
            fullWidth
            validation={{ required: true }}
          />
        </Gutter.Item>
        <Gutter.Item data-cy={ChangePasswordModalSelectors.NewPassword}>
          <Text
            type="password"
            name="newPassword"
            label={formatMessage(texts.form_labelNewPassword)}
            register={register}
            error={errors?.newPassword}
            fullWidth
            validation={{
              required: true,
              minLength: {
                value: 8,
                message: formatMessage(texts.newPasswordValidationLength),
              },
            }}
          />
        </Gutter.Item>
        <Gutter.Item data-cy={ChangePasswordModalSelectors.NewPasswordRepeat}>
          <Text
            type="password"
            name="newPasswordRepeat"
            label={formatMessage(texts.form_labelNewPasswordRepeat)}
            register={register}
            error={errors?.newPasswordRepeat}
            fullWidth
            validation={{
              required: true,
              minLength: {
                value: 8,
                message: formatMessage(texts.newPasswordValidationLength),
              },
              validate: (value) =>
                value === getValues().newPassword ||
                formatMessage(texts.newPasswordValidationMatch),
            }}
          />
        </Gutter.Item>
      </Gutter>
    </ActionModal>
  );
};
