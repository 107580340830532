import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormattedMessage } from 'react-intl';

import {
  CustomChatSubscribeDocument,
  useCustomMessagesQuery,
  CustomChatSubscribeSubscription,
  CustomChatSubscribeSubscriptionVariables,
  useSetMessagesAsReadMutation,
} from 'api/graphql';
import { EmptyInboxAndErrorIcon } from 'assets/icons';
import { SendMessage, Messages } from 'components/ui/chat';
import { Button, Empty, Spinner } from 'components/ui/general';
import { DefaultFilterMessages } from 'consts/chat';
import { useStayScrolled, useWindowSize } from 'hooks';
import { typeGuards } from 'utils';

import { texts } from './Feed.text';

import styles from './Feed.module.scss';

type FeedProps = {
  channelId: string | number;
  firstParticipantId?: string;
};

export const Feed = ({ channelId, firstParticipantId }: FeedProps) => {
  const rootRef = useRef(null);
  const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);
  const { stayScrolled, hasScrolled } = useStayScrolled({ element: rootRef });
  const { width, height } = useWindowSize();
  const [setMessagesAsRead] = useSetMessagesAsReadMutation();
  const { data, loading, error, subscribeToMore, fetchMore } =
    useCustomMessagesQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { filter: { channelId, limit: DefaultFilterMessages.Limit } },
      ...(firstParticipantId && { pollInterval: 2000 }),
    });
  const messages = useMemo(() => data?.messages, [data]);

  useEffect(() => {
    if (!hasScrolled && messages?.edges.length && !firstParticipantId) {
      setMessagesAsRead({ variables: { channelId } });
    }
  }, [hasScrolled, setMessagesAsRead, messages, channelId, firstParticipantId]);

  useLayoutEffect(() => {
    stayScrolled();
  }, [stayScrolled, messages, width, height]);

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      CustomChatSubscribeSubscription,
      CustomChatSubscribeSubscriptionVariables
    >({
      document: CustomChatSubscribeDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const message = typeGuards.message(subscriptionData.data.chatSubscribe);

        if (message && message.channel.id === channelId) {
          return {
            ...prev,
            messages: {
              ...prev.messages,
              edges: [
                message,
                ...prev.messages.edges.filter((m) => m.id !== message.id),
              ],
              meta: {
                ...prev.messages.meta,
                total: prev.messages.meta.total + 1, // Will not work if you can delete a message
              },
            },
          };
        }

        return prev;
      },
    });

    return () => unsubscribe?.();
  }, [channelId, subscribeToMore]);

  const onFetchMore = useCallback(async () => {
    if (messages) {
      setFetchMoreLoading(true);

      const fetchMoreData = await fetchMore({
        variables: {
          filter: {
            channelId,
            limit: DefaultFilterMessages.Limit,
            offset: messages.edges.length,
          },
        },
      });

      setFetchMoreLoading(fetchMoreData.loading);
    }
  }, [channelId, messages, fetchMore]);

  const renderContent = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Spinner
            visible
            text={<FormattedMessage {...texts.fetchMessagesLoading} />}
            color="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.error}>
          <Empty
            title={<FormattedMessage {...texts.fetchMessagesError} />}
            message={error.message}
          />
        </div>
      );
    }

    if (messages?.edges.length) {
      const { edges, meta } = messages;

      return (
        <>
          {edges.length < meta.total && (
            <div className={styles.fetchMore}>
              <Button
                color="secondary"
                loading={fetchMoreLoading}
                disabled={fetchMoreLoading}
                onClick={onFetchMore}
              >
                <FormattedMessage {...texts.fetchMore} />
              </Button>
            </div>
          )}
          <Messages messages={edges} firstParticipantId={firstParticipantId} />
        </>
      );
    }

    return (
      <div className={styles.empty}>
        <Empty
          title={<FormattedMessage {...texts.fetchMessagesEmpty} />}
          icon={<EmptyInboxAndErrorIcon />}
        />
      </div>
    );
  }, [
    loading,
    error,
    messages,
    fetchMoreLoading,
    onFetchMore,
    firstParticipantId,
  ]);

  return (
    <>
      <div ref={rootRef} className={styles.root}>
        {renderContent()}
      </div>
      {!firstParticipantId && (
        <SendMessage
          channelId={channelId}
          onHeightChange={() => stayScrolled()}
          disabled={loading}
        />
      )}
    </>
  );
};
