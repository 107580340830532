import { ReactNode, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';

import { Icon } from 'components/ui/general';
import { Durations, Easings } from 'consts/transition';
import { useTrapFocus } from 'hooks';

import styles from './Middle.module.scss';

export type MiddleProps = {
  children: ReactNode;
  onClose: () => void;
  size?: 'sm' | 'md' | 'lg';
  transparent?: boolean;
  stripPadding?: boolean;
  classNameType?: string;
  classNameClose?: string;
};

export const Middle = ({
  children,
  onClose,
  size = 'sm',
  transparent,
  stripPadding,
  classNameType,
  classNameClose,
}: MiddleProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const trapFocusRef = useTrapFocus({
    updateNodes: true,
  });

  const onMouseDownOverlay = useCallback(
    (event) => {
      if (!contentRef.current?.contains(event.target)) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(styles.root, classNameType, {
        [styles[`${size}Size`]]: size,
        [styles.transparent]: transparent,
        [styles.stripPadding]: stripPadding,
      })}
      onMouseDown={onMouseDownOverlay}
    >
      <div className={styles.body}>
        <motion.div
          key="middle"
          ref={trapFocusRef}
          className={styles.dialog}
          transition={{
            duration: Durations.Fast,
            ease: Easings.InOut,
          }}
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          exit={{ y: 50 }}
        >
          <div ref={contentRef} className={styles.content}>
            {children}
            <button
              type="button"
              className={classNames(styles.close, classNameClose)}
              onClick={onClose}
              aria-label="Close modal"
            >
              <Icon name="times" font="nuuk" />
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
