import { ChangeEvent, useCallback, useEffect } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Text } from 'components/ui/forms';
import { TableProps } from 'components/ui/table';
import { TableSelectors } from 'consts/cypress';
import { useTable } from 'hooks';

import { texts } from './Search.text';

import styles from './Search.module.scss';

type SearchProps = {
  classNameSearch?: string;
  onSearchChange: (searchTerm: string) => void;
  handleQueryParameters?: TableProps['handleQueryParameters'];
};

export const Search = ({
  classNameSearch,
  onSearchChange,
  handleQueryParameters,
}: SearchProps) => {
  const { formatMessage } = useIntl();
  const { register } = useForm();
  const { getSearchTermQueryParameter } = useTable();

  const handleOnChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onSearchChange(target.value);
    },
    [onSearchChange]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(handleOnChange, 250), [
    handleOnChange,
  ]);

  useEffect(() => {
    return () => debouncedOnChange.cancel();
  }, [debouncedOnChange]);

  return (
    <div
      className={classNames(styles.root, classNameSearch)}
      data-cy={TableSelectors.Search}
    >
      <Text
        name="searchTerm"
        placeholder={formatMessage(texts.inputPlaceholder)}
        register={register}
        fullWidth
        iconLeft={{ name: 'search' }}
        className={styles.input}
        onChange={debouncedOnChange}
        defaultValue={
          handleQueryParameters ? getSearchTermQueryParameter() : undefined
        }
      />
    </div>
  );
};
