import { ReactNode } from 'react';

import { Required, MobileNumber } from './subcomponents';

type UserFormProps = {
  children: ReactNode;
};

export const UserForm = ({ children }: UserFormProps) => {
  return <div>{children}</div>;
};

UserForm.Required = Required;
UserForm.MobileNumber = MobileNumber;
