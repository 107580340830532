import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { CustomMessagesQuery, Scalars } from 'api/graphql';
import { Gutter, Tooltip } from 'components/ui/general';
import { format } from 'utils';

import {
  MessageImage,
  MessageFile,
  MessageText,
  ReadReceipt,
} from './subcomponents';

import styles from './Message.module.scss';

type MessageProps = {
  message: CustomMessagesQuery['messages']['edges'][0];
  isMyLastMessage: boolean;
  myId?: Scalars['ID'];
};

export const Message = ({ message, isMyLastMessage, myId }: MessageProps) => {
  const { createdAt, createdBy, images, files, extra, channel } = message;

  const createdByMe = useMemo(
    () => createdBy?.id === myId,
    [createdBy?.id, myId]
  );

  const renderContent = useCallback(() => {
    if (images.length) {
      return (
        <Gutter gutter={{ bottom: 0.5 }}>
          {images.map((image) => (
            <Gutter.Item key={image.id}>
              <MessageImage image={image} />
            </Gutter.Item>
          ))}
        </Gutter>
      );
    }

    if (files.length) {
      return (
        <Gutter gutter={{ bottom: 0.5 }}>
          {files.map((file) => (
            <Gutter.Item key={file.id}>
              <MessageFile file={file} createdByMe={createdByMe} />
            </Gutter.Item>
          ))}
        </Gutter>
      );
    }

    return (
      <MessageText
        text={message.message}
        title={message.title}
        extra={extra}
        createdByMe={createdByMe}
      />
    );
  }, [images, files, message.message, message.title, extra, createdByMe]);

  return (
    <Tooltip
      tip={
        <>
          {!!createdBy && (
            <div>
              {createdBy.firstName} {createdBy.lastName}
            </div>
          )}
          {format.date({ date: createdAt }).calendar()}
        </>
      }
      trigger="hover"
      placement={createdByMe ? 'left' : 'right'}
      classNameTrigger={classNames(styles.root, {
        [styles.createdByMe]: createdByMe,
        [styles.isImages]: images.length > 0,
        [styles.isFiles]: files.length > 0,
      })}
    >
      {renderContent()}
      {isMyLastMessage && (
        <ReadReceipt
          createdAt={createdAt}
          participants={channel.participants}
          userId={myId}
        />
      )}
    </Tooltip>
  );
};
