import { useCallback } from 'react';

import classNames from 'classnames';
import { Moment } from 'moment';
import {
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
  ModifiersShape,
} from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Button } from 'components/ui/general';
import { DateFormat } from 'consts/date';
import { Durations } from 'consts/transition';

import styles from './DatePickerSingle.module.scss';

export type DatePickerSingleProps = {
  fullWidth?: boolean;
  className?: string;
} & DayPickerSingleDateControllerShape;

export const DatePickerSingle = ({
  hideKeyboardShortcutsPanel,
  numberOfMonths,
  transitionDuration,
  noBorder,
  fullWidth,
  renderNavNextButton,
  renderNavPrevButton,
  className,
  renderDayContents,
  ...props
}: DatePickerSingleProps) => {
  const wrapRenderDayContents = useCallback(
    (day: Moment, modifiers: ModifiersShape) => (
      <span className={styles.dayContent}>
        {renderDayContents?.(day, modifiers) ||
          day.format(DateFormat.DayNumber)}
      </span>
    ),
    [renderDayContents]
  );

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <DayPickerSingleDateController
        {...props}
        firstDayOfWeek={props.firstDayOfWeek || 1}
        hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel || true}
        numberOfMonths={typeof numberOfMonths === 'number' ? numberOfMonths : 2}
        transitionDuration={
          typeof transitionDuration === 'number'
            ? transitionDuration
            : Durations.Fast * 1000
        }
        noBorder={noBorder || true}
        daySize={58}
        renderNavNextButton={({ ariaLabel, ...data }) =>
          renderNavNextButton?.({ ariaLabel, ...data }) || (
            <Button
              {...data}
              aria-label={ariaLabel}
              className={styles.navNext}
              iconRight={{ name: 'angle-right', font: 'nuuk' }}
              naked
              size="sm"
              color="dark"
              rounded={false}
            />
          )
        }
        renderNavPrevButton={({ ariaLabel, ...data }) =>
          renderNavPrevButton?.({ ariaLabel, ...data }) || (
            <Button
              {...data}
              aria-label={ariaLabel}
              className={styles.navPrev}
              iconRight={{ name: 'angle-left', font: 'nuuk' }}
              naked
              size="sm"
              color="dark"
              rounded={false}
            />
          )
        }
        renderDayContents={wrapRenderDayContents}
      />
    </div>
  );
};
