import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  workShiftInstructions: {
    id: 'GroupFeed.workShiftInstructions',
    description: 'Work shift instructions',
    defaultMessage:
      'Skicka ett meddelande för att skapa en konversation med medlemmarna',
  },
  initChatGroupChannelLoading: {
    id: 'GroupFeed.initChatGroupChannelLoading',
    description: 'Init chat group channel loading',
    defaultMessage: 'Initierar konversationen',
  },
  initChatGroupChannelLoadingError: {
    id: 'GroupFeed.initChatGroupChannelLoadingError',
    description: 'Init chat group channel loading error',
    defaultMessage: 'Kunde inte initiera konversationen',
  },
});
