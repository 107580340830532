import { ReactNode } from 'react';

import classNames from 'classnames';

import { ContentCardLabel } from './subcomponents';

import styles from './ContentCard.module.scss';

type ContentCardProps = {
  children: ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  color?: 'nu' | 'kappa' | 'light' | 'warning'; // color names from $all-colors
};

export const ContentCard = ({
  size = 'md',
  children,
  className,
  color = 'nu',
}: ContentCardProps) => {
  return (
    <div
      className={classNames(styles.root, className, {
        [styles[`${size}Size`]]: size,
        [styles[`${color}Color`]]: color,
      })}
    >
      {children}
    </div>
  );
};

ContentCard.Label = ContentCardLabel;
