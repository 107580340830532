import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useChatSubscribeSubscription } from 'api/graphql';
import { QueryParameters } from 'consts/chat';
import { Paths } from 'consts/router';
import { useAuth, useDesktopNotification } from 'hooks';
import { removeTrailingSlash, typeGuards } from 'utils';

import { texts } from './UnreadMessagesListener.text';

export const UnreadMessagesListener = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuth();
  const { notify } = useDesktopNotification();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data } = useChatSubscribeSubscription({
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const message = typeGuards.message(data?.chatSubscribe);

    if (removeTrailingSlash(pathname) === Paths.Chat) {
      return;
    }

    if (message && message.createdBy?.id !== user?.id) {
      notify({
        title: formatMessage(texts.notificationTitle),
        body: message.message,
        onClick: () =>
          navigate({
            pathname: Paths.Chat,
            search: `${QueryParameters.ChannelId}=${message.channel.id}`,
          }),
      });
    }
  }, [
    data?.chatSubscribe,
    navigate,
    notify,
    user?.id,
    pathname,
    formatMessage,
  ]);

  return null;
};
