import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from '../ContentCard.module.scss';

type ContentCardLabelProps = {
  children: ReactNode;
  className?: string;
};

export const ContentCardLabel = ({
  children,
  className,
}: ContentCardLabelProps) => {
  return <h6 className={classNames(styles.label, className)}>{children}</h6>;
};
