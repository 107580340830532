import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  textareaPlaceholder: {
    id: 'SendMessage.textareaPlaceholder',
    description: 'Textarea placeholder',
    defaultMessage: 'Skriv ett meddelande...',
  },
  sendMessageError: {
    id: 'SendMessage.sendMessageError',
    description: 'Send message error',
    defaultMessage: 'Kunde inte skicka meddelandet',
  },
  sendMessageButton: {
    id: 'SendMessage.sendMessageButton',
    description: 'Send message button',
    defaultMessage: 'Skicka',
  },
});
