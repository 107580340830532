import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ofType, Epic, combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, map, catchError, takeUntil, mapTo } from 'rxjs/operators';

import { apolloClient } from 'api/apollo';
import {
  LoginEmailDocument,
  MutationLoginEmailArgs,
  MutationRefreshSessionArgs,
  RefreshSessionDocument,
  User,
} from 'api/graphql';
import { RootState } from 'redux/reducers';
import { setLocation } from 'redux/router';

type AuthAction = {
  type: string;
  payload?: any;
};

type AuthState = {
  token?: string | null;
  refreshToken?: string | null;
  lastRefresh: number | null;
  user?: User | null;
  loading: boolean;
  refreshing: boolean;
  error: boolean;
};

export type AuthRootState = {
  auth: AuthState;
};

// Selectors
export const getUser = ({ auth }: AuthRootState) => auth.user;
export const getRole = ({ auth }: AuthRootState) => auth.user?.role;
export const getToken = ({ auth }: AuthRootState) => auth.token;
export const getRefreshToken = ({ auth }: AuthRootState) => auth.refreshToken;
export const getError = ({ auth }: AuthRootState) => auth.error;
export const getLoggedIn = ({ auth }: AuthRootState) => !!auth.token;
export const getLoading = ({ auth }: AuthRootState) => auth.loading;
export const getRefreshing = ({ auth }: AuthRootState) => auth.refreshing;
export const getLastRefresh = ({ auth }: AuthRootState) => auth.lastRefresh;

// Reducers
const initialState: AuthState = {
  token: null,
  refreshToken: null,
  user: null,
  loading: false,
  refreshing: false,
  lastRefresh: null,
  error: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state, _action: PayloadAction<MutationLoginEmailArgs>) => {
      state.token = null;
      state.refreshToken = null;
      state.user = null;
      state.loading = true;
      state.error = false;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{ token: string; user: User; refreshToken: string }>
    ) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.refreshToken = action.payload.refreshToken;
      state.loading = false;
      state.error = false;
      state.lastRefresh = new Date().getTime();
    },
    loginError: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.user = null;
      state.loading = false;
      state.error = true;
    },
    refreshSessionStart: (
      state,
      _action: PayloadAction<MutationRefreshSessionArgs>
    ) => {
      state.refreshing = true;
    },
    refreshSessionSuccess: (
      state,
      action: PayloadAction<{ token: string; user: User; refreshToken: string }>
    ) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.refreshToken = action.payload.refreshToken;
      state.loading = false;
      state.refreshing = false;
      state.error = false;
      state.lastRefresh = new Date().getTime();
    },
    refreshSessionError: (state) => {
      state.token = null;
      state.user = null;
      state.loading = false;
      state.error = false;
      state.refreshToken = null;
      state.refreshing = false;
    },
    logoutStart: (state) => {
      state.loading = true;
      state.refreshToken = null;
    },
    logoutSuccess: (state) => {
      state.token = null;
      state.user = null;
      state.loading = false;
      state.error = false;
      state.refreshToken = null;
      state.refreshing = false;
      state.lastRefresh = null;
    },
    setUser: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    },
  },
});

export default authSlice.reducer;

// Actions
export const {
  loginStart,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  refreshSessionStart,
  refreshSessionSuccess,
  refreshSessionError,
  setUser,
  setError,
  setToken,
} = authSlice.actions;

// Epics
const loginEpic: Epic<AuthAction, AuthAction, RootState> = (action$) =>
  action$.pipe(
    ofType(loginStart.type),
    switchMap(({ payload: { email, password } }) =>
      from(
        apolloClient.mutate({
          mutation: LoginEmailDocument,
          variables: { email, password },
        })
      ).pipe(
        map(({ data }) => {
          const { success, jwt, refreshToken, user } = data.loginEmail;

          if (success) {
            return loginSuccess({
              token: jwt,
              refreshToken,
              user,
            });
          }

          return loginError();
        }),
        takeUntil(action$.pipe(ofType(setLocation.type))),
        catchError(() => of(loginError()))
      )
    )
  );

const refreshSessionEpic: Epic<AuthAction, AuthAction, RootState> = (action$) =>
  action$.pipe(
    ofType(refreshSessionStart.type),
    switchMap(({ payload: { refreshToken, userId } }) =>
      from(
        apolloClient.mutate({
          mutation: RefreshSessionDocument,
          variables: { refreshToken, userId },
        })
      ).pipe(
        map(({ data }) => {
          const {
            success,
            jwt,
            refreshToken: newRefreshToken,
            user,
          } = data.refreshSession;

          if (success) {
            return refreshSessionSuccess({
              token: jwt,
              refreshToken: newRefreshToken,
              user,
            });
          }

          return refreshSessionError();
        }),
        catchError(() => {
          return of(refreshSessionError());
        })
      )
    )
  );

const logoutEpic: Epic<AuthAction, AuthAction, RootState> = (action$) =>
  action$.pipe(ofType(logoutStart.type), mapTo(logoutSuccess()));

export const authEpics = combineEpics(
  loginEpic,
  refreshSessionEpic,
  logoutEpic
);
