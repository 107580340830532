import { useCallback, useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  UpdateLocationInput,
  useUpdateLocationImagesMutation,
  Location,
} from 'api/graphql';
import { Avatar } from 'components/ui/general';
import { useToast } from 'hooks';

import { texts } from './LocationAvatar.text';

export type LocationAvatarProps = {
  location: Location;
  fileAvailable?: boolean;
};

export const LocationAvatar = ({
  location,
  fileAvailable = true,
}: LocationAvatarProps) => {
  const { formatMessage } = useIntl();
  const fileRef = useRef<HTMLInputElement | null>(null);
  const { register, setValue } = useForm();
  const { addToast } = useToast();
  const [updateLocationImages, { error, loading }] =
    useUpdateLocationImagesMutation();

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.updateLocationImagesError),
        message: error.message,
        type: 'error',
      });
    }
  }, [error, addToast, formatMessage]);

  const updateLogo = useCallback(
    async (logo?: UpdateLocationInput['logo']) => {
      if (location) {
        const { id, image } = location;
        const { data } = await updateLocationImages({
          variables: {
            id,
            input: {
              logo: logo
                ? {
                    image: logo,
                  }
                : null,
              image: image
                ? {
                    id: image.id,
                  }
                : null,
            },
          },
        });

        if (data?.updateLocationImages) {
          if (!logo) {
            setValue('logo', undefined);
          }

          addToast({
            title: formatMessage(texts.updateLocationImagesSuccess),
            type: 'success',
          });
        }
      }
    },
    [updateLocationImages, location, addToast, formatMessage, setValue]
  );

  const onChange = useCallback(
    async ({ target }) => {
      if (target.files?.[0]) {
        await updateLogo(target.files[0]);
      }
    },
    [updateLogo]
  );

  return (
    <Avatar
      image={location.logo}
      imageOptions={['96x']}
      rounded
      aspectRatio="1:1"
      width="96px"
      loading={loading}
      file={
        fileAvailable
          ? {
              name: 'logo',
              register,
              onChange,
              accept: 'image/jpg, image/jpeg, image/png',
              fileRef,
              disabled: loading,
              menu: location.logo
                ? {
                    children: null,
                    items: [
                      {
                        label: <FormattedMessage {...texts.menuUpdateLogo} />,
                        onClick: () => fileRef.current?.click(),
                      },
                      {
                        label: <FormattedMessage {...texts.menuDeleteLogo} />,
                        onClick: async () => {
                          await updateLogo();
                        },
                      },
                    ],
                  }
                : undefined,
            }
          : undefined
      }
    />
  );
};
