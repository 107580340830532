import { useMemo, useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { ChannelOrigin, useChatGroupsLazyQuery } from 'api/graphql';
import { Button, Empty } from 'components/ui/general';
import { Table, TableFetchData } from 'components/ui/table';
import { QueryParameters } from 'consts/chat';
import { DateFormat } from 'consts/date';
import { Paths } from 'consts/router';
import { format, table } from 'utils';

import { texts } from './GroupsTable.text';

export const GroupsTable = () => {
  const [fetchChatGroups, { data, loading, error }] = useChatGroupsLazyQuery();

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'name',
      },
      {
        Header: <FormattedMessage {...texts.table_columnCreatedAt} />,
        accessor: 'createdAt',
        Cell: ({
          row: {
            original: { createdAt },
          },
        }: any) =>
          format
            .date({ date: createdAt })
            .format(DateFormat.DateHourMinuteSeconds),
      },
      {
        Header: <FormattedMessage {...texts.table_columnUpdatedAt} />,
        accessor: 'updatedAt',
        Cell: ({
          row: {
            original: { updatedAt },
          },
        }: any) =>
          format
            .date({ date: updatedAt })
            .format(DateFormat.DateHourMinuteSeconds),
      },
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => (
          <Button
            size="sm"
            color="secondary"
            to={{
              pathname: Paths.Chat,
              search: `${QueryParameters.GroupId}=${id}`,
            }}
          >
            <FormattedMessage {...texts.sendMessage} />
          </Button>
        ),
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, searchTerm }: TableFetchData) => {
      const defaultSorting = [
        {
          field: 'name',
          direction: 'asc',
        },
      ];

      fetchChatGroups({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            searchTerm,
            origin: ChannelOrigin.Group,
          },
          sorting: {
            sorting: sortBy.length
              ? sortBy.map(({ id, desc }) => ({
                  field: id,
                  direction: desc ? 'desc' : 'asc',
                }))
              : defaultSorting,
          },
        },
      });
    },
    [fetchChatGroups]
  );

  return (
    <Table
      search
      pagination
      sort
      lastTdRight
      totalPages={table.getTotalPages(data?.chatGroups.meta)}
      columns={columns}
      data={data?.chatGroups.edges || []}
      onFetchData={fetchData}
      loading={loading}
      empty={
        <Empty
          title={<FormattedMessage {...texts.table_emptyTable} />}
          message={error?.message}
        />
      }
    />
  );
};
