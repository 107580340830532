export enum DateFormat {
  Date = 'Do MMMM YYYY',
  DateHourMinuteSeconds = 'Do MMMM YYYY, HH:mm:ss',
  DateHourMinutes = 'Do MMMM YYYY, HH:mm',
  DateStamp = 'YYYY-MM-DD',
  HourMinutes = 'HH:mm',
  HourMinuteSeconds = 'HH:mm:ss',
  Milliseconds = 'x',
  DayNumber = 'D',
  Full = 'YYYY-MM-DDTHH:mm:ssZ',
}
