import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'DeleteUserModal.heading',
    description: 'Heading',
    defaultMessage: 'Ta bort användare',
  },
  paragraph: {
    id: 'DeleteUserModal.paragraph',
    description: 'Paragraph',
    defaultMessage:
      'Är du säker på att du vill ta bort denna användare? Denna ändring går inte att ångra. Om du är osäker tryck på avbryt.',
  },
  button: {
    id: 'DeleteUserModal.button',
    description: 'Button',
    defaultMessage: 'Ta bort',
  },
  tooltip: {
    id: 'DeleteUserModal.tooltip',
    description: 'Tooltip',
    defaultMessage: 'Ta bort användare',
  },
  deleteUserSuccess: {
    id: 'DeleteUserModal.deleteUserSuccess',
    description: 'Delete user success',
    defaultMessage: 'Användaren är borttagen',
  },
});
