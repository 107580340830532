import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Information, InformationProps } from 'components/ui/general';
import { ErrorSelectors } from 'consts/cypress';

import { texts } from './Error.text';

import styles from './Error.module.scss';

type ErrorProps = {
  error?: FieldErrors;
  className?: string;
  size?: InformationProps['size'];
};

export const Error = ({ error, className, size }: ErrorProps) => {
  if (error) {
    return (
      <div
        className={classNames(styles.root, className)}
        data-cy={ErrorSelectors.Root}
      >
        <Information status="error" size={size}>
          {error?.message || <FormattedMessage {...texts.message} />}
        </Information>
      </div>
    );
  }

  return null;
};
