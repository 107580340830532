import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CreateChannelInput, useCreateChannelMutation } from 'api/graphql';
import { QueryParameters } from 'consts/chat';
import { Paths } from 'consts/router';
import { useToast } from 'hooks';

import { texts } from './useCreateChannel.text';

export const useCreateChannel = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const [createChannelMutation, { data, loading, error }] =
    useCreateChannelMutation();
  const channel = useMemo(() => data?.createChannel, [data]);

  const createChannel = useCallback(
    async (input: CreateChannelInput) => {
      const response = await createChannelMutation({
        variables: { input },
      });

      if (response.data?.createChannel) {
        navigate({
          pathname: Paths.Chat,
          search: `${QueryParameters.ChannelId}=${response.data.createChannel.id}`,
        });
      }

      if (response.errors?.length) {
        addToast({
          title: formatMessage(texts.createChannelError),
          message: response.errors[0].message,
          type: 'error',
        });
      }

      return response;
    },
    [createChannelMutation, navigate, addToast, formatMessage]
  );

  return {
    createChannel,
    channel,
    loading,
    error,
  };
};
