import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.form,
  heading: {
    id: 'ChangePasswordModal.heading',
    description: 'Heading',
    defaultMessage: 'Byt lösenord',
  },
  button: {
    id: 'ChangePasswordModal.button',
    description: 'Button',
    defaultMessage: 'Spara',
  },
  trigger: {
    id: 'ChangePasswordModal.trigger',
    description: 'Trigger',
    defaultMessage: 'Byt lösenord',
  },
  newPasswordValidationLength: {
    id: 'ChangePasswordModal.newPasswordValidationLength',
    description: 'New password validation message',
    defaultMessage: 'Måste vara minst 8 tecken',
  },
  newPasswordValidationMatch: {
    id: 'ChangePasswordModal.newPasswordValidationMatch',
    description: 'New password validate matching passwords message',
    defaultMessage: 'Lösenorden måste matcha',
  },
  changePasswordSuccess: {
    id: 'ChangePasswordModal.changePasswordSuccess',
    description: 'Change password success',
    defaultMessage: 'Lösenordet är bytt',
  },
});
