import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types
type BookingsState = {
  locationIds: (string | number)[];
  dateInMilliseconds: string | null;
  workShiftIdAndWorkShiftSlotIdAndLocationId: {
    workShiftId: string | number;
    workShiftSlotId: string | number;
    locationId: string | number;
  } | null;
};

export type BookingsRootState = {
  bookings: BookingsState;
};

// Selectors
export const selectBookings = ({ bookings }: BookingsRootState) => bookings;
export const selectLocationIds = ({ bookings }: BookingsRootState) =>
  bookings.locationIds;
export const selectDateInMilliseconds = ({ bookings }: BookingsRootState) =>
  bookings.dateInMilliseconds;
export const selectWorkShiftIdAndWorkShiftSlotIdAndLocationId = ({
  bookings,
}: BookingsRootState) => bookings.workShiftIdAndWorkShiftSlotIdAndLocationId;

// Reducers
const initialState: BookingsState = {
  locationIds: [],
  dateInMilliseconds: null,
  workShiftIdAndWorkShiftSlotIdAndLocationId: null,
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setLocationIds: (
      state,
      action: PayloadAction<BookingsState['locationIds']>
    ) => {
      state.locationIds = action.payload;
    },
    setDateInMilliseconds: (
      state,
      action: PayloadAction<BookingsState['dateInMilliseconds']>
    ) => {
      state.dateInMilliseconds = action.payload;
    },
    setWorkShiftIdAndWorkShiftSlotIdAndLocationId: (
      state,
      action: PayloadAction<
        BookingsState['workShiftIdAndWorkShiftSlotIdAndLocationId']
      >
    ) => {
      state.workShiftIdAndWorkShiftSlotIdAndLocationId = action.payload;
    },
    setInitialState: () => initialState,
  },
});

export default bookingsSlice.reducer;

// Actions
export const {
  setLocationIds,
  setDateInMilliseconds,
  setWorkShiftIdAndWorkShiftSlotIdAndLocationId,
  setInitialState,
} = bookingsSlice.actions;
