import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  sendMessage: {
    id: 'GroupsTable.sendMessage',
    description: 'Send message',
    defaultMessage: 'Skicka meddelande',
  },
});
