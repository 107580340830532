import { ReactNode, useCallback, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useDeleteUserMutation, User, UserCollection } from 'api/graphql';
import { Button, Tooltip, ActionModal } from 'components/ui/general';
import { DeleteUserModalSelectors } from 'consts/cypress';
import { useToast } from 'hooks';

import { texts } from './DeleteUserModal.text';

type DeleteUserModalProps = {
  user: User;
  onDeleted?: Function;
  trigger?: ReactNode;
};

export const DeleteUserModal = ({
  user,
  onDeleted,
  trigger,
}: DeleteUserModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const [deleteUser, { error, loading }] = useDeleteUserMutation({
    update(cache, { data }) {
      if (!data) return;

      cache.modify({
        fields: {
          users(existingUsers: UserCollection, { readField }) {
            return {
              ...existingUsers,
              edges: existingUsers.edges.filter(
                (existingUser) => user.id !== readField('id', existingUser)
              ),
              meta: {
                ...existingUsers.meta,
                total: existingUsers.meta.total - 1,
              },
            } as UserCollection;
          },
        },
      });
    },
  });

  const onClick = useCallback(async () => {
    if (user) {
      const { data } = await deleteUser({
        variables: {
          id: user.id,
        },
      });

      if (data?.deleteUser) {
        setIsOpen(false);
        addToast({
          title: formatMessage(texts.deleteUserSuccess),
          type: 'success',
        });
        onDeleted?.();
      }
    }
  }, [addToast, deleteUser, formatMessage, onDeleted, user]);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.button} />,
        onClick,
        loading,
        disabled: loading,
        color: 'error',
        'data-cy': DeleteUserModalSelectors.Button,
      }}
      trigger={
        trigger || (
          <Tooltip
            tip={<FormattedMessage {...texts.tooltip} />}
            trigger="hover"
          >
            <Button
              color="secondary"
              iconRight={{ name: 'trash-alt' }}
              as="div"
              data-cy={DeleteUserModalSelectors.Trigger}
              size="sm"
            />
          </Tooltip>
        )
      }
    >
      <p>
        <FormattedMessage {...texts.paragraph} />
      </p>
    </ActionModal>
  );
};
