import { useCallback, useMemo, useRef } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ChannelOrigin, CustomChannelsQuery, UserRole } from 'api/graphql';
import { Image } from 'components/ui/general';
import { QueryParameters } from 'consts/chat';
import { Paths } from 'consts/router';
import { useAuth, useUrlQuery } from 'hooks';
import { format, imageProxy } from 'utils';

import { texts } from './Channel.text';

import styles from './Channel.module.scss';

type ChannelProps = {
  channel: CustomChannelsQuery['channels']['edges'][0];
  isOneToOneFilter?: boolean;
};

export const Channel = ({
  channel,
  isOneToOneFilter = false,
}: ChannelProps) => {
  const { id, name, participants, lastSentAt, origin, messages } = channel;
  const auth = useAuth();
  const { get } = useUrlQuery();
  const navigate = useNavigate();
  const channelId = useMemo(() => get(QueryParameters.ChannelId), [get]);

  const meAsParticipant = useMemo(
    () => participants?.find(({ user }) => user.id === auth.user?.id),
    [auth.user?.id, participants]
  );

  const otherParticipants = useMemo(
    () => participants?.filter(({ user }) => user.id !== auth.user?.id),
    [auth.user, participants]
  );

  const renderThumbnail = useCallback(() => {
    switch (origin) {
      case ChannelOrigin.OneToOne: {
        const { image } = otherParticipants?.[0]?.user || {};

        return (
          <div className={styles.thumbnail}>
            {!!image && (
              <Image
                src={imageProxy({ url: image.uri, options: ['48x'] })}
                alt={image.originalName}
                spinner={false}
                fit="contain"
                cover
              />
            )}
          </div>
        );
      }

      case ChannelOrigin.Group:
      case ChannelOrigin.Broadcast:
      case ChannelOrigin.System: {
        const twoOtherParticipants:
          | ChannelProps['channel']['participants']
          | null[] = otherParticipants?.slice(0, 2) || [];

        if (twoOtherParticipants.length < 2) {
          for (let index = 0; index < 2; index += 1) {
            if (twoOtherParticipants.length < 2) {
              twoOtherParticipants.push(null as any);
            }
          }
        }

        return (
          <div className={styles.thumbnailGroup}>
            {twoOtherParticipants.map((participant, index) => {
              if (participant) {
                const { image } = participant.user;

                return (
                  <div className={styles.thumbnail} key={participant.user.id}>
                    {!!image && (
                      <Image
                        src={imageProxy({ url: image.uri, options: ['32x'] })}
                        alt={image.originalName}
                        spinner={false}
                        fit="contain"
                        cover
                      />
                    )}
                  </div>
                );
              }

              // eslint-disable-next-line react/no-array-index-key
              return <div className={styles.thumbnail} key={index} />;
            })}
          </div>
        );
      }

      default:
        return null;
    }
  }, [origin, otherParticipants]);

  const renderName = useCallback(() => {
    switch (origin) {
      case ChannelOrigin.OneToOne:
      case ChannelOrigin.Group:
      case ChannelOrigin.Broadcast:
      case ChannelOrigin.System:
        return name;

      default:
        return null;
    }
  }, [name, origin]);

  const renderLastMessage = useCallback(() => {
    const { message, files, images } = messages[0];

    if (message.trim().length !== 0) {
      return message;
    }

    if (files.length) {
      return <FormattedMessage {...texts.lastMessageFiles} />;
    }

    if (images.length) {
      return <FormattedMessage {...texts.lastMessageImages} />;
    }

    return null;
  }, [messages]);

  const firstParticipantIdRef = useRef<string | undefined>(
    messages[0]?.createdBy?.id.toString()
  );

  const renderOneToOneChannel = () => {
    if (!participants) {
      return null;
    }

    const renderNames = () => {
      if (ChannelOrigin.Group === origin) {
        const resourceUsers = participants.filter(
          ({ user: { role } }) => role !== UserRole.CompanyAdmin
        );

        return (
          <>
            <p className={styles.oneToOneName}>{name}</p>
            <p className={styles.oneToOneName}>
              {resourceUsers.map(
                (
                  { user: { firstName, lastName, id: participantId } },
                  index
                ) => (
                  <span key={participantId} className={styles.oneToOneName}>
                    {`${firstName} ${lastName}${
                      index !== resourceUsers.length - 1 ? ', ' : ''
                    }`}
                  </span>
                )
              )}
            </p>
          </>
        );
      }

      const orderedParticipants = participants
        ?.slice()
        .sort(({ user: { id: participantID } }) =>
          participantID === firstParticipantIdRef.current ? -1 : 1
        );

      return orderedParticipants?.map(
        ({ user: { firstName, lastName, id: participantId } }) => (
          <p key={participantId} className={styles.oneToOneName}>
            {`${firstName} ${lastName}`}
          </p>
        )
      );
    };

    return (
      <div className={styles.content}>
        {renderNames()}
        {!!messages?.length && !!lastSentAt && (
          <div className={styles.lastSentAt}>
            {format.date({ date: lastSentAt }).calendar()}
          </div>
        )}
      </div>
    );
  };

  const renderChannel = () => {
    return (
      <>
        {renderThumbnail()}
        <div className={styles.content}>
          <div className={styles.name}>{renderName()}</div>
          {!!messages?.length && !!lastSentAt && (
            <div className={styles.lastMessageAndLastSentAt}>
              <div className={styles.lastMessage}>{renderLastMessage()}</div>
              <div className={styles.lastSentAt}>
                {format.date({ date: lastSentAt }).calendar()}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const queryParameter = isOneToOneFilter
    ? `${QueryParameters.ChannelId}=${id}&${ChannelOrigin.OneToOne}=${firstParticipantIdRef.current}`
    : `${QueryParameters.ChannelId}=${id}`;

  return (
    <button
      type="button"
      className={classNames(styles.root, {
        [styles.active]: id === channelId,
        [styles.unreadMessages]:
          format
            .date({ date: meAsParticipant?.lastReadAt })
            .diff(format.date({ date: lastSentAt })) < 0 ||
          (!!lastSentAt && !meAsParticipant?.lastReadAt),
      })}
      onClick={() => {
        navigate({
          pathname: Paths.Chat,
          search: queryParameter,
        });
      }}
    >
      <div className={styles.inner}>
        {isOneToOneFilter ? renderOneToOneChannel() : renderChannel()}
      </div>
    </button>
  );
};
