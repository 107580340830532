import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'CloseBroadcastModal.heading',
    description: 'Heading',
    defaultMessage: 'Vill du stänga denna broadcast?',
  },
  buttonCloseTrigger: {
    id: 'CloseBroadcastModal.buttonCloseTrigger',
    description: 'Button close trigger',
    defaultMessage: 'Stäng',
  },
  buttonCloseBroadcast: {
    id: 'CloseBroadcastModal.buttonCloseBroadcast',
    description: 'Button close broadcast',
    defaultMessage: 'Stäng',
  },
  labelBroadcastMessage: {
    id: 'CloseBroadcastModal.labelBroadcastMessage',
    description: 'Label broadcast message text input',
    defaultMessage: 'Meddelande',
  },
  closeBroadcastSuccess: {
    id: 'CloseBroadcastModal.closeBroadcastSuccess',
    description: 'Close broadcast success',
    defaultMessage: 'Broadcast stängd',
  },
});
