import {
  ChangeEvent,
  useState,
  useCallback,
  MutableRefObject,
  useMemo,
  ReactNode,
} from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { Icon, Menu, MenuProps } from 'components/ui/general';
import { FileSelectors } from 'consts/cypress';

import styles from './File.module.scss';

export type FileProps = {
  name: string;
  accept?: string;
  capture?: 'user' | 'environment';
  multiple?: boolean;
  className?: string;
  classNameInner?: string;
  classNameLabel?: string;
  ariaLabel?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: FieldErrors;
  children?: ReactNode;
  showFileList?: boolean;
  menu?: MenuProps;
  fullWidth?: boolean;
  fileRef?: MutableRefObject<HTMLInputElement | null>;
};

export const File = ({
  name,
  accept,
  capture,
  multiple,
  className,
  classNameInner,
  classNameLabel,
  ariaLabel,
  disabled,
  onChange,
  onBlur,
  register,
  validation,
  error,
  children,
  showFileList,
  menu,
  fullWidth,
  fileRef,
}: FileProps) => {
  const [fileList, setFileList] = useState<string[]>([]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (onChange) onChange(event);

      if (files) {
        setFileList(Array.from(files).map((file) => file.name));
      }
    },
    [onChange]
  );

  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  return (
    <>
      <div
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
        })}
        data-cy={FileSelectors.Root}
      >
        <div className={classNames(styles.inner, classNameInner)}>
          <label className={classNames(styles.label, classNameLabel)}>
            <input
              type="file"
              accept={accept}
              capture={capture}
              multiple={multiple}
              disabled={disabled}
              aria-label={ariaLabel}
              className={styles.input}
              data-cy={FileSelectors.Input}
              {...registerHolder}
              ref={(event) => {
                if (fileRef) fileRef.current = event;
                registerHolder.ref(event);
              }}
              onChange={(event) => {
                registerHolder.onChange(event);
                handleOnChange(event);
              }}
              onBlur={(event) => {
                registerHolder.onBlur(event);
                onBlur?.(event);
              }}
            />
            {children}
          </label>
          {!!menu && (
            <Menu
              {...menu}
              className={classNames(styles.menu, menu.className)}
              classNameTrigger={classNames(
                styles.menuTrigger,
                menu.classNameTrigger
              )}
            >
              <Icon name="ellipsis-vertical" />
            </Menu>
          )}
        </div>
        {showFileList && (
          <ul>
            {fileList.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{file}</li>
            ))}
          </ul>
        )}
      </div>
      <Error error={error} />
    </>
  );
};
