import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.channelOrigin,
  fetchChannelsLoading: {
    id: 'Channels.fetchChannelsLoading',
    description: 'Fetch channels loading',
    defaultMessage: 'Hämtar konversationer',
  },
  fetchChannelsError: {
    id: 'Channels.fetchChannelsError',
    description: 'Fetch channels error',
    defaultMessage: 'Kunde inte hämta konversationer',
  },
  fetchChannelsEmpty: {
    id: 'Channels.fetchChannelsEmpty',
    description: 'Fetch channels empty',
    defaultMessage: 'Kunde inte hitta några konversationer',
  },
  filterResultsEmpty: {
    id: 'Channels.filterResultsEmpty',
    description: 'Filter results empty',
    defaultMessage: 'Hittade inga matchande resultat',
  },
  buttonChatGroups: {
    id: 'Channels.buttonChatGroups',
    description: 'Button chat groups',
    defaultMessage: 'Grupper',
  },
  buttonChatBroadcasts: {
    id: 'Channels.buttonChatBroadcasts',
    description: 'Button chat broadcast',
    defaultMessage: 'Broadcast',
  },
  searchTermPlaceholder: {
    id: 'Channels.searchTermPlaceholder',
    description: 'Search term placeholder',
    defaultMessage: 'Sök',
  },
  channelOriginPlaceholder: {
    id: 'Channels.channelOriginPlaceholder',
    description: 'Channel origin placeholder',
    defaultMessage: 'Välj typ',
  },
  topHeading: {
    id: 'Channels.topHeading',
    description: 'Top heading',
    defaultMessage: 'Meddelanden',
  },
});
