import { FormattedMessage } from 'react-intl';

import { RestaurantProfile } from 'api/graphql';
import { Grid, Gutter, Tag } from 'components/ui/general';

import { texts } from '../RestaurantProfileModal.text';

import styles from '../RestaurantProfileModal.module.scss';

type LanguagesSectionProps = {
  restaurantProfile: RestaurantProfile;
};

export const LanguagesSection = ({
  restaurantProfile,
}: LanguagesSectionProps) => {
  const { languages } = restaurantProfile;

  if (!languages?.length) {
    return null;
  }

  return (
    <Gutter gutter={{ bottom: 2 }}>
      <Gutter.Item>
        <h6 className={styles.sectionHeading}>
          <FormattedMessage {...texts.headingLanguages} />
        </h6>
      </Gutter.Item>
      <Gutter.Item>
        <Grid gutter={{ left: 1.5, bottom: 1.5 }}>
          {languages.map((language) => (
            <Grid.Item key={language}>
              <Tag color="info" size="lg">
                <FormattedMessage {...texts[`language_${language}`]} />
              </Tag>
            </Grid.Item>
          ))}
        </Grid>
      </Gutter.Item>
    </Gutter>
  );
};
