import { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Location, User, UserRole, useUsersLazyQuery } from 'api/graphql';
import { Empty } from 'components/ui/general';
import { SwitchLocationContactCompanyProfile } from 'components/ui/location';
import { Table, TableFetchData } from 'components/ui/table';
import { useAuth } from 'hooks';
import { table } from 'utils';

import { texts } from './LocationContactsTable.text';

type LocationContactsTableProps = {
  location: Location;
};

export const LocationContactsTable = ({
  location,
}: LocationContactsTableProps) => {
  const auth = useAuth();
  const [fetchUsers, { data, loading, error }] = useUsersLazyQuery();

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }: any) => `${firstName} ${lastName}`,
      },
      {
        Header: <FormattedMessage {...texts.table_columnMobileNumber} />,
        accessor: 'mobileNumber',
      },
      {
        Header: <FormattedMessage {...texts.table_columnEmail} />,
        accessor: 'email',
      },
      {
        Header: <FormattedMessage {...texts.table_columnRole} />,
        accessor: 'role',
        Cell: ({
          row: {
            original: { role },
          },
        }: any) => (
          <FormattedMessage {...texts[`userRole_${role as UserRole}`]} />
        ),
      },
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => (
          <SwitchLocationContactCompanyProfile
            user={original as User}
            location={location}
            disabled={auth.role === UserRole.CompanyUser}
          />
        ),
      },
    ],
    [location, auth.role]
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, searchTerm }: TableFetchData) => {
      fetchUsers({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            searchTerm,
            company: location.company.id,
          },
          sorting: {
            sorting: sortBy.map(({ id, desc }) => ({
              field: id,
              direction: desc ? 'desc' : 'asc',
            })),
          },
        },
      });
    },
    [fetchUsers, location.company.id]
  );

  return (
    <Table
      columns={columns}
      data={data?.users.edges || []}
      onFetchData={fetchData}
      pagination
      search
      topStripPaddingSides
      headBackground
      loading={loading}
      totalPages={table.getTotalPages(data?.users.meta)}
      sort
      lastTdRight
      empty={
        <Empty
          title={<FormattedMessage {...texts.table_emptyTable} />}
          message={error?.message}
        />
      }
    />
  );
};
