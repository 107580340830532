import { FormattedMessage } from 'react-intl';

import { Empty, Stretch } from 'components/ui/general';

import { texts } from './Error.text';

import styles from './Error.module.scss';

export const Error = () => {
  return (
    <Stretch>
      <div className={styles.inner}>
        <Empty
          title={<FormattedMessage {...texts.title} />}
          button={{
            children: <FormattedMessage {...texts.button} />,
            onClick: () => window.location.reload(),
          }}
        />
      </div>
    </Stretch>
  );
};
