import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  fetchMessagesLoading: {
    id: 'Feed.fetchMessagesLoading',
    description: 'Fetch messages loading',
    defaultMessage: 'Hämtar meddelanden',
  },
  fetchMessagesError: {
    id: 'Feed.fetchMessagesError',
    description: 'Fetch messages error',
    defaultMessage: 'Kunde inte hämta meddelanden',
  },
  fetchMessagesEmpty: {
    id: 'Feed.fetchMessagesEmpty',
    description: 'Fetch messages empty',
    defaultMessage: 'Du har inga meddelanden',
  },
  fetchMore: {
    id: 'Feed.fetchMore',
    description: 'Fetch more',
    defaultMessage: 'Läs in fler meddelanden',
  },
});
