import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  sendFilesError: {
    id: 'SendFiles.sendFilesError',
    description: 'Send files error',
    defaultMessage: 'Kunde inte skicka filen/filerna',
  },
  tooltip: {
    id: 'SendFiles.tooltip',
    description: 'Tooltip',
    defaultMessage: 'Bifoga fil',
  },
});
