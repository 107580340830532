import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'CreateLocationModal.heading',
    description: 'Heading',
    defaultMessage: 'Ny arbetsplats',
  },
  button: {
    id: 'CreateLocationModal.button',
    description: 'Button',
    defaultMessage: 'Skapa ny arbetsplats',
  },
  trigger: {
    id: 'CreateLocationModal.trigger',
    description: 'Trigger',
    defaultMessage: 'Ny arbetsplats',
  },
  createLocationSuccess: {
    id: 'CreateLocationModal.createLocationSuccess',
    description: 'Create location success',
    defaultMessage: 'Arbetsplatsen är skapad',
  },
});
