import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  menuUpdateLogo: {
    id: 'LocationAvatar.menuUpdateLogo',
    description: 'Menu update logo',
    defaultMessage: 'Ladda upp ny bild',
  },
  menuDeleteLogo: {
    id: 'LocationAvatar.menuDeleteLogo',
    description: 'Menu delete logo',
    defaultMessage: 'Ta bort bild',
  },
  updateLocationImagesError: {
    id: 'LocationAvatar.updateLocationImagesError',
    description: 'Update location image error',
    defaultMessage: 'Kunde inte uppdatera bilden',
  },
  updateLocationImagesSuccess: {
    id: 'LocationAvatar.updateLocationImagesSuccess',
    description: 'Update location image success',
    defaultMessage: 'Bilden är uppdaterad',
  },
});
