import { motion, AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';

import { Portal } from 'components/tools';
import { AlertCard } from 'components/ui/general';
import { NotificationManagerSelectors } from 'consts/cypress';
import { Durations, Easings } from 'consts/transition';
import { selectNotifications, deleteNotification } from 'redux/notification';
import { capitalizeFirstLetter } from 'utils';

import styles from './NotificationManager.module.scss';

export const NotificationManager = () => {
  const dispatch = useDispatch();
  const selectorNotifications = useSelector(selectNotifications);

  return (
    <Portal selector="#notification">
      <div
        className={styles.notifications}
        data-cy={NotificationManagerSelectors.Notifications}
      >
        <AnimatePresence>
          {selectorNotifications.map(
            ({ id, type = 'light', title, message }) => {
              return (
                <motion.div
                  key={id}
                  className={styles.notification}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{
                    opacity: 1,
                    height: 'auto',
                  }}
                  transition={{
                    duration: Durations.Fast,
                    ease: Easings.InOut,
                  }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <div className={styles.container}>
                    <AlertCard
                      title={title}
                      message={
                        message ? capitalizeFirstLetter(message) : undefined
                      }
                      type={type}
                      button={{
                        onClick: () => dispatch(deleteNotification({ id })),
                      }}
                    />
                  </div>
                </motion.div>
              );
            }
          )}
        </AnimatePresence>
      </div>
    </Portal>
  );
};
