import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.table,
  heading: {
    id: 'BroadcastRespondentsModal.heading',
    description: 'Broadcast respondents modal heading',
    defaultMessage: 'Svarande',
  },
  fetchChannelIdError: {
    id: 'BroadcastRespondentsModal.fetchChannelIdError',
    description: 'Fetch broadcast channel id error title',
    defaultMessage: 'Kunde inte hämta broadcast',
  },
});
