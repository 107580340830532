import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  Company,
  CustomResourcesDocument,
  LocationsDocument,
  useCreateLocationMutation,
  UsersDocument,
} from 'api/graphql';
import { ActionModal, Button } from 'components/ui/general';
import { LocationForm } from 'components/ui/location';
import { Paths } from 'consts/router';
import { useToast } from 'hooks';

import { texts } from './CreateLocationModal.text';

type CreateLocationModalProps = {
  company: Company;
  trigger?: ReactNode;
};

export const CreateLocationModal = ({
  company,
  trigger,
}: CreateLocationModalProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();

  const [createLocation, { error, loading }] = useCreateLocationMutation({
    refetchQueries: [UsersDocument, CustomResourcesDocument, LocationsDocument],
  });

  const onSubmit = useCallback(
    async ({
      name,
      street,
      postalCode,
      city,
      contactUser,
      description,
      web,
      organisationNumber,
      billingStreet,
      billingPostalCode,
      billingCity,
      billingReference,
      eInvoice,
      region,
      legacyCustomerId,
      sameBillingAsCompany,
      latitude,
      longitude,
    }) => {
      const { data } = await createLocation({
        variables: {
          input: {
            companyId: company.id,
            name,
            street,
            postalCode,
            city,
            userId: contactUser.value,
            description,
            web,
            organisationNumber,
            billingStreet: sameBillingAsCompany
              ? company.billingStreet
              : billingStreet,
            billingPostalCode: sameBillingAsCompany
              ? company.billingPostalCode
              : billingPostalCode,
            billingCity: sameBillingAsCompany
              ? company.billingCity
              : billingCity,
            billingReference,
            eInvoice,
            region: region.value,
            legacyCustomerId: legacyCustomerId || undefined,
            latitude: typeof latitude !== 'undefined' ? String(latitude) : '',
            longitude:
              typeof longitude !== 'undefined' ? String(longitude) : '',
          },
        },
      });

      if (data?.createLocation) {
        navigate(Paths.CompanyLocation(company.id, data.createLocation.id));
        addToast({
          title: formatMessage(texts.createLocationSuccess),
          type: 'success',
        });
      }
    },
    [
      addToast,
      company.billingCity,
      company.billingPostalCode,
      company.billingStreet,
      company.id,
      createLocation,
      formatMessage,
      navigate,
    ]
  );

  useEffect(() => {
    if (!isOpen) reset();
  }, [reset, isOpen]);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      button={{
        children: <FormattedMessage {...texts.button} />,
        type: 'submit',
        loading,
        disabled: loading,
      }}
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      trigger={
        trigger || (
          <Button iconLeft={{ name: 'plus' }} as="div" size="sm">
            <FormattedMessage {...texts.trigger} />
          </Button>
        )
      }
    >
      <LocationForm
        {...{ company, register, control, errors, setValue }}
        showSameBilling
      />
    </ActionModal>
  );
};
