import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import union from 'lodash/union';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  CompanyProfile,
  UserRole,
  useCustomCompaniesBroadcastLazyQuery,
  Scalars,
  CustomCompaniesQuery,
  CustomCompaniesQueryVariables,
  CustomCompaniesDocument,
  CustomResourcesBroadcastQueryVariables,
  CustomResourcesBroadcastQuery,
  CustomCompaniesBroadcastDocument,
} from 'api/graphql';
import { BroadcastSendMessageModal } from 'components/ui/chat';
import { Checkbox } from 'components/ui/forms';
import { Button, Container, Empty } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { TranslationsContext } from 'context/translations';
import { useLazyQueryInterval } from 'hooks';
import { table, typeGuards } from 'utils';

import { texts } from './BroadcastSelectionCompaniesModal.text';

import styles from './BroadcastSelectionCompaniesModal.module.scss';

type BroadcastSelectionCompaniesModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const BroadcastSelectionCompaniesModal = ({
  isOpen,
  setIsOpen,
}: BroadcastSelectionCompaniesModalProps) => {
  const { register, reset } = useForm();
  const { formatMessage } = useIntl();
  const [lang] = useContext(TranslationsContext);

  const [isBroadcastSendMessageModalOpen, setIsBroadcastSendMessageModalOpen] =
    useState(false);
  const [broadcastSendMessageUserIds, setBroadcastSendMessageUserIds] =
    useState<Scalars['ID'][]>([]);
  const [variables, setVariables] =
    useState<CustomResourcesBroadcastQueryVariables>();

  const [fetchUsers, { data, loading, error }] =
    useCustomCompaniesBroadcastLazyQuery();

  const [fetchFilterCompanies, companies] = useLazyQueryInterval<
    CustomCompaniesQuery,
    CustomCompaniesQueryVariables
  >({
    document: CustomCompaniesDocument,
    name: 'companies',
  });

  useEffect(() => {
    if (!isOpen && !isBroadcastSendMessageModalOpen) {
      reset();
      setBroadcastSendMessageUserIds([]);
    }
  }, [isOpen, isBroadcastSendMessageModalOpen, reset]);

  useEffect(() => {
    if (isOpen) {
      fetchFilterCompanies();
    }
  }, [fetchFilterCompanies, isOpen]);

  const [fetchAllUsers, allUsers] = useLazyQueryInterval<
    CustomResourcesBroadcastQuery,
    CustomResourcesBroadcastQueryVariables
  >({
    document: CustomCompaniesBroadcastDocument,
    name: 'users',
  });

  const handleSelectAll = useCallback(() => {
    fetchAllUsers({
      variables: {
        ...variables,
        filter: {
          ...variables?.filter,
          offset: 0,
          limit: 500,
        },
      },
    });
  }, [fetchAllUsers, variables]);

  useEffect(() => {
    const { edges, meta } = allUsers.data?.users || {};

    if (edges?.length === meta?.total && !allUsers.loading) {
      setBroadcastSendMessageUserIds((prevBroadcastSendMessageUserIds) => {
        return union(
          prevBroadcastSendMessageUserIds,
          edges?.map(({ id }) => id)
        );
      });
    }
  }, [allUsers.data?.users, allUsers.loading]);

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }: any) => `${firstName} ${lastName}`,
      },
      {
        Header: <FormattedMessage {...texts.table_columnCompany} />,
        accessor: 'userProfile.company.name',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => {
          const companyProfile: CompanyProfile | undefined =
            original.userProfile.find((userProfile: CompanyProfile) =>
              typeGuards.companyProfile(userProfile)
            );
          return companyProfile?.company.name || null;
        },
      },
      {
        Header: (
          <Button
            size="xs"
            color="secondary"
            onClick={handleSelectAll}
            disabled={allUsers.loading}
            loading={allUsers.loading}
            className={styles.selectAllButton}
          >
            <FormattedMessage {...texts.buttonSelectAll} />
          </Button>
        ),
        disableSortBy: true,
        accessor: 'selection',
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => (
          <div className={styles.selectAllCheckboxHolder}>
            <Checkbox
              register={register}
              name={`selection.${id}`}
              defaultChecked={broadcastSendMessageUserIds.includes(id)}
              onChange={({ target: { checked } }) => {
                setBroadcastSendMessageUserIds(
                  (prevBroadcastSendMessageUserIds) => {
                    if (checked) {
                      return [...prevBroadcastSendMessageUserIds, id];
                    }

                    return prevBroadcastSendMessageUserIds.filter(
                      (prevId) => prevId !== id
                    );
                  }
                );
              }}
            />
          </div>
        ),
      },
    ],
    [allUsers.loading, broadcastSendMessageUserIds, handleSelectAll, register]
  );

  const filter = useMemo(
    () => [
      {
        select: {
          name: 'company',
          placeholder: formatMessage(texts.filterCompanyLabel),
          loading: companies.loading,
          options:
            [...(companies.data?.companies.edges || [])]
              .sort((a, b) => a.name.localeCompare(b.name, lang))
              .map(({ id, name }) => ({
                label: name,
                value: `company__${id}`,
              })) || [],
        },
      },
    ],
    [companies.data?.companies.edges, companies.loading, formatMessage, lang]
  );

  const fetchData = useCallback(
    ({
      pageIndex,
      pageSize,
      sortBy,
      searchTerm,
      activeFilter,
    }: TableFetchData) => {
      if (!isOpen) return;

      const defaultSorting = [
        {
          field: 'createdAt',
          direction: 'desc',
        },
      ];

      const { company } = table.getFilters(activeFilter);

      const collectVariables = {
        filter: {
          offset: pageIndex * pageSize,
          limit: pageSize,
          searchTerm,
          roles: [UserRole.CompanyAdmin, UserRole.CompanyUser],
          company: company?.[0],
        },
        sorting: {
          sorting: sortBy.length
            ? sortBy.map(({ id, desc }) => ({
                field: id,
                direction: desc ? 'desc' : 'asc',
              }))
            : defaultSorting,
        },
      };

      setVariables(collectVariables);
      fetchUsers({ variables: collectVariables });
    },
    [fetchUsers, isOpen]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        middleSize="lg"
        middleStripPadding
      >
        <Container fullWidth vertical>
          <h5>
            <FormattedMessage {...texts.heading} />
          </h5>
        </Container>
        <Table
          search
          pagination
          isInModalMiddle
          sort
          lastTdRight
          totalPages={table.getTotalPages(data?.users.meta)}
          columns={columns}
          data={data?.users.edges || []}
          onFetchData={fetchData}
          loading={loading}
          handleQueryParameters={false}
          filter={filter}
          topBeside={
            <Button
              iconLeft={{ name: 'plus' }}
              as="div"
              disabled={!broadcastSendMessageUserIds.length}
              onClick={() => {
                setIsOpen(false);
                setIsBroadcastSendMessageModalOpen(true);
              }}
            >
              <FormattedMessage
                {...texts.buttonWriteMessage}
                values={{ amount: broadcastSendMessageUserIds.length }}
              />
            </Button>
          }
          empty={
            <Empty
              title={<FormattedMessage {...texts.table_emptyTable} />}
              message={error?.message}
            />
          }
        />
      </Modal>
      <BroadcastSendMessageModal
        isOpen={isBroadcastSendMessageModalOpen}
        setIsOpen={(broadcastSendMessageModalIsOpen) => {
          if (!broadcastSendMessageModalIsOpen) {
            setIsBroadcastSendMessageModalOpen(false);
            setIsOpen(true);
          }
        }}
        userIds={broadcastSendMessageUserIds}
      />
    </>
  );
};
