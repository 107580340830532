import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'BroadcastSendMessageModal.heading',
    description: 'Heading',
    defaultMessage: 'Meddelande',
  },
  buttonSend: {
    id: 'BroadcastSendMessageModal.buttonSend',
    description: 'Button send broadcast',
    defaultMessage: 'Skicka',
  },
  buttonSendWithAmount: {
    id: 'BroadcastSendMessageModal.buttonSendWithAmount',
    description: 'Button send broadcast with amount',
    defaultMessage: 'Skicka till {amount} st',
  },
  buttonGoBackSelection: {
    id: 'BroadcastSendMessageModal.buttonGoBackSelection',
    description: 'Button go back to select receivers',
    defaultMessage: 'Urval',
  },
  labelBroadcastTitle: {
    id: 'BroadcastSendMessageModal.labelBroadcastTitle',
    description: 'Label broadcast title',
    defaultMessage: 'Rubrik',
  },
  labelBroadcastMessage: {
    id: 'BroadcastSendMessageModal.labelBroadcastMessage',
    description: 'Label broadcast message',
    defaultMessage: 'Meddelande',
  },
  broadcastMessageSuccess: {
    id: 'BroadcastSendMessageModal.broadcastMessageSuccess',
    description: 'Broadcast message success',
    defaultMessage: 'Meddelandet har skickats',
  },
});
