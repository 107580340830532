import { useCallback } from 'react';

import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomMessagesQuery } from 'api/graphql';
import { Gutter, Icon, Image } from 'components/ui/general';
import { DateFormat } from 'consts/date';
import { Paths } from 'consts/router';
import {
  setDateInMilliseconds,
  setWorkShiftIdAndWorkShiftSlotIdAndLocationId,
} from 'redux/bookings';
import { format, imageProxy } from 'utils';

import styles from '../Message.module.scss';

type MessageTextProps = {
  text: CustomMessagesQuery['messages']['edges'][0]['message'];
  title?: CustomMessagesQuery['messages']['edges'][0]['title'];
  extra?: CustomMessagesQuery['messages']['edges'][0]['extra'];
  createdByMe?: boolean;
};

export const MessageText = ({
  text,
  title,
  extra,
  createdByMe,
}: MessageTextProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderExtra = useCallback(() => {
    if (!extra) {
      return null;
    }

    const {
      startTime,
      endTime,
      workShiftId,
      workShiftSlot: {
        id,
        workShift: { location },
      },
    } = extra;

    const onClick = () => {
      dispatch(
        setDateInMilliseconds(
          format.date({ date: startTime }).format(DateFormat.Milliseconds)
        )
      );
      dispatch(
        setWorkShiftIdAndWorkShiftSlotIdAndLocationId({
          workShiftId,
          workShiftSlotId: id,
          locationId: location.id,
        })
      );

      navigate(Paths.Bookings);
    };

    return (
      <button type="button" className={styles.extra} onClick={onClick}>
        {!!location.image && (
          <div className={styles.extraImage}>
            <Image
              src={imageProxy({ url: location.image.uri, options: ['420x'] })}
              cover
              fit="cover"
              alt={location.image.originalName}
              spinnerSize="sm"
              spinnerColor={createdByMe ? 'light' : 'dark'}
            />
          </div>
        )}
        <div className={styles.extraContent}>
          <div className={styles.extraContentLeft}>
            <Gutter gutter={{ bottom: 1 }}>
              <Gutter.Item>
                <div className={styles.extraName}>{location.name}</div>
              </Gutter.Item>
              <Gutter.Item>
                <div>
                  {format.date({ date: startTime }).format(DateFormat.Date)}
                </div>
              </Gutter.Item>
              <Gutter.Item>
                <div>
                  {format
                    .date({ date: startTime })
                    .format(DateFormat.HourMinutes)}
                  -
                  {format
                    .date({ date: endTime })
                    .format(DateFormat.HourMinutes)}
                </div>
              </Gutter.Item>
            </Gutter>
          </div>
          <div className={styles.extraContentRight}>
            <Icon name="arrow-right" />
          </div>
        </div>
      </button>
    );
  }, [dispatch, extra, navigate, createdByMe]);

  return (
    <>
      {renderExtra()}
      <div className={styles.text}>
        {!!title && <h6 className={styles.textTitle}>{title}</h6>}
        {text.split('\n').map((string, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={index}>
            {string.length ? (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    key={key}
                    href={decoratedHref}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {string}
              </Linkify>
            ) : (
              <>&nbsp;</>
            )}
          </p>
        ))}
      </div>
    </>
  );
};
