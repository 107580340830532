import { useState } from 'react';

import classNames from 'classnames';

import styles from './VideoCover.module.scss';

export type VideoCoverProps = {
  src: string;
};

export const VideoCover = ({ src }: VideoCoverProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  return (
    <div
      className={classNames(styles.root, {
        [styles.isPlaying]: isPlaying,
      })}
    >
      <video
        src={src}
        autoPlay
        muted
        loop
        controls={false}
        playsInline
        className={styles.video}
        onPlaying={() => setIsPlaying(true)}
      />
    </div>
  );
};
