import { ReactNode } from 'react';

import styles from './Stretch.module.scss';

type StretchProps = {
  children: ReactNode;
};

export const Stretch = ({ children }: StretchProps) => {
  return <div className={styles.root}>{children}</div>;
};
