import { FormattedMessage } from 'react-intl';

import { RestaurantProfile } from 'api/graphql';
import { Grid, Gutter, Icon, Tag } from 'components/ui/general';
import { resource } from 'utils';

import { texts } from '../RestaurantProfileModal.text';

import styles from '../RestaurantProfileModal.module.scss';

type WorkExperienceSectionProps = {
  restaurantProfile: RestaurantProfile;
};

export const WorkExperienceSection = ({
  restaurantProfile,
}: WorkExperienceSectionProps) => {
  const { workExperience } = restaurantProfile;

  if (!workExperience?.length) {
    return null;
  }

  return (
    <Gutter gutter={{ bottom: 2 }}>
      <Gutter.Item>
        <h6 className={styles.sectionHeading}>
          <FormattedMessage {...texts.headingWorkExperience} />
        </h6>
      </Gutter.Item>
      <Gutter.Item>
        <Grid gutter={{ left: 1.5, bottom: 1.5 }}>
          {workExperience.map(({ id, category }) => (
            <Grid.Item key={id}>
              <Tag className={styles.workExperience} color="info" size="lg">
                <div className={styles.workExperienceIcon}>
                  <Icon
                    name={resource.iconsRestaurantVerticalCategory[category]}
                  />
                </div>
                <div>
                  <FormattedMessage
                    {...texts[`restaurantVerticalCategory_${category}`]}
                  />
                </div>
              </Tag>
            </Grid.Item>
          ))}
        </Grid>
      </Gutter.Item>
    </Gutter>
  );
};
