import { Tooltip } from 'components/ui/general';

type TruncateProps = {
  text: string;
  characterLimit?: number;
};

export const Truncate = ({ text, characterLimit = 50 }: TruncateProps) => {
  const concatTextWithoutLinebreak = text.split('\n').join(' ');
  const shouldTruncate = concatTextWithoutLinebreak.length > characterLimit;

  const displayTextElement = (
    <p>
      {shouldTruncate
        ? concatTextWithoutLinebreak.slice(0, characterLimit).concat('...')
        : concatTextWithoutLinebreak}
    </p>
  );

  const tipTextElement = text.split('\n').map((sentence, index) => {
    const uniqueKey = `truncate-${index}`;
    return <p key={uniqueKey}>{sentence.length ? sentence : <>&nbsp;</>}</p>;
  });

  return shouldTruncate ? (
    <Tooltip tip={tipTextElement} placement="top" trigger="hover">
      {displayTextElement}
    </Tooltip>
  ) : (
    displayTextElement
  );
};
