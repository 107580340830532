import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  trigger: {
    id: 'BroadcastViewModal.trigger',
    description: 'Trigger',
    defaultMessage: 'Visa',
  },
  titleLabel: {
    id: 'BroadcastViewModal.titleLabel',
    description: 'Title label',
    defaultMessage: 'Rubrik',
  },
  messageLabel: {
    id: 'BroadcastViewModal.messageLabel',
    description: 'Message label',
    defaultMessage: 'Meddelande',
  },
});
