import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { isSSR } from 'utils';

type SetStoredValue<T> = Dispatch<SetStateAction<T>>;

export const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): [T, SetStoredValue<T>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (isSSR) {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Can't read localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  const setNewValue: SetStoredValue<T> = useCallback(
    (value) => {
      if (isSSR) {
        console.error(`Can't set localStorage key "${key}" on the server`);
        return;
      }

      try {
        const newValue = value instanceof Function ? value(storedValue) : value;
        setStoredValue(newValue);
        window.localStorage.setItem(key, JSON.stringify(newValue));
      } catch (error) {
        console.error(`Can't set localStorage key "${key}":`, error);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setNewValue];
};
