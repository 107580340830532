import { ReactNode } from 'react';

import classNames from 'classnames';

import { IconProps } from 'components/ui/general';
import { AccordionSelectors } from 'consts/cypress';
import { AccordionContextProvider } from 'context/accordion';
import { passPropsToChildren } from 'utils';

import {
  AccordionItem,
  AccordionLabel,
  AccordionContent,
} from './subcomponents';

import styles from './Accordion.module.scss';

export type AccordionProps = {
  children: ReactNode;
  multiple?: boolean;
  activeIds?: string[];
  className?: string;
  iconClosed?: IconProps;
  iconOpened?: IconProps;
};

export const Accordion = ({
  children,
  activeIds,
  className,
  ...props
}: AccordionProps) => {
  return (
    <AccordionContextProvider activeIds={activeIds}>
      <div
        className={classNames(styles.root, className)}
        data-cy={AccordionSelectors.Root}
      >
        {passPropsToChildren(children, props)}
      </div>
    </AccordionContextProvider>
  );
};

Accordion.Item = AccordionItem;
Accordion.Label = AccordionLabel;
Accordion.Content = AccordionContent;
