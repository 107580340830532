import { useCallback, useContext, useEffect } from 'react';

import { WrapperProps } from '@googlemaps/react-wrapper';
import { UseFormSetValue } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  Company,
  Region,
  UserRole,
  UsersQuery,
  UsersQueryVariables,
  UsersDocument,
} from 'api/graphql';
import { GoogleMaps } from 'components/tools';
import {
  Select,
  SelectProps,
  Text,
  Textarea,
  TextProps,
  TextWithGoogleMapsAutocomplete,
} from 'components/ui/forms';
import { Grid, Gutter } from 'components/ui/general';
import { LocationFormSelectors } from 'consts/cypress';
import { TranslationsContext } from 'context/translations';
import {
  useAuth,
  useGoogleMaps,
  useLazyQueryInterval,
  useValidate,
} from 'hooks';

import { texts } from '../LocationForm.text';

type LocationProps = {
  company: Company;
  register: TextProps['register'];
  control: SelectProps['control'];
  setValue: UseFormSetValue<any>;
  edit?: boolean;
  errors: {
    [key: string]: any;
  };
  defaultValues?: {
    [key: string]: any;
  };
  disabled?: boolean;
};

export const Location = ({
  company,
  register,
  control,
  setValue,
  edit,
  errors,
  defaultValues,
  disabled,
}: LocationProps) => {
  const { formatMessage } = useIntl();
  const { role } = useAuth();
  const [lang] = useContext(TranslationsContext);
  const { isPostalCode, isOrganisationNumber, isInt, isURL } = useValidate();
  const { getAddressComponents } = useGoogleMaps();

  const [fetchUsers, { error, loading, data }] = useLazyQueryInterval<
    UsersQuery,
    UsersQueryVariables
  >({
    document: UsersDocument,
    name: 'users',
  });

  useEffect(() => {
    fetchUsers({
      variables: {
        filter: {
          company: company.id,
        },
      },
    });
  }, [fetchUsers, company.id]);

  const onPlaceChanged = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const addressComponents = getAddressComponents(place.address_components);
      const longitude = place.geometry?.location?.lng();
      const latitude = place.geometry?.location?.lat();
      const options = (value?: string | number) => ({
        shouldValidate: !!value,
      });

      setValue('longitude', longitude, options(longitude));
      setValue('latitude', latitude, options(latitude));
      setValue(
        'postalCode',
        addressComponents.postalCode,
        options(addressComponents.postalCode)
      );
      setValue(
        'city',
        addressComponents.postalTown,
        options(addressComponents.postalTown)
      );
    },
    [getAddressComponents, setValue]
  );

  // Because of Google something happens with the default value,
  // so we need to help react-hook-form here
  useEffect(() => {
    if (defaultValues?.street) {
      setValue('street', defaultValues.street);
    }
  }, [defaultValues?.street, setValue]);

  const renderStreet = useCallback<NonNullable<WrapperProps['render']>>(
    (status) => (
      <TextWithGoogleMapsAutocomplete
        text={{
          register,
          name: 'street',
          label: formatMessage(texts.form_labelStreet),
          validation: { required: true },
          error: errors?.street,
          fullWidth: true,
          defaultValue: defaultValues?.street,
          disabled,
        }}
        status={status}
        onPlaceChanged={onPlaceChanged}
      />
    ),
    [
      defaultValues?.street,
      disabled,
      errors?.street,
      formatMessage,
      onPlaceChanged,
      register,
    ]
  );

  return (
    <Gutter gutter={{ bottom: 2 }}>
      <Gutter.Item data-cy={LocationFormSelectors.Name}>
        <Text
          name="name"
          label={formatMessage(texts.form_labelNameLocation)}
          register={register}
          validation={{ required: true }}
          error={errors?.name}
          fullWidth
          defaultValue={defaultValues?.name}
          disabled={disabled}
        />
      </Gutter.Item>
      <Gutter.Item data-cy={LocationFormSelectors.Street}>
        <GoogleMaps render={renderStreet} libraries={['places']} />
      </Gutter.Item>
      <Gutter.Item>
        <Grid gutter={{ left: 1 }}>
          <Grid.Item width={6} data-cy={LocationFormSelectors.PostalCode}>
            <Text
              name="postalCode"
              label={formatMessage(texts.form_labelPostalCode)}
              register={register}
              validation={{ required: true, validate: isPostalCode }}
              error={errors?.postalCode}
              fullWidth
              defaultValue={defaultValues?.postalCode}
              disabled={disabled}
            />
          </Grid.Item>
          <Grid.Item width={6} data-cy={LocationFormSelectors.City}>
            <Text
              name="city"
              label={formatMessage(texts.form_labelCity)}
              register={register}
              validation={{ required: true }}
              error={errors?.city}
              fullWidth
              defaultValue={defaultValues?.city}
              disabled={disabled}
            />
          </Grid.Item>
        </Grid>
      </Gutter.Item>
      <Gutter.Item>
        <Grid gutter={{ left: 1 }}>
          <Grid.Item width={6} data-cy={LocationFormSelectors.Latitude}>
            <Text
              name="latitude"
              label={formatMessage(texts.form_labelLatitude)}
              register={register}
              error={errors?.latitude}
              fullWidth
              defaultValue={defaultValues?.latitude}
              disabled={disabled}
            />
          </Grid.Item>
          <Grid.Item width={6} data-cy={LocationFormSelectors.Longitude}>
            <Text
              name="longitude"
              label={formatMessage(texts.form_labelLongitude)}
              register={register}
              error={errors?.longitude}
              fullWidth
              defaultValue={defaultValues?.longitude}
              disabled={disabled}
            />
          </Grid.Item>
        </Grid>
      </Gutter.Item>
      <Gutter.Item>
        <div data-cy={LocationFormSelectors.ContactUser}>
          <Select
            name="contactUser"
            label={formatMessage(texts.form_labelContactUser)}
            control={control}
            fullWidth
            validation={{ required: true }}
            error={errors?.contactUser || error}
            loading={loading}
            options={
              data?.users.edges.map(({ id, firstName, lastName }) => ({
                label: `${firstName} ${lastName}`,
                value: id,
              })) || []
            }
            defaultValue={defaultValues?.contactUser}
            disabled={disabled}
          />
        </div>
      </Gutter.Item>
      <Gutter.Item data-cy={LocationFormSelectors.Description}>
        <Textarea
          name="description"
          label={formatMessage(texts.form_labelDescription)}
          register={register}
          error={errors?.description}
          fullWidth
          defaultValue={defaultValues?.description}
          disabled={disabled}
        />
      </Gutter.Item>
      <Gutter.Item data-cy={LocationFormSelectors.Web}>
        <Text
          name="web"
          label={formatMessage(texts.form_labelWeb)}
          register={register}
          error={errors?.web}
          fullWidth
          defaultValue={defaultValues?.web}
          validation={{ validate: (value) => value?.length && isURL(value) }}
          disabled={disabled}
        />
      </Gutter.Item>
      <Gutter.Item data-cy={LocationFormSelectors.OrganisationNumber}>
        <Text
          name="organisationNumber"
          label={formatMessage(texts.form_labelOrganisationNumber)}
          register={register}
          validation={{
            required: true,
            validate: isOrganisationNumber,
          }}
          error={errors?.organisationNumber}
          fullWidth
          defaultValue={defaultValues?.organisationNumber}
          disabled={(edit && role !== UserRole.Admin) || disabled}
        />
      </Gutter.Item>
      <Gutter.Item data-cy={LocationFormSelectors.Region}>
        <Select
          name="region"
          label={formatMessage(texts.form_labelRegion)}
          control={control}
          fullWidth
          validation={{ required: true }}
          error={errors?.region}
          options={Object.entries(Region)
            .map(([, value]) => ({
              label: formatMessage(texts[`region_${value}`]),
              value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label, lang))}
          defaultValue={defaultValues?.region}
          disabled={disabled}
        />
      </Gutter.Item>
      <Gutter.Item>
        <Text
          name="legacyCustomerId"
          label={formatMessage(texts.form_labelLegacyCustomerId)}
          register={register}
          fullWidth
          validation={{
            validate: (value) => value?.length && isInt(value),
          }}
          error={errors?.legacyCustomerId}
          defaultValue={defaultValues?.legacyCustomerId}
          disabled={disabled}
        />
      </Gutter.Item>
    </Gutter>
  );
};
