import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ChatGroupsDocument,
  Scalars,
  useBroadcastMutation,
  useResendBroadcastMutation,
} from 'api/graphql';
import { Text, Textarea } from 'components/ui/forms';
import { ActionModal, Button, Gutter } from 'components/ui/general';
import { useToast } from 'hooks';

import { texts } from './BroadcastSendMessageModal.text';

type BroadcastSendMessageModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  userIds?: Scalars['ID'][];
  chatGroupId?: Scalars['ID'];
};

export const BroadcastSendMessageModal = ({
  isOpen,
  setIsOpen,
  userIds,
  chatGroupId,
}: BroadcastSendMessageModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const [sendBroadcastMessage, { loading, error }] = useBroadcastMutation({
    refetchQueries: [ChatGroupsDocument],
  });
  const [resendBroadcast, resendBroadcastResponse] = useResendBroadcastMutation(
    {
      refetchQueries: [ChatGroupsDocument],
    }
  );

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const resendBroadcastMutationCallback = useCallback(
    async (
      broadcastChatId: Scalars['ID'],
      newBroadcastTitle: string,
      newBroadcastMessage: string
    ) => {
      const { data: resendBroadcastMutationResult } = await resendBroadcast({
        variables: {
          id: broadcastChatId,
          title: newBroadcastTitle,
          message: newBroadcastMessage,
          new: true,
        },
      });

      if (resendBroadcastMutationResult?.resendBroadcast?.id) {
        addToast({
          title: formatMessage(texts.broadcastMessageSuccess),
          type: 'success',
        });
        setIsOpen(false);
      }
    },
    [addToast, formatMessage, setIsOpen, resendBroadcast]
  );

  const onSubmit = useCallback(
    async ({ broadcastTitle, broadcastMessage }) => {
      if (chatGroupId) {
        resendBroadcastMutationCallback(
          chatGroupId,
          broadcastTitle,
          broadcastMessage
        );
        return;
      }

      if (userIds) {
        const { data } = await sendBroadcastMessage({
          variables: {
            title: broadcastTitle,
            message: broadcastMessage,
            userIds,
          },
        });

        if (data?.broadcast?.id) {
          addToast({
            title: formatMessage(texts.broadcastMessageSuccess),
            type: 'success',
          });
          setIsOpen(false);
        }
      }
    },
    [
      addToast,
      chatGroupId,
      formatMessage,
      sendBroadcastMessage,
      setIsOpen,
      resendBroadcastMutationCallback,
      userIds,
    ]
  );

  return (
    <ActionModal
      {...{ isOpen, setIsOpen }}
      error={error || resendBroadcastResponse.error}
      size="md"
      onSubmit={handleSubmit(onSubmit)}
      button={{
        children: userIds?.length ? (
          <FormattedMessage
            {...texts.buttonSendWithAmount}
            values={{ amount: userIds.length }}
          />
        ) : (
          <FormattedMessage {...texts.buttonSend} />
        ),
        type: 'submit',
        disabled: loading || resendBroadcastResponse.loading,
        loading: loading || resendBroadcastResponse.loading,
      }}
      as="form"
    >
      <Gutter gutter={{ bottom: 4 }}>
        <Gutter.Item>
          <h5>
            <FormattedMessage {...texts.heading} />
          </h5>
        </Gutter.Item>
        <Gutter.Item>
          <Button
            color="secondary"
            iconLeft={{ name: 'arrow-left' }}
            onClick={() => setIsOpen(false)}
          >
            <FormattedMessage {...texts.buttonGoBackSelection} />
          </Button>
        </Gutter.Item>
        <Gutter.Item>
          <Gutter gutter={{ bottom: 3 }}>
            <Gutter.Item>
              <Text
                name="broadcastTitle"
                label={<FormattedMessage {...texts.labelBroadcastTitle} />}
                register={register}
                fullWidth
                validation={{ required: true }}
                error={errors.broadcastTitle}
              />
            </Gutter.Item>
            <Gutter.Item>
              <Textarea
                name="broadcastMessage"
                label={<FormattedMessage {...texts.labelBroadcastMessage} />}
                register={register}
                fullWidth
                validation={{ required: true }}
                error={errors.broadcastMessage}
              />
            </Gutter.Item>
          </Gutter>
        </Gutter.Item>
      </Gutter>
    </ActionModal>
  );
};
