import { useCallback } from 'react';

import { apolloClient } from 'api/apollo';
import {
  BookingsDocument,
  useCustomNotificationSubscribeSubscription,
  CustomNotificationSubscribeSubscription,
  NotificationType,
  CustomBookingsDocument,
  CustomBookingsWorkShiftSlotModalDocument,
  BookingDocument,
  CustomBookingSlotsDocument,
  BookingSlotsDocument,
  CustomWorkShiftSlotsDocument,
  WorkShiftSlotsDocument,
  WorkShiftSlotDocument,
  WorkShiftDocument,
} from 'api/graphql';
import { useNotification } from 'hooks';

export const NotificationsListener = () => {
  const { addNotification } = useNotification();

  const refetchQueriesThatAffectsBookings = useCallback(
    async (
      notification: CustomNotificationSubscribeSubscription['notificationSubscribe']
    ) => {
      const acceptedNotificationTypes = [
        NotificationType.AcceptedJobOffer,
        NotificationType.BookingCanceled,
        NotificationType.BookingStartSoon,
        NotificationType.ContactTimeChange,
        NotificationType.DeclineJobOffer,
        NotificationType.DeclineRfi,
        NotificationType.JobOfferAllDeclined,
        NotificationType.NewJobOffer,
        NotificationType.NoShow,
        NotificationType.RequestForInterest,
        NotificationType.ResourceDeclineTimeChange,
        NotificationType.ResourceTimeChange,
        NotificationType.RfiAccepted,
        NotificationType.RfiAllDeclined,
        NotificationType.TimeReportSubmitted,
        NotificationType.UserReportedSick,
        NotificationType.BookingStarted,
      ];

      if (!acceptedNotificationTypes.includes(notification.type)) {
        return [];
      }

      // This solution is not great, but it will do for now
      const result = await apolloClient.refetchQueries({
        include: [
          // Bookings
          BookingsDocument,
          CustomBookingsDocument,
          CustomBookingsWorkShiftSlotModalDocument,

          // Booking
          BookingDocument,

          // Booking slots
          BookingSlotsDocument,
          CustomBookingSlotsDocument,

          // Work shift slots
          WorkShiftSlotsDocument,
          CustomWorkShiftSlotsDocument,

          // Work shift slot
          WorkShiftSlotDocument,

          // Work shift
          WorkShiftDocument,
        ],
      });

      return result;
    },
    []
  );

  useCustomNotificationSubscribeSubscription({
    onData: async ({ data }) => {
      const notification = data.data?.notificationSubscribe;

      if (!notification) {
        return;
      }

      // Wait for refetchQueries() so it notifies AFTER the refetch(es) are done
      const result = await refetchQueriesThatAffectsBookings(notification);

      if (result && !notification.background) {
        const { title, message } = notification;
        addNotification({ title, message });
      }
    },
  });

  return null;
};
