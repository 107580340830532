import {
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { usePopperTooltip } from 'react-popper-tooltip';

import { Link, LinkProps, Portal } from 'components/tools';
import { MenuSelectors } from 'consts/cypress';
import { Durations, Easings } from 'consts/transition';
import { useKeyPress } from 'hooks';

import styles from './Menu.module.scss';

export type MenuProps = {
  children: ReactNode;
  items: {
    label: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    link?: Omit<LinkProps, 'children'>;
    className?: string;
    closeOnClick?: boolean;
  }[];
  placement?:
    | 'top-start'
    | 'right-start'
    | 'bottom-start'
    | 'left-start'
    | 'bottom-end';
  className?: string;
  classNameTrigger?: string;
  classNameMenu?: string;
  color?: 'light'; // color names from $all-colors
  portal?: boolean;
};

export const Menu = ({
  children,
  items,
  placement = 'bottom-start',
  className,
  classNameTrigger,
  classNameMenu,
  color = 'light',
  portal = false,
}: MenuProps) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const PortalOrNot = useMemo(() => (portal ? Portal : 'div'), [portal]);
  const padding = useMemo(
    () => ({
      top: 56,
      left: 0,
    }),
    []
  );
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, state } =
    usePopperTooltip(
      {
        trigger: 'click',
        placement,
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
        closeOnTriggerHidden: true,
        offset: [0, 0],
      },
      {
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [
                'bottom-start',
                'top-start',
                'right-start',
                'left-start',
                'bottom-end',
              ],
              padding,
            },
          },
        ],
      }
    );

  useKeyPress(27, () => {
    if (controlledVisible) setControlledVisible(false);
  });

  const renderItems = useCallback(
    () =>
      items.map(
        ({ label, onClick, link = {}, closeOnClick, ...props }, index) => {
          let Element: any = 'div';
          if (onClick) Element = 'button';
          if (link.to) Element = Link;

          return (
            <Element
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              type={onClick && !link.to ? 'button' : null}
              className={classNames(styles.item, props.className, {
                [styles.itemHover]: !!onClick || !!link.to,
              })}
              onClick={
                onClick || closeOnClick
                  ? (event: MouseEvent<any, any>) => {
                      onClick?.(event);
                      if (closeOnClick) setControlledVisible(false);
                    }
                  : undefined
              }
              data-cy={MenuSelectors.Item}
              {...link}
            >
              {label}
            </Element>
          );
        }
      ),
    [items]
  );

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.visible]: visible,
      })}
      data-cy={MenuSelectors.Root}
    >
      <button
        type="button"
        ref={setTriggerRef}
        className={classNames(styles.trigger, classNameTrigger)}
        data-cy={MenuSelectors.Trigger}
      >
        {children}
      </button>
      <AnimatePresence>
        {visible && (
          <PortalOrNot>
            <motion.div
              ref={setTooltipRef}
              transition={{ duration: Durations.Fast, ease: Easings.InOut }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              {...getTooltipProps({
                className: classNames(styles.menu, classNameMenu, {
                  [styles[`${color || 'dark'}Color`]]: true,
                  [styles.offsetHorizontal]:
                    state?.placement === 'right-start' ||
                    state?.placement === 'left-start',
                  [styles.offsetVertical]:
                    state?.placement === 'top-start' ||
                    state?.placement === 'bottom-start' ||
                    state?.placement === 'bottom-end',
                }),
              })}
              data-cy={MenuSelectors.Menu}
            >
              <div className={styles.inner}>{renderItems()}</div>
            </motion.div>
          </PortalOrNot>
        )}
      </AnimatePresence>
    </div>
  );
};
