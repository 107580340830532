import { useContext } from 'react';

import { Wrapper, WrapperProps } from '@googlemaps/react-wrapper';

import { TranslationsContext } from 'context/translations';

type GoogleMapsProps = {
  render?: WrapperProps['render'];
  libraries?: WrapperProps['libraries'];
};

export const GoogleMaps = ({ render, libraries }: GoogleMapsProps) => {
  const [lang] = useContext(TranslationsContext);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API) {
    console.error('API key is missing for Google Maps to render successfully');
    return null;
  }

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
      libraries={libraries}
      render={render}
      language={lang}
    />
  );
};
