import { useCallback, useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { UpdateUserInput, useUserChangeImageMutation, User } from 'api/graphql';
import { Avatar } from 'components/ui/general';
import { useToast } from 'hooks';

import { texts } from './UserAvatar.text';

export type UserAvatarProps = {
  user: User;
  fileAvailable?: boolean;
};

export const UserAvatar = ({ user, fileAvailable = true }: UserAvatarProps) => {
  const { formatMessage } = useIntl();
  const fileRef = useRef<HTMLInputElement | null>(null);
  const { register, setValue } = useForm();
  const { addToast } = useToast();
  const [updateUserImage, { error, loading }] = useUserChangeImageMutation();

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.updateUserImageError),
        message: error.message,
        type: 'error',
      });
    }
  }, [error, addToast, formatMessage]);

  const updateImage = useCallback(
    async (image?: UpdateUserInput['image']) => {
      if (user) {
        const { data } = await updateUserImage({
          variables: {
            id: user.id,
            input: image ? { image } : null,
          },
        });

        if (data?.userChangeImage) {
          if (!image) {
            setValue('image', undefined);
          }

          addToast({
            title: formatMessage(texts.updateUserImageSuccess),
            type: 'success',
          });
        }
      }
    },
    [updateUserImage, user, addToast, formatMessage, setValue]
  );

  const onChange = useCallback(
    async ({ target }) => {
      if (target.files?.[0]) {
        await updateImage(target.files[0]);
      }
    },
    [updateImage]
  );

  return (
    <Avatar
      image={user.image}
      imageOptions={['96x']}
      rounded
      aspectRatio="1:1"
      width="96px"
      loading={loading}
      file={
        fileAvailable
          ? {
              name: 'image',
              register,
              onChange,
              accept: 'image/jpg, image/jpeg, image/png',
              fileRef,
              disabled: loading,
              menu: user.image
                ? {
                    children: null,
                    items: [
                      {
                        label: <FormattedMessage {...texts.menuUpdateImage} />,
                        onClick: () => fileRef.current?.click(),
                      },
                      {
                        label: <FormattedMessage {...texts.menuDeleteImage} />,
                        onClick: async () => {
                          await updateImage();
                        },
                      },
                    ],
                  }
                : undefined,
            }
          : undefined
      }
    />
  );
};
