import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { Outlet, Navigate } from 'react-router-dom';

import { UserRole } from 'api/graphql';
import { Paths } from 'consts/router';
import { useAuth, useToast } from 'hooks';

import { texts } from './PrivateRoute.text';

type PrivateRouteProps = {
  allowedRoles?: UserRole[];
};

export const PrivateRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const { formatMessage } = useIntl();
  const { loggedIn } = useAuth();
  const { isNotAllowed } = useAuth({ allowedRoles });
  const { addToast } = useToast();

  useEffect(() => {
    if (isNotAllowed) {
      addToast({
        title: formatMessage(texts.notAllowed),
        type: 'warning',
      });
    }
  }, [addToast, formatMessage, isNotAllowed]);

  if (!loggedIn) {
    return <Navigate to={Paths.Login} replace />;
  }

  if (isNotAllowed) {
    return <Navigate to={Paths.Landing} replace />;
  }

  return <Outlet />;
};
