import { ReactNode, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Linkify from 'react-linkify';

import { ActionModal, Button, Gutter } from 'components/ui/general';

import { texts } from './BroadcastViewModal.text';

import styles from './BroadcastViewModal.module.scss';

type BroadcastViewModalProps = {
  trigger?: ReactNode;
  title: string;
  message?: string;
};

export const BroadcastViewModal = ({
  trigger,
  title,
  message,
}: BroadcastViewModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen }}
      trigger={
        trigger || (
          <Button color="secondary" as="div" size="sm">
            <FormattedMessage {...texts.trigger} />
          </Button>
        )
      }
    >
      <Gutter gutter={{ bottom: 2 }}>
        <Gutter.Item>
          <h6 className={styles.label}>
            <FormattedMessage {...texts.titleLabel} />
          </h6>
          {title}
        </Gutter.Item>
        {!!message && (
          <Gutter.Item>
            <h6 className={styles.label}>
              <FormattedMessage {...texts.messageLabel} />
            </h6>
            {message?.split('\n').map((string, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>
                {string.length ? (
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        key={key}
                        href={decoratedHref}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {string}
                  </Linkify>
                ) : (
                  <>&nbsp;</>
                )}
              </p>
            ))}
          </Gutter.Item>
        )}
      </Gutter>
    </ActionModal>
  );
};
