import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.restaurantVerticalCategory,
  ...shared.region,
  ...shared.language,
  trigger: {
    id: 'RestaurantProfileModal.trigger',
    description: 'Trigger',
    defaultMessage: 'Profil',
  },
  headingWorkExperience: {
    id: 'RestaurantProfileModal.headingWorkExperience',
    description: 'Heading work experience',
    defaultMessage: 'Yrkeserfarenhet',
  },
  headingTag: {
    id: 'RestaurantProfileModal.headingTag',
    description: 'Heading tag',
    defaultMessage: 'Övriga kunskaper',
  },
  headingLanguages: {
    id: 'RestaurantProfileModal.headingLanguages',
    description: 'Heading languages',
    defaultMessage: 'Spåkkunskaper',
  },
  fetchUserLoading: {
    id: 'RestaurantProfileModal.fetchUserLoading',
    description: 'Fetch user loading',
    defaultMessage: 'Hämtar profil',
  },
  fetchUserError: {
    id: 'RestaurantProfileModal.fetchUserError',
    description: 'Fetch user error',
    defaultMessage: 'Kunde inte hämta profilen',
  },
  fetchUserEmpty: {
    id: 'RestaurantProfileModal.fetchUserEmpty',
    description: 'Fetch user empty',
    defaultMessage: 'Kunde inte hitta någon profil',
  },
  createChannel: {
    id: 'RestaurantProfileModal.createChannel',
    description: 'Create channel',
    defaultMessage: 'Skicka meddelande',
  },
  hideResource: {
    id: 'RestaurantProfileModal.hideResource',
    description: 'Hide resource',
    defaultMessage: 'Dölj resurs',
  },
  hideResourceSuccessTitle: {
    id: 'RestaurantProfileModal.hideResourceSuccessTitle',
    description: 'Hide resource success title',
    defaultMessage: 'Resursen är dold',
  },
  hideResourceErrorTitle: {
    id: 'RestaurantProfileModal.hideResourceErrorTitle',
    description: 'Hide resource error title',
    defaultMessage: 'Resursen kan inte döljas',
  },
  recommendedBy: {
    id: 'RestaurantProfileModal.recommendedBy',
    description: 'Recommended by',
    defaultMessage: 'Rekommenderas av',
  },
  fetchRecommendationError: {
    id: 'RestaurantProfileModal.fetchRecommendationError',
    description: 'Fetch recommendation error',
    defaultMessage: 'Kunde inte hämta rekommendationer',
  },
  fetchRecommendationsLoading: {
    id: 'RestaurantProfileModal.fetchRecommendationsLoading',
    description: 'Fetch recommendations loading',
    defaultMessage: 'Hämtar rekommendationer',
  },
});
