import {
  ChangeEvent,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { FieldErrors, RegisterOptions, UseFormRegister } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { Icon } from 'components/ui/general';

import styles from './Number.module.scss';

export type NumberProps = {
  name: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  defaultValue?: number;
  step?: number;
  min?: number;
  max?: number;
  className?: string;
  error?: FieldErrors;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  fullWidth?: boolean;
};

export const Number = ({
  name,
  disabled,
  step,
  register,
  validation,
  defaultValue,
  min,
  max,
  className,
  error,
  onChange,
  inputRef,
  fullWidth,
}: NumberProps) => {
  const [currentNumber, setCurrentNumber] = useState(defaultValue);
  const numberRef = useRef<HTMLInputElement | null>(null);

  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  return (
    <>
      <div
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
        })}
      >
        <button
          type="button"
          className={styles.stepDown}
          disabled={disabled}
          onClick={() => {
            numberRef.current?.stepDown();
            numberRef.current?.dispatchEvent(
              new Event('change', { bubbles: true })
            );
          }}
        >
          <Icon name="minus" />
        </button>
        <input
          type="number"
          className={styles.input}
          defaultValue={defaultValue}
          readOnly
          disabled={disabled}
          step={step}
          min={min}
          max={max}
          {...registerHolder}
          ref={(event) => {
            if (inputRef) inputRef.current = event;
            numberRef.current = event;
            registerHolder.ref(event);
          }}
          onChange={(event) => {
            registerHolder.onChange(event);
            onChange?.(event);
            setCurrentNumber(window.Number(event.target.value));
          }}
        />
        <div className={styles.number}>
          {typeof currentNumber === 'number' ? currentNumber : <>&nbsp;</>}
        </div>
        <button
          type="button"
          className={styles.stepUp}
          disabled={disabled}
          onClick={() => {
            numberRef.current?.stepUp();
            numberRef.current?.dispatchEvent(
              new Event('change', { bubbles: true })
            );
          }}
        >
          <Icon name="plus" />
        </button>
      </div>
      <Error error={error} />
    </>
  );
};
