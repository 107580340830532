import { useMemo, useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { Select, SelectProps } from 'components/ui/forms';
import { TableProps } from 'components/ui/table';
import { useTable } from 'hooks';

export type FilterSelectProps = {
  select: {
    name: SelectProps['name'];
    multiLabelWithNumber?: SelectProps['multiLabelWithNumber'];
    placeholder?: SelectProps['placeholder'];
    loading?: SelectProps['loading'];
    options: {
      value: string;
      label: string;
    }[];
  };
  handleQueryParameters: TableProps['handleQueryParameters'];
  onFilterChange: (
    filter: FilterSelectProps['select']['options'],
    selectOptions: FilterSelectProps['select']['options']
  ) => void;
};

export const FilterSelect = ({
  handleQueryParameters,
  select,
  onFilterChange,
}: FilterSelectProps) => {
  const { getFilterQueryParameter } = useTable();
  const { control, setValue } = useForm();

  const defaultValue = useMemo(() => {
    const getFilter = getFilterQueryParameter();

    if (!getFilter?.length || !handleQueryParameters) {
      return undefined;
    }

    return getFilter
      .map((filter) => select.options.find(({ value }) => value === filter))
      .filter((filter) => filter);
  }, [getFilterQueryParameter, select.options, handleQueryParameters]);

  const handleOnChange = useCallback(
    (filter) => {
      const getFilter = filter?.value ? [filter] : filter;
      onFilterChange(getFilter || [], select.options);
    },
    [onFilterChange, select.options]
  );

  useEffect(() => {
    setValue(select.name, defaultValue || []);
  }, [defaultValue, select.name, setValue]);

  return (
    <Select
      name={select.name}
      multiLabelWithNumber={select.multiLabelWithNumber}
      placeholder={select.placeholder || select.multiLabelWithNumber}
      loading={select.loading}
      options={select.options}
      control={control}
      fullWidth
      isClearable
      isSearchable={false}
      isMulti={!!select.multiLabelWithNumber}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleOnChange}
      defaultValue={defaultValue}
    />
  );
};
