import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSendMessageMutation } from 'api/graphql';
import { File } from 'components/ui/forms';
import { Button, Tooltip } from 'components/ui/general';
import { useToast } from 'hooks';

import { texts } from './SendImages.text';

import styles from './SendImages.module.scss';

type SendImagesProps = {
  channelId?: string | number;
  disabled?: boolean;
};

export const SendImages = ({ channelId, disabled }: SendImagesProps) => {
  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const [sendMessage, { loading, error }] = useSendMessageMutation();
  const { register } = useForm();

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.sendImagesError),
        message: error.message,
        type: 'error',
      });
    }
  }, [addToast, error, formatMessage]);

  const onChange = useCallback(
    async ({ target }) => {
      if (target.files?.length && channelId) {
        await sendMessage({
          variables: {
            input: {
              channelId,
              message: ' ', // Will not be rendered
              images: target.files,
            },
          },
        });

        // Reset so onChange works with the same image(s)
        target.value = '';
      }
    },
    [channelId, sendMessage]
  );

  return (
    <Tooltip tip={<FormattedMessage {...texts.tooltip} />} trigger="hover">
      <File
        name="images"
        register={register}
        multiple
        accept="image/jpg, image/jpeg, image/png"
        disabled={disabled || loading}
        onChange={onChange}
        className={styles.file}
        classNameInner={styles.fileInner}
        classNameLabel={styles.fileLabel}
      >
        <Button
          fullWidth
          size="lg"
          className={styles.fileButton}
          color="secondary"
          iconRight={{ name: 'images' }}
          loading={loading}
          as="div"
        />
      </File>
    </Tooltip>
  );
};
