import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  sendImagesError: {
    id: 'SendImages.sendImagesError',
    description: 'Send images error',
    defaultMessage: 'Kunde inte skicka bilden/bilderna',
  },
  tooltip: {
    id: 'SendImages.tooltip',
    description: 'Tooltip',
    defaultMessage: 'Bifoga bild',
  },
});
