import { useCallback } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Image as ImageType } from 'api/graphql';
import { File, FileProps } from 'components/ui/forms';
import { Icon, Image, Spinner } from 'components/ui/general';
import { AvatarSelectors } from 'consts/cypress';
import { imageProxy } from 'utils';

import { texts } from './Avatar.text';

import styles from './Avatar.module.scss';

export type AvatarProps = {
  file?: FileProps;
  image?: ImageType | null;
  imageOptions?: string[];
  loading?: boolean;
  rounded?: boolean;
  aspectRatio: '1:1' | '76:25';
  width: '96px' | '80px' | '64px' | '56px' | '100%';
  className?: string;
  classNameInner?: string;
};

export const Avatar = ({
  file,
  image,
  imageOptions,
  loading,
  rounded,
  aspectRatio,
  width,
  className,
  classNameInner,
}: AvatarProps) => {
  const renderInner = useCallback(
    (isLoading?: boolean) => {
      if (isLoading) {
        return (
          <div className={classNames(styles.inner, classNameInner)}>
            <Spinner visible className={styles.spinner} size="sm" />
          </div>
        );
      }

      return (
        <div className={classNames(styles.inner, classNameInner)}>
          {!!image && (
            <Image
              src={imageProxy({
                // url: image.uri,
                filename: image.filename,
                options: imageOptions,
              })}
              alt="Avatar"
              width={image.width}
              height={image.height}
              cover
              fit="contain"
              spinnerSize="sm"
            />
          )}
          {!!file && !image && (
            <div className={styles.noImage}>
              <Icon name="plus" className={styles.noImageIcon} />
              <div className={styles.noImageText}>
                <FormattedMessage {...texts.noImage} />
              </div>
            </div>
          )}
        </div>
      );
    },
    [image, imageOptions, file, classNameInner]
  );

  const renderContent = useCallback(() => {
    if (loading) {
      return renderInner(true);
    }

    if (file) {
      return (
        <File
          {...file}
          className={classNames(file.className, styles.file)}
          menu={
            file.menu
              ? {
                  ...file.menu,
                  className: classNames(file.menu.className, styles.menu),
                }
              : undefined
          }
        >
          {renderInner()}
        </File>
      );
    }

    return renderInner();
  }, [file, loading, renderInner]);

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.rounded]: rounded!!,
        [styles[`aspectRatio${aspectRatio}`]]: aspectRatio,
        [styles[`width${width}`]]: width,
      })}
      data-cy={AvatarSelectors.Root}
    >
      {renderContent()}
    </div>
  );
};
