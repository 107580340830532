import TagManager, { DataLayerArgs } from 'react-gtm-module';

import { Events } from 'consts/gtm';
import { gdpr } from 'utils';

const gtmId = process.env.REACT_APP_GTM_ID;

const init = () => {
  if (gtmId) {
    TagManager.initialize({
      gtmId,
      dataLayer: {
        event: Events.Consent,
        consent: gdpr.getConsentValue(),
      },
    });
  }
};

const push = (args: DataLayerArgs) => {
  if (gtmId) {
    TagManager.dataLayer(args);
  }
};

export const gtm = {
  init,
  push,
};
