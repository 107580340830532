import { ReactNode } from 'react';

import { UserRole } from 'api/graphql';
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Container,
  Gutter,
  Tabs,
  TabsProps,
  Tag,
} from 'components/ui/general';
import { LocationAvatar, LocationAvatarProps } from 'components/ui/location';
import { UserAvatar, UserAvatarProps } from 'components/ui/user';
import { ContentWithTabsSelectors } from 'consts/cypress';
import { useAuth } from 'hooks';

import styles from './ContentWithTabs.module.scss';

type ContentWithTabsProps = {
  breadcrumbs?: BreadcrumbsProps['crumbs'];
  tabs: TabsProps['panes'];
  heading: ReactNode;
  paragraph?: ReactNode;
  tag?: ReactNode;
  avatar?: {
    user?: UserAvatarProps;
    location?: LocationAvatarProps;
  };
};

export const ContentWithTabs = ({
  breadcrumbs,
  tabs,
  heading,
  paragraph,
  tag,
  avatar = {},
}: ContentWithTabsProps) => {
  const { role } = useAuth();

  return (
    <Container vertical>
      <Gutter gutter={{ bottom: 4 }}>
        {!!breadcrumbs && (
          <Gutter.Item>
            <Breadcrumbs crumbs={breadcrumbs} />
          </Gutter.Item>
        )}
        <Gutter.Item>
          <div className={styles.inner}>
            {(!!avatar.user || !!avatar.location) && (
              <div
                className={styles.avatar}
                data-cy={ContentWithTabsSelectors.Avatar}
              >
                {!!avatar.user && (
                  <UserAvatar
                    fileAvailable={role === UserRole.Admin}
                    {...avatar.user}
                  />
                )}
                {!!avatar.location && (
                  <LocationAvatar
                    fileAvailable={
                      role === UserRole.Admin || role === UserRole.CompanyAdmin
                    }
                    {...avatar.location}
                  />
                )}
              </div>
            )}
            <div className={styles.headingAndParagraph}>
              <Gutter gutter={{ bottom: 1 }}>
                <Gutter.Item>
                  <h5 data-cy={ContentWithTabsSelectors.Heading}>{heading}</h5>
                </Gutter.Item>
                {!!paragraph && (
                  <Gutter.Item>
                    <p className={styles.paragraph}>{paragraph}</p>
                  </Gutter.Item>
                )}
              </Gutter>
            </div>
            {!!tag && (
              <Tag
                className={styles.tag}
                color="info"
                data-cy={ContentWithTabsSelectors.Tag}
              >
                {tag}
              </Tag>
            )}
          </div>
        </Gutter.Item>
        <Gutter.Item>
          <Tabs panes={tabs} />
        </Gutter.Item>
      </Gutter>
    </Container>
  );
};
