import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.region,
  ...shared.form,
  headingSameBilling: {
    id: 'LocationForm.headingSameBilling',
    description: 'Heading same billing',
    defaultMessage: 'Fakturering',
  },
  labelSameBillingAsCompany: {
    id: 'LocationForm.labelSameBillingAsCompany',
    description: 'Label same billing as company',
    defaultMessage: 'Samma som för {companyName}',
  },
});
