import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'DeleteLocationModal.heading',
    description: 'Heading',
    defaultMessage: 'Ta bort arbetsplats',
  },
  paragraphAdmin: {
    id: 'DeleteLocationModal.paragraphAdmin',
    description: 'Paragraph admin',
    defaultMessage: 'Är du säker på att du vill ta bort denna arbetsplats?',
  },
  paragraphNotAdmin: {
    id: 'DeleteLocationModal.paragraphNotAdmin',
    description: 'Paragraph not admin',
    defaultMessage:
      'Vill du ta bort {companyName}/{locationName}, kontakta supporten.',
  },
  button: {
    id: 'DeleteLocationModal.button',
    description: 'Button',
    defaultMessage: 'Ta bort',
  },
  tooltip: {
    id: 'DeleteLocationModal.tooltip',
    description: 'Tooltip',
    defaultMessage: 'Ta bort arbetsplats',
  },
  deleteCompanyLocationSuccess: {
    id: 'DeleteLocationModal.deleteCompanyLocationSuccess',
    description: 'Delete company location success',
    defaultMessage: 'Arbetsplatsen är borttagen',
  },
});
