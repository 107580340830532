import { useCallback, useEffect, useState } from 'react';

import { WrapperProps } from '@googlemaps/react-wrapper';
import { UseFormSetValue } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Company } from 'api/graphql';
import { GoogleMaps } from 'components/tools';
import {
  Checkbox,
  Text,
  TextProps,
  TextWithGoogleMapsAutocomplete,
} from 'components/ui/forms';
import { Grid, Gutter } from 'components/ui/general';
import { LocationFormSelectors } from 'consts/cypress';
import { useGoogleMaps, useValidate } from 'hooks';

import { texts } from '../LocationForm.text';

type BillingProps = {
  company: Company;
  register: TextProps['register'];
  setValue: UseFormSetValue<any>;
  errors: {
    [key: string]: any;
  };
  defaultValues?: {
    [key: string]: any;
  };
  disabled?: boolean;
  showSameBilling?: boolean;
};

export const Billing = ({
  company,
  register,
  setValue,
  errors,
  defaultValues,
  disabled,
  showSameBilling,
}: BillingProps) => {
  const { formatMessage } = useIntl();
  const [sameBillingAsCompany, setSameBillingAsCompany] = useState(false);
  const { isPostalCode } = useValidate();
  const { getAddressComponents } = useGoogleMaps();
  const { isEmail } = useValidate();

  const onPlaceChanged = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const addressComponents = getAddressComponents(place.address_components);
      const options = (value?: string | number) => ({
        shouldValidate: !!value,
      });

      setValue(
        'billingPostalCode',
        addressComponents.postalCode,
        options(addressComponents.postalCode)
      );
      setValue(
        'billingCity',
        addressComponents.postalTown,
        options(addressComponents.postalTown)
      );
    },
    [getAddressComponents, setValue]
  );

  // Because of Google something happens with the default value,
  // so we need to help react-hook-form here
  useEffect(() => {
    if (defaultValues?.billingStreet) {
      setValue('billingStreet', defaultValues.billingStreet);
    }
  }, [defaultValues?.billingStreet, setValue]);

  const renderBillingStreet = useCallback<NonNullable<WrapperProps['render']>>(
    (status) => (
      <TextWithGoogleMapsAutocomplete
        text={{
          register,
          name: 'billingStreet',
          label: formatMessage(texts.form_labelBillingStreet),
          validation: { required: true },
          error: errors?.billingStreet,
          fullWidth: true,
          defaultValue: defaultValues?.billingStreet,
          disabled,
        }}
        status={status}
        onPlaceChanged={onPlaceChanged}
      />
    ),
    [
      register,
      formatMessage,
      errors?.billingStreet,
      defaultValues?.billingStreet,
      disabled,
      onPlaceChanged,
    ]
  );

  return (
    <Gutter gutter={{ bottom: 3 }}>
      <Gutter.Item>
        <h6>
          <FormattedMessage {...texts.headingSameBilling} />
        </h6>
      </Gutter.Item>
      <Gutter.Item>
        <Gutter gutter={{ bottom: 2 }}>
          {!!showSameBilling && (
            <Gutter.Item>
              <Checkbox
                register={register}
                name="sameBillingAsCompany"
                label={formatMessage(texts.labelSameBillingAsCompany, {
                  companyName: company.name,
                })}
                onChange={({ target }) =>
                  setSameBillingAsCompany(target.checked)
                }
                disabled={disabled}
              />
            </Gutter.Item>
          )}
          {!sameBillingAsCompany && (
            <>
              <Gutter.Item data-cy={LocationFormSelectors.BillingStreet}>
                <GoogleMaps
                  render={renderBillingStreet}
                  libraries={['places']}
                />
              </Gutter.Item>
              <Gutter.Item>
                <Grid gutter={{ left: 1 }}>
                  <Grid.Item
                    width={6}
                    data-cy={LocationFormSelectors.BillingPostalCode}
                  >
                    <Text
                      name="billingPostalCode"
                      label={formatMessage(texts.form_labelBillingPostalCode)}
                      register={register}
                      validation={{
                        required: true,
                        validate: isPostalCode,
                      }}
                      error={errors?.billingPostalCode}
                      fullWidth
                      defaultValue={defaultValues?.billingPostalCode}
                      disabled={disabled}
                    />
                  </Grid.Item>
                  <Grid.Item
                    width={6}
                    data-cy={LocationFormSelectors.BillingCity}
                  >
                    <Text
                      name="billingCity"
                      label={formatMessage(texts.form_labelBillingCity)}
                      register={register}
                      validation={{ required: true }}
                      error={errors?.billingCity}
                      fullWidth
                      defaultValue={defaultValues?.billingCity}
                      disabled={disabled}
                    />
                  </Grid.Item>
                </Grid>
              </Gutter.Item>
            </>
          )}
          <Gutter.Item data-cy={LocationFormSelectors.EInvoice}>
            <Text
              name="eInvoice"
              label={formatMessage(texts.form_labelEInvoice)}
              register={register}
              validation={{
                validate: (value) => value?.length && isEmail(value),
              }}
              error={errors?.eInvoice}
              fullWidth
              defaultValue={defaultValues?.eInvoice}
              disabled={disabled}
            />
          </Gutter.Item>
          <Gutter.Item data-cy={LocationFormSelectors.BillingReference}>
            <Text
              name="billingReference"
              label={formatMessage(texts.form_labelBillingReference)}
              register={register}
              validation={{ required: true }}
              error={errors?.billingReference}
              fullWidth
              defaultValue={defaultValues?.billingReference}
              disabled={disabled}
            />
          </Gutter.Item>
        </Gutter>
      </Gutter.Item>
    </Gutter>
  );
};
