import { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Company, useLocationsLazyQuery } from 'api/graphql';
import { Button, Empty } from 'components/ui/general';
import { Table, TableFetchData } from 'components/ui/table';
import { LocationsTableSelectors } from 'consts/cypress';
import { Paths } from 'consts/router';
import { table } from 'utils';

import { texts } from './LocationsTable.text';

type LocationsTableProps = {
  company: Company;
};

export const LocationsTable = ({ company }: LocationsTableProps) => {
  const [fetchLocations, { data, loading, error }] = useLocationsLazyQuery();

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnNameLocation} />,
        accessor: 'name',
      },
      {
        Header: <FormattedMessage {...texts.table_columnAddress} />,
        accessor: 'street',
        Cell: ({
          row: {
            original: { street, postalCode, city },
          },
        }: any) => `${street}, ${postalCode} ${city}`,
      },
      {
        Header: <FormattedMessage {...texts.table_columnContactUser} />,
        accessor: 'contactUser.firstName',
        Cell: ({
          row: {
            original: { contactUser },
          },
        }: any) => `${contactUser.firstName} ${contactUser.lastName}`,
      },
      {
        Header: <FormattedMessage {...texts.table_columnMobileNumber} />,
        accessor: 'contactUser.mobileNumber',
      },
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => (
          <Button
            iconRight={{ name: 'angle-right' }}
            naked
            stripPadding
            size="sm"
            color="dark"
            to={Paths.CompanyLocation(company.id, id)}
            data-cy={LocationsTableSelectors.Pick}
          />
        ),
      },
    ],
    [company.id]
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, searchTerm }: TableFetchData) => {
      fetchLocations({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            searchTerm,
            companyId: company.id,
          },
          sorting: {
            sorting: sortBy.map(({ id, desc }) => ({
              field: id,
              direction: desc ? 'desc' : 'asc',
            })),
          },
        },
      });
    },
    [fetchLocations, company]
  );

  return (
    <Table
      columns={columns}
      data={data?.locations.edges || []}
      onFetchData={fetchData}
      pagination
      search
      loading={loading}
      totalPages={table.getTotalPages(data?.locations.meta)}
      sort
      topStripPaddingSides
      headBackground
      lastTdRight
      empty={
        <Empty
          title={<FormattedMessage {...texts.table_emptyTable} />}
          message={error?.message}
        />
      }
    />
  );
};
