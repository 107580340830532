import { useCallback } from 'react';

import union from 'lodash/union';
import { Moment } from 'moment';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import { FilterSelectProps } from 'components/ui/table';
import { DateFormat } from 'consts/date';
import { QueryParameters } from 'consts/table';

export const useTable = () => {
  const [getAllQueryParameters, setAllQueryParameters] = useQueryParams(
    {
      [QueryParameters.PageIndex]: NumberParam,
      [QueryParameters.Filter]: ArrayParam,
      [QueryParameters.SearchTerm]: StringParam,
      [QueryParameters.SortById]: StringParam,
      [QueryParameters.SortByDesc]: BooleanParam,
    },
    {
      updateType: 'replaceIn',
    }
  );

  const clearAllQueryParameters = useCallback(() => {
    setAllQueryParameters({
      pageIndex: undefined,
      filter: undefined,
      searchTerm: undefined,
      sortById: undefined,
      sortByDesc: undefined,
    });
  }, [setAllQueryParameters]);

  const getPageIndexQueryParameter = useCallback(
    () => getAllQueryParameters.pageIndex || undefined,
    [getAllQueryParameters.pageIndex]
  );

  const getSearchTermQueryParameter = useCallback(
    () => getAllQueryParameters.searchTerm || undefined,
    [getAllQueryParameters.searchTerm]
  );

  const getSortingQueryParameter = useCallback(() => {
    if (
      getAllQueryParameters.sortById &&
      typeof getAllQueryParameters.sortByDesc === 'boolean'
    ) {
      return [
        {
          id: getAllQueryParameters.sortById,
          desc: getAllQueryParameters.sortByDesc,
        },
      ];
    }
  }, [getAllQueryParameters.sortByDesc, getAllQueryParameters.sortById]);

  const getFilterQueryParameter = useCallback(() => {
    if (!getAllQueryParameters.filter) {
      return;
    }

    return getAllQueryParameters.filter.filter((item) => item) as string[];
  }, [getAllQueryParameters.filter]);

  const getFilterValuesForSelect = useCallback(
    (
      filters: { [key: string]: string }[],
      selectOptions: FilterSelectProps['select']['options'],
      currentFilters?: string[]
    ) => {
      let collectFilters: string[] = currentFilters || [];

      selectOptions.forEach(({ value }) => {
        const findOption = filters.find((filter) => filter.value === value);

        if (findOption) {
          const filterIsAlreadyApplied = collectFilters.find(
            (filter) => filter === value
          );

          if (!filterIsAlreadyApplied) {
            collectFilters = [...collectFilters, value];
          }

          return;
        }

        collectFilters = collectFilters.filter((filter) => filter !== value);
      });

      return collectFilters;
    },
    []
  );

  const setFilterQueryParameterForSelect = useCallback(
    (
      filters: { [key: string]: string }[],
      selectOptions: FilterSelectProps['select']['options']
    ) => {
      selectOptions.forEach(({ value }) => {
        const findOption = filters.find((item) => item.value === value);

        if (findOption) {
          setAllQueryParameters((previousQueryParameters) => ({
            ...previousQueryParameters,
            filter: union(previousQueryParameters.filter || [], [value]),
          }));
          return;
        }

        setAllQueryParameters((previousQueryParameters) => ({
          ...previousQueryParameters,
          filter: previousQueryParameters.filter?.filter(
            (item) => item !== value
          ),
        }));
      });
    },
    [setAllQueryParameters]
  );

  const getFilterValuesForDate = useCallback(
    (date: Moment | null, name: string, currentFilters?: string[]) => {
      let collectFilters: string[] = (currentFilters || []).filter(
        (item) => !item?.startsWith(name)
      );

      if (date) {
        const formatDate = date?.format(DateFormat.DateStamp);
        collectFilters = [...collectFilters, `${name}__${formatDate}`];
      }

      return collectFilters;
    },
    []
  );

  const setFilterQueryParameterForDate = useCallback(
    (date: Moment | null, name: string) => {
      const formatDate = date?.format(DateFormat.DateStamp);
      const value = formatDate ? `${name}__${formatDate}` : undefined;

      if (value) {
        setAllQueryParameters((previousQueryParameters) => ({
          ...previousQueryParameters,
          filter: union(
            previousQueryParameters.filter?.filter(
              (item) => !item?.startsWith(name)
            ) || [],
            [value]
          ),
        }));
        return;
      }

      setAllQueryParameters((previousQueryParameters) => ({
        ...previousQueryParameters,
        filter: previousQueryParameters.filter?.filter(
          (item) => !item?.startsWith(name)
        ),
      }));
    },
    [setAllQueryParameters]
  );

  return {
    getAllQueryParameters,
    setAllQueryParameters,
    clearAllQueryParameters,
    getPageIndexQueryParameter,
    getFilterQueryParameter,
    setFilterQueryParameterForSelect,
    getFilterValuesForSelect,
    getSearchTermQueryParameter,
    getSortingQueryParameter,
    setFilterQueryParameterForDate,
    getFilterValuesForDate,
  };
};
