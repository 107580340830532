import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import union from 'lodash/union';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Region,
  ResourceCategoriesDocument,
  ResourceCategoriesQuery,
  ResourceCategoriesQueryVariables,
  ResourceLevelsDocument,
  ResourceLevelsQuery,
  ResourceLevelsQueryVariables,
  RestaurantProfile,
  RestaurantVerticalCategory,
  RestaurantWorkExperience,
  UserRole,
  useCustomResourcesBroadcastLazyQuery,
  Scalars,
  CustomResourcesBroadcastDocument,
  CustomResourcesBroadcastQuery,
  CustomResourcesBroadcastQueryVariables,
} from 'api/graphql';
import { BroadcastSendMessageModal } from 'components/ui/chat';
import { Checkbox } from 'components/ui/forms';
import {
  Button,
  Container,
  Empty,
  Grid,
  Icon,
  Tag,
} from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { TranslationsContext } from 'context/translations';
import { useLazyQueryInterval } from 'hooks';
import { resource, table, typeGuards } from 'utils';

import { texts } from './BroadcastSelectionResourcesModal.text';

import styles from './BroadcastSelectionResourcesModal.module.scss';

type BroadcastSelectionResourcesModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const BroadcastSelectionResourcesModal = ({
  isOpen,
  setIsOpen,
}: BroadcastSelectionResourcesModalProps) => {
  const { formatMessage } = useIntl();
  const [lang] = useContext(TranslationsContext);
  const { register, reset } = useForm();

  const [isBroadcastSendMessageModalOpen, setIsBroadcastSendMessageModalOpen] =
    useState(false);
  const [broadcastSendMessageUserIds, setBroadcastSendMessageUserIds] =
    useState<Scalars['ID'][]>([]);
  const [variables, setVariables] =
    useState<CustomResourcesBroadcastQueryVariables>();

  const [fetchResources, { data, loading, error }] =
    useCustomResourcesBroadcastLazyQuery();

  useEffect(() => {
    if (!isOpen && !isBroadcastSendMessageModalOpen) {
      reset();
      setBroadcastSendMessageUserIds([]);
    }
  }, [isOpen, isBroadcastSendMessageModalOpen, reset]);

  const [fetchResourceLevels, resourceLevels] = useLazyQueryInterval<
    ResourceLevelsQuery,
    ResourceLevelsQueryVariables
  >({
    document: ResourceLevelsDocument,
    name: 'resourceLevels',
  });

  useEffect(() => {
    if (isOpen) {
      fetchResourceLevels();
    }
  }, [isOpen, fetchResourceLevels]);

  const [fetchResourceCategories, resourceCategories] = useLazyQueryInterval<
    ResourceCategoriesQuery,
    ResourceCategoriesQueryVariables
  >({
    document: ResourceCategoriesDocument,
    name: 'resourceCategories',
  });

  useEffect(() => {
    if (isOpen) {
      fetchResourceCategories({
        variables: {
          filter: {
            groupOnName: true,
          },
        },
      });
    }
  }, [isOpen, fetchResourceCategories]);

  const [fetchAllResources, allResources] = useLazyQueryInterval<
    CustomResourcesBroadcastQuery,
    CustomResourcesBroadcastQueryVariables
  >({
    document: CustomResourcesBroadcastDocument,
    name: 'users',
  });

  const handleSelectAll = useCallback(() => {
    fetchAllResources({
      variables: {
        ...variables,
        filter: {
          ...variables?.filter,
          offset: 0,
          limit: 500,
        },
      },
    });
  }, [fetchAllResources, variables]);

  useEffect(() => {
    const { edges, meta } = allResources.data?.users || {};

    if (edges?.length === meta?.total && !allResources.loading) {
      setBroadcastSendMessageUserIds((prevBroadcastSendMessageUserIds) => {
        return union(
          prevBroadcastSendMessageUserIds,
          edges?.map(({ id }) => id)
        );
      });
    }
  }, [allResources.data?.users, allResources.loading]);

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.table_columnName} />,
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }: any) => `${firstName} ${lastName}`,
      },
      {
        Header: <FormattedMessage {...texts.table_columnRegion} />,
        accessor: 'restaurantProfile.region',
        Cell: ({ row: { original } }: any) => {
          const restaurantProfile: RestaurantProfile | undefined =
            original.userProfile.find((userProfile: RestaurantProfile) =>
              typeGuards.restaurantProfile(userProfile)
            );

          if (restaurantProfile?.region) {
            return formatMessage(texts[`region_${restaurantProfile.region}`]);
          }

          return null;
        },
      },
      {
        Header: <FormattedMessage {...texts.table_columnWorkExperience} />,
        disableSortBy: true,
        accessor: 'restaurantProfile.workExperience',
        Cell: ({ row: { original } }: any) => {
          const restaurantProfile: RestaurantProfile | undefined =
            original.userProfile.find((userProfile: RestaurantProfile) =>
              typeGuards.restaurantProfile(userProfile)
            );

          if (restaurantProfile) {
            return (
              <Grid gutter={{ left: 0.5, bottom: 0.5 }} align="start">
                {restaurantProfile.workExperience.map(
                  ({ category }: RestaurantWorkExperience) => (
                    <Grid.Item key={category}>
                      <Tag
                        size="sm"
                        color="nu"
                        className={styles.workExperience}
                        nowrap
                      >
                        <Icon
                          font="ericeira"
                          name={
                            resource.iconsRestaurantVerticalCategory[category]
                          }
                        />
                        <div className={styles.workExperienceText}>
                          <FormattedMessage
                            {...texts[`restaurantVerticalCategory_${category}`]}
                          />
                        </div>
                      </Tag>
                    </Grid.Item>
                  )
                )}
              </Grid>
            );
          }
          return null;
        },
      },
      {
        Header: (
          <Button
            size="xs"
            color="secondary"
            onClick={handleSelectAll}
            disabled={allResources.loading}
            loading={allResources.loading}
            className={styles.selectAllButton}
          >
            <FormattedMessage {...texts.buttonSelectAll} />
          </Button>
        ),
        disableSortBy: true,
        accessor: 'selection',
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => (
          <div className={styles.selectAllCheckboxHolder}>
            <Checkbox
              register={register}
              name={`selection.${id}`}
              defaultChecked={broadcastSendMessageUserIds.includes(id)}
              onChange={({ target: { checked } }) => {
                setBroadcastSendMessageUserIds(
                  (prevBroadcastSendMessageUserIds) => {
                    if (checked) {
                      return [...prevBroadcastSendMessageUserIds, id];
                    }

                    return prevBroadcastSendMessageUserIds.filter(
                      (prevId) => prevId !== id
                    );
                  }
                );
              }}
            />
          </div>
        ),
      },
    ],
    [
      handleSelectAll,
      allResources.loading,
      register,
      broadcastSendMessageUserIds,
      formatMessage,
    ]
  );

  const filter = useMemo(
    () => [
      {
        select: {
          name: 'resourceCategoryNames',
          multiLabelWithNumber: formatMessage(
            texts.filterResourceCategoryNamesLabel
          ),
          loading: resourceCategories.loading,
          options:
            resourceCategories.data?.resourceCategories.edges.map(
              ({ name }) => ({
                label: formatMessage(
                  texts[
                    `restaurantVerticalCategory_${
                      name as RestaurantVerticalCategory
                    }`
                  ]
                ),
                value: `resourceCategoryNames__${name}`,
              })
            ) || [],
        },
      },
      {
        select: {
          name: 'resourceCategoryLevels',
          multiLabelWithNumber: formatMessage(
            texts.filterResourceCategoryLevelsLabel
          ),
          loading: resourceLevels.loading,
          options:
            resourceLevels.data?.resourceLevels.edges.map(({ id, name }) => ({
              label: name,
              value: `resourceCategoryLevels__${id}`,
            })) || [],
        },
      },
      {
        select: {
          name: 'region',
          multiLabelWithNumber: formatMessage(texts.filterRegionLabel),
          options: Object.values(Region)
            .map((region) => ({
              label: formatMessage(texts[`region_${region}`]),
              value: `region__${region}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label, lang)),
        },
      },
    ],
    [
      formatMessage,
      lang,
      resourceCategories.data?.resourceCategories.edges,
      resourceCategories.loading,
      resourceLevels.data?.resourceLevels.edges,
      resourceLevels.loading,
    ]
  );

  const fetchData = useCallback(
    ({
      pageIndex,
      pageSize,
      sortBy,
      searchTerm,
      activeFilter,
    }: TableFetchData) => {
      if (!isOpen) return;

      const defaultSorting = [
        {
          field: 'createdAt',
          direction: 'desc',
        },
      ];

      const { resourceCategoryNames, resourceCategoryLevels, region } =
        table.getFilters(activeFilter);

      const collectVariables = {
        filter: {
          offset: pageIndex * pageSize,
          limit: pageSize,
          searchTerm,
          roles: [UserRole.User],
          resourceCategoryNames,
          resourceCategoryLevels,
          region,
        },
        sorting: {
          sorting: sortBy.length
            ? sortBy.map(({ id, desc }) => ({
                field: id,
                direction: desc ? 'desc' : 'asc',
              }))
            : defaultSorting,
        },
      };

      setVariables(collectVariables);
      fetchResources({ variables: collectVariables });
    },
    [isOpen, fetchResources]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        middleSize="lg"
        middleStripPadding
      >
        <Container fullWidth vertical>
          <h5>
            <FormattedMessage {...texts.heading} />
          </h5>
        </Container>
        <Table
          search
          pagination
          isInModalMiddle
          sort
          lastTdRight
          totalPages={table.getTotalPages(data?.users.meta)}
          columns={columns}
          data={data?.users.edges || []}
          onFetchData={fetchData}
          loading={loading}
          filter={filter}
          handleQueryParameters={false}
          topBeside={
            <Button
              iconLeft={{ name: 'plus' }}
              as="div"
              disabled={!broadcastSendMessageUserIds.length}
              onClick={() => {
                setIsOpen(false);
                setIsBroadcastSendMessageModalOpen(true);
              }}
            >
              <FormattedMessage
                {...texts.buttonWriteMessage}
                values={{ amount: broadcastSendMessageUserIds.length }}
              />
            </Button>
          }
          empty={
            <Empty
              title={<FormattedMessage {...texts.table_emptyTable} />}
              message={error?.message}
            />
          }
        />
      </Modal>
      <BroadcastSendMessageModal
        isOpen={isBroadcastSendMessageModalOpen}
        setIsOpen={(broadcastSendMessageModalIsOpen) => {
          if (!broadcastSendMessageModalIsOpen) {
            setIsBroadcastSendMessageModalOpen(false);
            setIsOpen(true);
          }
        }}
        userIds={broadcastSendMessageUserIds}
      />
    </>
  );
};
