import { CustomMessagesQuery } from 'api/graphql';
import { Image } from 'components/ui/general';
import { imageProxy } from 'utils';

import styles from '../Message.module.scss';

type MessageImageProps = {
  image: CustomMessagesQuery['messages']['edges'][0]['images'][0];
};

export const MessageImage = ({ image }: MessageImageProps) => {
  const { uri, width, height, originalName } = image;

  return (
    <div className={styles.imageHolder}>
      <div className={styles.imageInner} style={{ width }}>
        <Image
          src={imageProxy({ url: uri, options: ['420x'] })}
          width={width}
          height={height}
          alt={originalName}
          spinnerColor="dark"
          placeholder="mu"
          className={styles.image}
          spinnerSize="sm"
        />
      </div>
    </div>
  );
};
