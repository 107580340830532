import { User } from 'api/graphql';
import { ContentCard, Grid, Gutter } from 'components/ui/general';
import { UserAccountSelectors } from 'consts/cypress';

import styles from './UserAccount.module.scss';

type UserAccountProps = {
  user: User;
};

export const UserAccount = ({ user }: UserAccountProps) => {
  const { firstName, lastName, email, mobileNumber } = user;

  return (
    <Grid>
      <Grid.Item width={4}>
        <ContentCard size="lg">
          <Gutter gutter={{ bottom: 1 }}>
            <Gutter.Item>
              <p
                className={styles.name}
                data-cy={UserAccountSelectors.Name}
              >{`${firstName} ${lastName}`}</p>
            </Gutter.Item>
            <Gutter.Item>
              <p>{email}</p>
            </Gutter.Item>
            {!!mobileNumber && (
              <Gutter.Item>
                <p>{mobileNumber}</p>
              </Gutter.Item>
            )}
          </Gutter>
        </ContentCard>
      </Grid.Item>
    </Grid>
  );
};
