import moment, { MomentFormatSpecification, MomentInput } from 'moment';

const formatDate = ({
  date,
  format,
  strict,
}: {
  date?: MomentInput;
  format?: MomentFormatSpecification;
  strict?: boolean;
} = {}) => {
  return moment(date, format, strict);
};

const formatCurrency = (
  number: number,
  options?: Intl.NumberFormatOptions & { onlySymbol?: boolean }
) => {
  const locale = 'sv-SE';

  if (typeof number !== 'number') {
    return number;
  }

  const currency = number.toLocaleString(locale, {
    style: 'currency',
    currency: 'SEK',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
    ...options,
  });

  if (options?.onlySymbol) {
    return currency.replace(/[0-9]/g, '').trim();
  }

  return currency;
};

export const format = {
  date: formatDate,
  currency: formatCurrency,
};
