import { FormattedMessage } from 'react-intl';

import { RestaurantProfile } from 'api/graphql';
import { Grid, Icon } from 'components/ui/general';

import { texts } from '../RestaurantProfileModal.text';

import styles from '../RestaurantProfileModal.module.scss';

type CollectionSectionProps = {
  mobileNumber?: string | null;
  restaurantProfile: RestaurantProfile;
};

export const CollectionSection = ({
  mobileNumber,
  restaurantProfile,
}: CollectionSectionProps) => {
  return (
    <div className={styles.collectionSection}>
      <Grid gutter={{ left: 3, bottom: 3 }}>
        <Grid.Item>
          <div className={styles.information}>
            <Icon name="map-marker-alt" className={styles.informationIcon} />
            <FormattedMessage
              {...texts[`region_${restaurantProfile.region}`]}
            />
          </div>
        </Grid.Item>
        {!!mobileNumber && (
          <Grid.Item>
            <div className={styles.information}>
              <Icon name="phone" className={styles.informationIcon} />
              {mobileNumber}
            </div>
          </Grid.Item>
        )}
      </Grid>
    </div>
  );
};
