import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  linkLanding: {
    id: 'Header.linkLanding',
    description: 'Link landing',
    defaultMessage: 'Dashboard',
  },
  linkCompanies: {
    id: 'Header.linkCompanies',
    description: 'Link companies',
    defaultMessage: 'Kunder',
  },
  linkCompany: {
    id: 'Header.linkCompany',
    description: 'Link company',
    defaultMessage: 'Företag',
  },
  linkAssignments: {
    id: 'Header.linkAssignments',
    description: 'Link assignments',
    defaultMessage: 'Historik',
  },
  linkNotifications: {
    id: 'Header.linkNotifications',
    description: 'Link notifications',
    defaultMessage: 'Notiser/händelser',
  },
  linkSettings: {
    id: 'Header.linkSettings',
    description: 'Link settings',
    defaultMessage: 'Kontoinställningar',
  },
  linkResources: {
    id: 'Header.linkResources',
    description: 'Link resources',
    defaultMessage: 'Resurser',
  },
  linkResourceCategories: {
    id: 'Header.linkResourceCategories',
    description: 'Link resource categories',
    defaultMessage: 'Resurskategorier',
  },
  linkUsers: {
    id: 'Header.linkUsers',
    description: 'Link users',
    defaultMessage: 'Användare',
  },
  linkTemplates: {
    id: 'Header.linkTemplates',
    description: 'Link templates',
    defaultMessage: 'Bokningsmallar',
  },
  linkAttests: {
    id: 'Header.linkAttests',
    description: 'Link attests',
    defaultMessage: 'Attestering',
  },
  linkBookings: {
    id: 'Header.linkBookings',
    description: 'Link bookings',
    defaultMessage: 'Bokningar',
  },
  linkLogout: {
    id: 'Header.linkLogout',
    description: 'Link logout',
    defaultMessage: 'Logga ut',
  },
});
