import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

import classNames from 'classnames';

import { Gutter, Image } from 'components/ui/general';
import { Modal, ModalProps } from 'components/ui/modals';
import { imageProxy } from 'utils';

import styles from './ImageModal.module.scss';

type ImageModalProps = {
  image: {
    height: number;
    width: number;
    src: string;
    alt: string;
  };
  trigger?: ReactNode;
  triggerDisabled?: boolean;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  classNameTrigger?: string;
  heading: ReactNode;
  size?: ModalProps['middleSize'];
};

export const ImageModal = ({
  image,
  isOpen,
  setIsOpen,
  trigger,
  triggerDisabled,
  classNameTrigger,
  heading,
  size = 'md',
}: ImageModalProps) => {
  const [controlledIsOpen, setControlledIsOpen] = useState(false);

  const getIsOpen = useMemo(
    () => (typeof isOpen === 'boolean' ? isOpen : controlledIsOpen),
    [controlledIsOpen, isOpen]
  );

  const getSetIsOpen = useMemo(
    () => (typeof setIsOpen === 'function' ? setIsOpen : setControlledIsOpen),
    [setIsOpen]
  );

  return (
    <>
      {!!trigger && (
        <button
          type="button"
          onClick={() => getSetIsOpen(true)}
          className={classNames(styles.trigger, classNameTrigger)}
          disabled={triggerDisabled}
        >
          {trigger}
        </button>
      )}
      <Modal
        isOpen={getIsOpen}
        onClose={() => getSetIsOpen(false)}
        middleSize={size}
      >
        <Gutter gutter={{ bottom: 3 }}>
          <Gutter.Item>
            <h6>{heading}</h6>
          </Gutter.Item>
          <Gutter.Item>
            <Image
              {...image}
              src={imageProxy({ url: image.src, options: ['544x'] })}
              placeholder="mu"
              className={styles.image}
            />
          </Gutter.Item>
        </Gutter>
      </Modal>
    </>
  );
};
