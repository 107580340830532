import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { MutationHookOptions } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ChannelOrigin,
  CustomSetLocationHiddenMutation,
  CustomSetLocationHiddenMutationVariables,
  SetLocationHiddenMutationVariables,
  UserRole,
  UsersDocument,
  useCustomRestaurantUsersLazyQuery,
  useCustomSetLocationHiddenMutation,
  useCustomRecommendationsLazyQuery,
} from 'api/graphql';
import {
  Avatar,
  Button,
  Grid,
  Gutter,
  Information,
  Spinner,
  Tag,
} from 'components/ui/general';
import { ToggleLocationFavourite } from 'components/ui/location';
import { Modal } from 'components/ui/modals';
import { useCreateChannel, useAuth, useToast } from 'hooks';
import { typeGuards } from 'utils';

import {
  CollectionSection,
  LanguagesSection,
  TagsSection,
  WorkExperienceSection,
} from './subcomponents';

import { texts } from './RestaurantProfileModal.text';

import styles from './RestaurantProfileModal.module.scss';

type RestaurantProfileModalProps = {
  userId: string | number;
  trigger?: ReactNode;
  classNameTrigger?: string;
  bottom?: ReactNode;
  locationId: string | number;
  renderLocationHiddenButton?: boolean;
};

export const RestaurantProfileModal = ({
  userId,
  trigger,
  classNameTrigger,
  bottom,
  locationId,
  renderLocationHiddenButton = false,
}: RestaurantProfileModalProps) => {
  const { role } = useAuth();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [fetchUsers, { data, error, loading }] =
    useCustomRestaurantUsersLazyQuery();
  const { createChannel, ...createChannelData } = useCreateChannel();
  const user = useMemo(() => data?.users.edges?.[0], [data]);
  const restaurantProfile = useMemo(
    () =>
      user?.userProfile?.map((profile) =>
        typeGuards.restaurantProfile(profile)
      )[0],
    [user]
  );

  const setLocationMutationBaseOptions: MutationHookOptions<
    CustomSetLocationHiddenMutation,
    CustomSetLocationHiddenMutationVariables
  > = {
    refetchQueries: [UsersDocument],
    onError: (onLocationHiddenError) => {
      addToast({
        title: formatMessage(texts.hideResourceErrorTitle),
        message: onLocationHiddenError.message,
        type: 'error',
      });
    },
  };
  const [setLocationHidden, { loading: setLocationHiddenLoading }] =
    useCustomSetLocationHiddenMutation(setLocationMutationBaseOptions);

  useEffect(() => {
    if (isOpen) {
      fetchUsers({
        variables: {
          filter: {
            userIds: [userId],
            locationFavourite: locationId,
            includePassive: true,
          },
        },
      });
    }
  }, [fetchUsers, isOpen, userId, locationId]);

  const onSetResourceHide = useCallback(async () => {
    const variables: SetLocationHiddenMutationVariables = {
      id: locationId,
      userId,
      hidden: true,
    };
    const { data: setLocationHiddenResponse } = await setLocationHidden({
      variables,
    });

    if (setLocationHiddenResponse?.setLocationHidden?.id) {
      setIsOpen(false);

      addToast({
        title: formatMessage(texts.hideResourceSuccessTitle),
        type: 'success',
      });
    }
  }, [addToast, formatMessage, locationId, setLocationHidden, userId]);

  const [fetchRecommendations, recommendations] =
    useCustomRecommendationsLazyQuery();

  useEffect(() => {
    if (isOpen && user) {
      fetchRecommendations({
        variables: {
          filter: { user: user.id },
          sorting: {
            sorting: [
              {
                field: 'createdAt',
                direction: 'desc',
              },
            ],
          },
        },
      });
    }
  }, [fetchRecommendations, isOpen, user]);

  const renderRecommendations = useCallback(() => {
    if (recommendations.loading) {
      return (
        <Gutter.Item>
          <Spinner
            textAlign="right"
            visible
            color="primary"
            text={<FormattedMessage {...texts.fetchRecommendationsLoading} />}
          />
        </Gutter.Item>
      );
    }

    if (!recommendations.data) {
      return null;
    }

    const locations = recommendations.data.recommendations.edges.map(
      ({ location }) => location
    );

    if (locations?.length === 0) {
      return null;
    }

    return (
      <Gutter.Item>
        <Gutter gutter={{ bottom: 2 }}>
          <Gutter.Item>
            <p className={styles.sectionHeading}>
              <FormattedMessage {...texts.recommendedBy} />
            </p>
          </Gutter.Item>
          <Gutter.Item>
            <Grid gutter={{ left: 1, bottom: 1 }}>
              {locations?.slice(0, 3).map(({ name, id }) => (
                <Grid.Item key={id}>
                  <Tag color="mu">{name}</Tag>
                </Grid.Item>
              ))}
            </Grid>
          </Gutter.Item>
        </Gutter>
      </Gutter.Item>
    );
  }, [recommendations.data, recommendations.loading]);

  const renderRecommendationsIcon = useCallback(() => {
    return (
      <Grid.Item>
        <Button
          color="info"
          size="sm"
          iconLeft={{ name: 'thumbs-up' }}
          viewOnly
          as="div"
          loading={recommendations.loading}
        >
          {String(recommendations.data?.recommendations.meta.total || 0)}
        </Button>
      </Grid.Item>
    );
  }, [
    recommendations.data?.recommendations.meta.total,
    recommendations.loading,
  ]);

  useEffect(() => {
    if (recommendations.error) {
      addToast({
        title: formatMessage(texts.fetchRecommendationError),
        message: recommendations.error.message,
        type: 'error',
      });
    }
  }, [addToast, recommendations.error, formatMessage]);

  const renderContent = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Spinner
            visible
            color="primary"
            text={<FormattedMessage {...texts.fetchUserLoading} />}
          />
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.error}>
          <Information status="error">
            <FormattedMessage {...texts.fetchUserError} />
          </Information>
        </div>
      );
    }

    if (!restaurantProfile || !user) {
      return (
        <div className={styles.empty}>
          <Information>
            <FormattedMessage {...texts.fetchUserEmpty} />
          </Information>
        </div>
      );
    }

    const { id, image, firstName, lastName, mobileNumber, favourite } = user;
    const { description } = restaurantProfile;

    return (
      <>
        <div className={styles.top}>
          <Gutter gutter={{ bottom: 3 }}>
            <Gutter.Item>
              <div className={styles.topInner}>
                <div className={styles.avatarHolder}>
                  <Avatar
                    image={image}
                    imageOptions={['96x']}
                    rounded
                    aspectRatio="1:1"
                    width="96px"
                  />
                  {role !== UserRole.Admin && (
                    <ToggleLocationFavourite
                      userId={id}
                      defaultFavourite={favourite}
                      locationId={locationId}
                      className={styles.toggleLocationFavourite}
                    >
                      {({ isFavourited, isLoading }) => (
                        <Button
                          iconRight={{
                            name: 'heart',
                            font: isFavourited ? 'colombo' : 'madrid',
                          }}
                          rounded
                          size="sm"
                          color="error"
                          as="div"
                          loading={isLoading}
                          disabled={isLoading}
                        />
                      )}
                    </ToggleLocationFavourite>
                  )}
                </div>
                <div>
                  <Gutter gutter={{ bottom: 3 }}>
                    <Gutter.Item>
                      <h5>{`${firstName} ${lastName}`}</h5>
                    </Gutter.Item>
                    <Gutter.Item>
                      <Gutter gutter={{ bottom: 2 }}>
                        <Gutter.Item>
                          <Grid gutter={{ left: 1, bottom: 1 }} align="center">
                            {typeof restaurantProfile?.approvedBookings ===
                              'number' && (
                              <Grid.Item>
                                <Button
                                  color="info"
                                  size="sm"
                                  iconLeft={{ name: 'briefcase' }}
                                  viewOnly
                                  as="div"
                                >
                                  {String(restaurantProfile.approvedBookings)}
                                </Button>
                              </Grid.Item>
                            )}
                            {typeof restaurantProfile?.favouriteCount ===
                              'number' && (
                              <Grid.Item>
                                <Button
                                  color="info"
                                  size="sm"
                                  iconLeft={{ name: 'star' }}
                                  viewOnly
                                  as="div"
                                >
                                  {String(restaurantProfile.favouriteCount)}
                                </Button>
                              </Grid.Item>
                            )}
                            {renderRecommendationsIcon()}
                          </Grid>
                        </Gutter.Item>
                        <Gutter.Item>
                          <Grid gutter={{ left: 1, bottom: 1 }} align="center">
                            <Grid.Item>
                              <Button
                                size="sm"
                                color="secondary"
                                loading={createChannelData.loading}
                                disabled={createChannelData.loading}
                                onClick={() => {
                                  createChannel({
                                    origin: ChannelOrigin.OneToOne,
                                    userIds: [id],
                                  });
                                }}
                              >
                                <FormattedMessage {...texts.createChannel} />
                              </Button>
                            </Grid.Item>
                            {role !== UserRole.Admin &&
                              renderLocationHiddenButton && (
                                <Grid.Item>
                                  <Button
                                    color="secondary"
                                    size="sm"
                                    iconLeft={{ name: 'eye-slash' }}
                                    onClick={onSetResourceHide}
                                    loading={setLocationHiddenLoading}
                                    disabled={setLocationHiddenLoading}
                                  >
                                    <FormattedMessage {...texts.hideResource} />
                                  </Button>
                                </Grid.Item>
                              )}
                          </Grid>
                        </Gutter.Item>
                      </Gutter>
                    </Gutter.Item>
                  </Gutter>
                </div>
              </div>
            </Gutter.Item>
            {description && (
              <Gutter.Item>
                <div className={styles.description}>
                  {description.split('\n').map((string, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={index}>{string.length ? string : <>&nbsp;</>}</p>
                  ))}
                </div>
              </Gutter.Item>
            )}
            <Gutter.Item>
              <CollectionSection
                mobileNumber={mobileNumber}
                restaurantProfile={restaurantProfile}
              />
            </Gutter.Item>
            {renderRecommendations()}
          </Gutter>
        </div>
        <div className={styles.middle}>
          <Gutter gutter={{ bottom: 5 }}>
            {!!restaurantProfile.workExperience?.length && (
              <Gutter.Item>
                <WorkExperienceSection restaurantProfile={restaurantProfile} />
              </Gutter.Item>
            )}
            {!!restaurantProfile.languages?.length && (
              <Gutter.Item>
                <LanguagesSection restaurantProfile={restaurantProfile} />
              </Gutter.Item>
            )}
            {!!restaurantProfile.tags?.length && (
              <Gutter.Item>
                <TagsSection restaurantProfile={restaurantProfile} />
              </Gutter.Item>
            )}
          </Gutter>
        </div>
        {!!bottom && <div className={styles.bottom}>{bottom}</div>}
      </>
    );
  }, [
    bottom,
    createChannel,
    createChannelData.loading,
    error,
    loading,
    locationId,
    onSetResourceHide,
    renderLocationHiddenButton,
    renderRecommendations,
    renderRecommendationsIcon,
    restaurantProfile,
    role,
    setLocationHiddenLoading,
    user,
  ]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={classNames(styles.trigger, classNameTrigger)}
      >
        {trigger || (
          <Button color="secondary" size="sm" as="div">
            <FormattedMessage {...texts.trigger} />
          </Button>
        )}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        type="side"
        align="right"
        classNameClose={styles.modalClose}
      >
        <div className={styles.inner}>{renderContent()}</div>
      </Modal>
    </>
  );
};
